
import stylesLogin from './../../../styles/LoginPage.module.css'

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import imgLogo from '../../../assets/images/logo.png'
import InputText from '../../../components/InputText/InputText.js';
import BotonPrincipal from '../../../components/boton-principal/BotonPrincipal.js';
import BotonSecundario from '../../../components/boton-secundario/BotonSecundario.js';

import { useLocation } from 'react-router-dom';
import { srvValidarCodigo, srvValidarCodigoRecuperacion } from '../../../services/recuperar-password/srvValidarCodigoRecuperacion.js';
import PasswordStrength from '../../../components/password-strength/PasswordStrength.js';
import { TEXTO_REQUISITO_PASSWORD_SEGURA } from '../../../utils/Const.js';
import { srvCambiarContrasena } from '../../../services/recuperar-password/srvCambiarContrasena.js';


function NuevoPasswordPage() {

    // Dentro del componente de la ruta "/validar"
    const location = useLocation();
    const correo = location.state.correo; // Aquí está tu data enviada
    const codigo = location.state.codigo; // Aquí está tu data enviada

    const dispatch = useDispatch();

    const [errorPassword1, setErrorPassword1] = React.useState("");
    const [passwordStrength, setPasswordStrength] = React.useState("");
    const [errorPassword2, setErrorPassword2] = React.useState("");
    const [formData, setFormData] = React.useState({ password1: '', password2: '' });
    const [error, setError] = React.useState("");

    const navigate = useNavigate();

    const registroEnServidor = () => {
        // console.log("asds")
        const fetchData = async () => {
            try {
                const userInfo = await srvCambiarContrasena(correo, codigo, formData.password1);
                console.log(userInfo.data)

                if (userInfo.data.estado == 1) {
                    navigate("/recuperar-password/4-recuperacion-exitosa");
                }
                // navigate("/panel");
                else
                    setErrorPassword1("Código inválidos. " + userInfo.data.mensaje)
            } catch (error) {
                // setError(error.message);
                setErrorPassword1("Código inválido s" + error)
            }
        };
        fetchData();
    }

    const handleInputChange = (e) => {


        const { name, value } = e.target;
        console.log(value)
        setFormData({ ...formData, [name]: value });

    };


    const validarLogin = () => {
        let sw = 1
        setErrorPassword1("")
        setErrorPassword2("")
        if (formData.password1 == "") {
            sw = 0
            setErrorPassword1("Password no puede estar vacio")
        }

        if (sw == 1 && formData.password2 != formData.password1) {
            sw = 0
            setErrorPassword2("Contraseñas no coinciden")
        }

        if (sw == 1 && passwordStrength == 'Débil') {
            console.log("Error en el pass")
            sw = 0
            setErrorPassword1(TEXTO_REQUISITO_PASSWORD_SEGURA)
        }

        if (sw == 1) {
            registroEnServidor()
        }
    }

    console.log("setPasswordStrength", passwordStrength)

    return (
        <div className='App-fondo'>
            <div className={`Card ${stylesLogin.CardLogin}`} >
                <div className={stylesLogin.filaTitulo}>
                    <h1 className='tituloVerde'>
                        Validación de correo
                    </h1>
                    <img className={stylesLogin.logo} src={imgLogo}></img>
                </div>

                <div className={stylesLogin.filaSubtitulo}>
                    <label className='subtituloNegro'>
                        Ingresa el código enviado al correo '{correo}'
                    </label>
                </div>




                <div style={{ marginTop: "30px", display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                    <InputText
                        id="password1"
                        name="password1"
                        value={formData.password1}
                        type={"password"}
                        onChangeInput={handleInputChange}
                        width={'100%'}
                        required
                        placeholder={"Password"}
                        mensajeError={errorPassword1}></InputText>
                </div>

                <div style={{ marginTop: "30px", display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                    <InputText
                        id="password2"
                        name="password2"
                        value={formData.password2}
                        type={"password"}
                        onChangeInput={handleInputChange}
                        width={'100%'}
                        required
                        placeholder={"Repita password"}
                        mensajeError={errorPassword2}></InputText>
                </div>



                <PasswordStrength onChangeStrength={(value) => { setPasswordStrength(value) }} value={formData.password1} />

                <div style={{ width: '100%', marginTop: '30px' }}>
                    <BotonPrincipal text={"Cambiar contraseña"} onClick={() => validarLogin()}></BotonPrincipal>
                </div>

                {/* <Button variant="contained">Ingresar</Button> */}
                <hr></hr>

                <div className={stylesLogin.FilaNoEstasRegistrado}>
                    <BotonSecundario colorTexto='black' text={"¿Ya tienes una cuenta?"} onClick={() => navigate('/login')}></BotonSecundario>
                </div>



            </div>
        </div >
    )
}

export default NuevoPasswordPage;