import axios from 'axios';

// Función para realizar la solicitud GET a la API
export const srvSubirFotoSpot = async (userData, file, idSpotHash) => {

    try {
        const data = new FormData();
        data.append('file', file);

        const response = await axios.put(`${process.env.REACT_APP_SERVER_BACKEND}/v1/spots/${idSpotHash}/upload-imagen-spot`, data,
            {
                headers: {
                    Authorization: `Bearer ${userData.usuario.token}`,
                    //'Content-Type': 'multipart/form-data',
                },
            });

        return response;




    } catch (error) {
        console.error(error);
        throw new Error(error);
    }



};

