
import stylesLogin from './../../../styles/LoginPage.module.css'
import styles from './RegistrarPage.module.css'
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import imgLogo from '../../../assets/images/logo.png'
import InputText from '../../../components/InputText/InputText.js';
import BotonPrincipal from '../../../components/boton-principal/BotonPrincipal.js';
import BotonSecundario from '../../../components/boton-secundario/BotonSecundario.js';

import PasswordStrength from '../../../components/password-strength/PasswordStrength.js';
import Checkbox from '../../../components/checkbox/Checkbox.js';
import EtiquetaError from '../../../components/etiqueta-error/EtiquetaError.js';
import { srvRegistrarNuevoUsuario } from '../../../services/registro/srvRegistrarNuevoUsuario.js';
import { TEXTO_REQUISITO_PASSWORD_SEGURA } from '../../../utils/Const.js';


function RegistrarPage(props) {
    const dispatch = useDispatch();

    const [showPassword, setShowPassword] = React.useState(false);
    const [passwordStrength, setPasswordStrength] = React.useState("");
    const [errorUsuario, setErrorUsuario] = React.useState("");
    const [errorPassword, setErrorPassword] = React.useState("");
    const [errorNombre, setErrorNombre] = React.useState("");
    const [errorApellido, setErrorApellido] = React.useState("");
    const [errorCondiciones, setErrorCondiciones] = React.useState("");
    const [errorServidor, setErrorServidor] = React.useState("");

    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const [formData, setFormData] = useState({
        nombre: 'Felipe',
        apellido: 'Castillo',
        correo: 'felipecastillof@gmail.com',
        password: '12345678',
        swAceptoCondiciones: false
    });

    const registroEnServidor = () => {
        // console.log("asds")
        const fetchData = async () => {
            let userInfo
            try {
                userInfo = await srvRegistrarNuevoUsuario(formData.nombre, formData.apellido, formData.correo, formData.password);
                console.log(userInfo.data)
                // console.log("authorization", userInfo.headers.getAuthorization)
                if (userInfo.data.estado == 1) {
                    // dispatch(iniciarSesion(userInfo.data));
                    // props.handleLogged(userInfo.data)
                    navigate("/registro/validar", { state: { correo: formData.correo } });
                }
                // navigate("/panel");
                else
                    setError("Error al crear usuario. " + userInfo.data.mensaje)
            } catch (error) {
                // setError(error.message);
                setError("Error al crear usuario. " + userInfo.data.mensaje)
            }
        };
        fetchData();
    }

    const handleInputChange = (e) => {

        const { name, value } = e.target;
        console.log(value)
        setFormData({ ...formData, [name]: value });

    };


    const validarLogin = () => {
        let sw = 1
        setErrorUsuario("")
        setErrorPassword("")
        setErrorNombre("")
        setErrorApellido("")
        setErrorCondiciones("")
        if (formData.correo == "") {
            sw = 0
            setErrorUsuario("Correo no puede estar vacío")
        }

        if (formData.password == "") {
            sw = 0
            setErrorPassword("Password no puede estar vacío")
        }


        if (sw == 1 && passwordStrength == 'Débil') {
            sw = 0
            setErrorPassword(TEXTO_REQUISITO_PASSWORD_SEGURA)
        }


        if (formData.nombre == "") {
            sw = 0
            setErrorNombre("Nombre no puede estar vacío")
        }

        if (formData.apellido == "") {
            sw = 0
            setErrorApellido("Apellido no puede estar vacío")
        }


        if (!formData.swAceptoCondiciones) {
            sw = 0
            setErrorCondiciones("Debes aceptar las condiciones")
        }


        if (sw == 1) {
            registroEnServidor()
        }
    }
    const handleCheckboxChange = (checked) => {
        console.log(checked);
        setFormData({ ...formData, swAceptoCondiciones: checked })
        // Aquí puedes hacer algo más con el nuevo estado del checkbox.
    };

    const verTerminosYCondiciones = () => {

    }

    console.log("formD", formData)

    return (
        <div className='App-fondo'>
            <div className={`Card ${stylesLogin.CardLogin}`} >
                <div className={stylesLogin.filaTitulo}>
                    <h1 className='tituloVerde'>
                        Registrate
                    </h1>
                    <img className={stylesLogin.logo} src={imgLogo}></img>
                </div>

                <div className={stylesLogin.filaSubtitulo}>
                    <label className='subtituloNegro'>
                        Ingresa tus datos para continuar
                    </label>
                </div>

                <div className={stylesLogin.filaSubtitulo2}>
                    <label className='subtituloNegro2'>
                        Registrate con tu correo
                    </label>
                </div>




                <div style={{ marginTop: "30px", display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                    <InputText
                        id="nombre"
                        name="nombre"
                        value={formData.nombre}
                        type={"text"}
                        onChangeInput={handleInputChange}
                        width={'40%'}
                        required
                        placeholder={"Nombre"}
                        mensajeError={errorNombre}></InputText>

                    <InputText
                        id="apellido"
                        name="apellido"
                        value={formData.apellido}
                        type={"text"}
                        onChangeInput={handleInputChange}
                        width={'50%'}
                        required
                        placeholder={"Apellido"}
                        mensajeError={errorApellido}></InputText>

                </div>



                <div style={{ marginTop: "10px" }}>
                    <InputText
                        id="correo"
                        name="correo"
                        value={formData.correo}
                        type={"email"}
                        onChangeInput={handleInputChange}
                        required
                        placeholder={"Email"}
                        mensajeError={errorUsuario}></InputText>
                </div>

                <div style={{ marginTop: "10px" }}>
                    <InputText
                        id="password"
                        name="password"
                        value={formData.password}
                        type={"password"}
                        onChangeInput={handleInputChange}
                        required
                        placeholder={"Password"}
                        mensajeError={errorPassword}></InputText>
                </div>
                <PasswordStrength onChangeStrength={(value) => { setPasswordStrength(value) }} value={formData.password} />




                <div className={styles.FilaCondiciones}>
                    {/* <CheckBox label="Estoy de acuerdo con los Términos y condiciones"
                        onChange={(valor) => setFormData({ ...formData, swAceptoCondiciones: valor })}></CheckBox> */}
                    <div>
                        <BotonSecundario text={"Ver términos y condiciones"} onClick={() => verTerminosYCondiciones()}></BotonSecundario>
                    </div>

                    <Checkbox
                        label="Acepto los términos y condiciones"
                        onChange={handleCheckboxChange}
                    />
                    <EtiquetaError mensajeError={errorCondiciones}></EtiquetaError>
                </div>



                <BotonPrincipal text={"Ingresar"} onClick={() => validarLogin()}></BotonPrincipal>
                <EtiquetaError mensajeError={error}></EtiquetaError>
                {/* <Button variant="contained">Ingresar</Button> */}
                <hr></hr>

                <div className={stylesLogin.FilaNoEstasRegistrado}>
                    <BotonSecundario colorTexto='black' text={"¿Ya tienes una cuenta?"} onClick={() => navigate('/login')}></BotonSecundario>
                </div>



            </div>
        </div >
    )
}

export default RegistrarPage;