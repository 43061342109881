// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RegistrarPage_FilaCondiciones__hYCxI {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  margin-top: 30px;
}

.RegistrarPage_FilaCondiciones__hYCxI > div:nth-child(1) {
  display: flex;
  justify-content: flex-start;
}
`, "",{"version":3,"sources":["webpack://./src/pages/registro/1-registrar/RegistrarPage.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,2BAA2B;AAC7B","sourcesContent":[".FilaCondiciones {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  width: 100%;\n  margin-top: 30px;\n}\n\n.FilaCondiciones > div:nth-child(1) {\n  display: flex;\n  justify-content: flex-start;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FilaCondiciones": `RegistrarPage_FilaCondiciones__hYCxI`
};
export default ___CSS_LOADER_EXPORT___;
