

import styles from './AgregarTotemsPage.module.css'
import stylesPanel from '../../../../../styles/Panel.module.css'
import React, { useState, useRef, useEffect } from 'react';
import { BrowserRouter as Router, useLocation, useNavigate } from 'react-router-dom';
import HeaderTituloComponent from '../../../../../components/header-titulo/HeaderTituloComponent.js';
import InputText from '../../../../../components/InputText/InputText.js';
import BotonPrincipal from '../../../../../components/boton-principal/BotonPrincipal.js';

import { useDispatch, useSelector } from 'react-redux';
import { cerrarSesion } from '../../../../../redux/userActions.js';

import imagenSpotNoFoto from '../../../../../assets/images/spot-no-image.jpg'

import BotonSecundario from '../../../../../components/boton-secundario/BotonSecundario.js';
import SelectComponent from '../../../../../components/select/SelectComponent.js';
import { srvGuardarUsuarioExistente } from '../../../../../services/usuarios/srvGuardarUsuarioExistente.js';
import { srvSubirFotoPerfil } from '../../../../../services/usuarios/srvSubirFotoPerfil.js';
import ConfirmationModalComponent from '../../../../../components/confirmation-modal/ConfirmationModalComponent.js';
import { srvObtenerFotoPerfil } from '../../../../../services/usuarios/srvObtenerFotoPerfil.js';
import { srvGuardarNuevoUsuario } from '../../../../../services/usuarios/srvGuardarNuevoUsuario.js';
import { srvBuscarUsuario } from '../../../../../services/usuarios/srvBuscarUsuario.js';
import { srvBuscarTodosLosSpots } from '../../../../../services/spots/srvBuscarTodosLosSpots.js';
import Checkbox from '../../../../../components/checkbox/Checkbox.js';
import { srvBuscarSpot } from '../../../../../services/spots/srvBuscarSpot.js';
import { srvObtenerFotoSpot } from '../../../../../services/spots/srvObtenerFotoSpot.js';
import { srvGuardarSpotExistente } from '../../../../../services/spots/srvGuardarSpotExistente.js';
import { srvGuardarNuevoSpot } from '../../../../../services/spots/srvGuardarNuevoSpot.js';
import { srvSubirFotoSpot } from '../../../../../services/spots/srvSubirFotoSpot.js';
import Incrementador from '../../../../../components/incrementador/Incrementador.js';
import { srvBuscarCantidadTotemsSinActivar } from '../../../../../services/totems/srvBuscarCantidadTotemsSinActivar.js';
import CuadroAyuda from '../../../../../components/cuadro-ayuda/CuadroAyuda.js';
import ProgressBar from '../../../../../components/progress-bar/ProgressBar.js';
import { srvBuscarTotemsBySpot } from '../../../../../services/totems/srvBuscarTotemsBySpot.js';
import { srvActivarNuevosTotems } from '../../../../../services/spots/srvActivarNuevosTotems.js';

function AgregarTotemsPage(props) {
    const dispatch = useDispatch();
    // dispatch(cerrarSesion());
    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const [errorDescripcion, setErrorDescripcion] = useState('');
    const [errorSpot, setErrorSpot] = useState('');

    const [file, setFile] = useState(null);
    const [totems, setTotems] = useState([]);
    const [imageUrl, setImageUrl] = useState(imagenSpotNoFoto);
    const fileInputRef = useRef(null);


    const [imagenDesdeServidor, setImagenDesdeServidor] = useState(null);

    const [isModalOpen, setModalOpen] = useState(false);
    const [cantidadTotemsSinActivar, setCantidadTotemsSinActivar] = useState(null);
    const [cantidadTotemsAAgregar, setCantidadTotemsAAgregar] = useState(null)



    const location = useLocation();
    const spot = location.state.spot; // Aquí está tu data enviada

    const perfiles = [{ texto: "Master", value: 1 },
    { texto: "Administrador", value: 2 },
    { texto: "Gestor", value: 3 }]

    const userData = useSelector(state => state.authInfo);


    const [formData, setFormData] = useState({
        idSpotHash: -1,
        spot: '',
        descripcion: '',
        fechaCreacion: '',
        creadoPor: '',
        imagenUrl: '',
        imagenBlob: null
    })

    React.useEffect(() => {
        buscarCantidadTotemsSinActivar()
    }, [])


    React.useEffect(() => {
        console.log("spot", spot)
        if (spot) {
            buscarSpot(spot.idSpotHash)
            buscarTotemsBySpot(spot.idSpotHash)
        }

    }, [spot])





    const buscarSpot = (idSpotHash) => {
        const fetchData = async () => {
            try {
                const spotFromServer = await srvBuscarSpot(userData, idSpotHash);
                console.log(spotFromServer)
                // console.log("authorization", userInfo.headers.getAuthorization)
                if (spotFromServer) {
                    console.log("holi")

                    setFormData(prevFormData => ({
                        ...prevFormData,

                        idSpotHash: spotFromServer.data.idSpotHash,
                        spot: spotFromServer.data.spot,
                        descripcion: spotFromServer.data.descripcion,
                        fechaCreacion: spotFromServer.data.fechaCreacion,
                        creadoPor: spotFromServer.data.creadoPor,
                        imagenUrl: spotFromServer.data.imagenUrl || imagenSpotNoFoto

                    }));
                }
                // navigate("/panel");
                else
                    setError("Error al buscar los usuarios")
            } catch (error) {
                console.log("error.message", "+" + error + "+")
                if (error == 'Error: Error del servidor: 401') {
                    console.log("ahora si 401")
                    dispatch(cerrarSesion());
                } else {
                    setError('Error al procesar la solicitud');
                }


            }
        };
        fetchData();
    }

    const buscarTotemsBySpot = (idSpotHash) => {
        const fetchData = async () => {
            try {
                const totemsFromServer = await srvBuscarTotemsBySpot(userData, idSpotHash);
                console.log(totemsFromServer)
                // console.log("authorization",  userInfo.headers.getAuthorization) 
                if (totemsFromServer) {
                    console.log("holi")
                    setTotems(totemsFromServer.data.datos.totems)

                }
                // navigate("/panel");  
                else
                    setError("Error al buscar los usuarios")
            } catch (error) {
                console.log("error.message", "+" + error + "+")
                if (error == 'Error: Error del servidor: 401') {
                    console.log("ahora si 401")
                    dispatch(cerrarSesion());
                } else {
                    setError('Error al procesar la solicitud');
                }


            }
        };
        fetchData();
    }
    useEffect(() => {
        if (formData.imagenUrl && formData.imagenUrl != '') {

            console.log('formData.imagenUrl nuevo:', formData.imagenUrl);
            cargarImagenFromServer();
        }
    }, [formData.imagenUrl]);


    const cargarImagenFromServer = async () => {
        const defaultImage = '../../../../../assets/icons/perfil.jpg'; // Ruta a la imagen por defecto en tu carpeta assets
        console.log("Cargando imagen:", formData.imagenUrl);
        if (formData.imagenUrl && formData.imagenUrl !== defaultImage && formData.imagenUrl !== "") {
            try {
                console.log("hola 1")
                const imageObjectURL = await srvObtenerFotoSpot(userData, formData.imagenUrl);
                setFormData(prevFormData => ({ ...prevFormData, imagenBlob: imageObjectURL }));
            } catch (error) {
                console.log("hola 2")
                setFormData(prevFormData => ({ ...prevFormData, imagenBlob: defaultImage }));
            }
        } else {
            console.log("hola 3")
            setFormData(prevFormData => ({ ...prevFormData, imagenBlob: defaultImage }));
        }
    };


    const solicitarActivacionTotems = () => {
        const fetchData = async () => {
            try {

                let respuesta = await srvActivarNuevosTotems(userData, spot.idSpotHash, cantidadTotemsAAgregar);
                console.log("respuesta", respuesta)
                if (respuesta.data.estado == 1 && respuesta.data.datos.exito == 1) {
                    buscarSpot(spot.idSpotHash)
                    buscarTotemsBySpot(spot.idSpotHash)
                    buscarCantidadTotemsSinActivar()
                }
            } catch (error) {
                console.log("error.message", "+" + error + "+")
                if (error == 'Error: Error del servidor: 401') {
                    console.log("ahora si 401")
                    dispatch(cerrarSesion());
                } else {
                    setError('Error al procesar la solicitud');
                }
            }
        };
        fetchData();
    }

    const triggerFileInput = () => {
        fileInputRef.current.click();
    };

    const handleActivarNuevosTotems = () => {
        // Lógica para eliminar usuario

        solicitarActivacionTotems()
        setModalOpen(false); // Cerrar el modal
    };

    const buscarCantidadTotemsSinActivar = () => {
        // console.log("asds")
        const fetchData = async () => {
            try {
                const spotsFromServer = await srvBuscarCantidadTotemsSinActivar(userData);
                console.log(spotsFromServer)
                // console.log("authorization", userInfo.headers.getAuthorization)
                if (spotsFromServer) {
                    console.log(spotsFromServer.data.datos.cantidadTotemsSinActivar)

                    setCantidadTotemsSinActivar(spotsFromServer.data.datos.cantidadTotemsSinActivar)
                    // Cargar las imágenes después de actualizar el estado de los usuarios

                    // props.handleLogged(userInfo.data)
                }
                // navigate("/panel");
                else
                    setError("Error al buscar los usuarios")
            } catch (error) {
                console.log("error.message", "+" + error + "+")
                if (error == 'Error: Error del servidor: 401') {
                    console.log("ahora si 401")
                    dispatch(cerrarSesion());
                } else {
                    setError('Error al procesar la solicitud');
                }


            }
        };
        fetchData();
    }

    const activarNuevoDispositivo = (idTotemHash) => {
        console.log("TODO Activar dispositivo en " + idTotemHash)

        navigate("activar-nuevo-dispositivo", { state: { spot: spot, idTotemHash: idTotemHash } })
    }


    const agregarTotems = () => {
        console.log("Se agregarán  " + cantidadTotemsAAgregar + " nuevos totems")
    }
    console.log("formData", formData)

    return (
        <div className={stylesPanel.containerPrincipal}>
            <HeaderTituloComponent pagina={HeaderTituloComponent.PAGINA_LICENCIAS_EDICION}></HeaderTituloComponent>

            <div className={stylesPanel.containerPanel}>
                <div className={styles.containerUsuario} style={{ marginBottom: '20px', borderStyle: 'none' }}>
                    <div className={styles.divCampos}>
                        <img src={formData.imagenBlob ? formData.imagenBlob : imagenSpotNoFoto} className={styles.circleImage} width={400} height={400} />
                        <div className={styles.divColumn} >

                            <div className={styles.divCamposItem}>
                                <label className={styles.atributo}>Spot:</label>
                                <label className={styles.valor}>{formData.spot}</label>
                            </div>


                        </div>

                    </div>

                    {cantidadTotemsSinActivar > 0 && (
                        <div>
                            <label className={styles.labelAgregarTotems}>Agregar totems a este Spot (Máximo {cantidadTotemsSinActivar})</label>

                            <Incrementador valor={1} minimo={1} maximo={cantidadTotemsSinActivar} onChangeInput={(valor) => { setCantidadTotemsAAgregar(valor) }} ></Incrementador>

                            <div style={{ width: 250, marginLeft: 'auto', marginRight: 'auto', marginTop: 20 }}><BotonPrincipal text="Agregar totems" width={'auto'} icono={BotonPrincipal.ICONO_DISPOSITIVO}
                                onClick={() => setModalOpen(true)}
                            /></div>

                            <div style={{ alignItems: 'center', display: 'flex', marginTop: 20 }}><CuadroAyuda texto={`Puedes agregar hasta ${cantidadTotemsSinActivar} Totems. Recuerda que esta acción no se puede deshacer`}></CuadroAyuda></div>


                        </div>
                    )}



                    <div className={styles.divCampos}>

                        <label className={styles.labelTituloTotems}>Totems disponibles {totems.length}</label>

                        {spot.cantidadTotems > 0 && (
                            <div className={styles.divColumn} style={{ marginTop: 10, height: 40 }}>
                                <label className={styles.atributo} >Tienes {spot.cantidadTotemsAsignados} de {spot.cantidadTotems} totems activados:</label>
                                <ProgressBar progress={100 * spot.cantidadTotemsAsignados / spot.cantidadTotems} />
                            </div>
                        )}


                        {spot.cantidadTotems == 0 && (
                            <div className={styles.divColumn} style={{ marginTop: 10, height: 40 }}>
                                <label className={styles.atributo} >No tienes totems asignados a este Spot</label>

                            </div>
                        )}



                    </div>
                    {totems.map((totem, index) => (
                        totem.idDispositivo != null ?
                            (<div className={styles.contenedorTotemActivo}>
                                <div className={styles.icono}>
                                    <svg id="Component_9_2" data-name="Component 9 – 2" xmlns="http://www.w3.org/2000/svg" width="50" viewBox="0 0 83.563 95.5">
                                        <path id="Icon_awesome-tablet-alt" data-name="Icon awesome-tablet-alt" d="M74.609,0H8.953A8.955,8.955,0,0,0,0,8.953V86.547A8.955,8.955,0,0,0,8.953,95.5H74.609a8.955,8.955,0,0,0,8.953-8.953V8.953A8.955,8.955,0,0,0,74.609,0ZM41.781,89.531a5.969,5.969,0,1,1,5.969-5.969A5.962,5.962,0,0,1,41.781,89.531ZM74.609,69.387a2.245,2.245,0,0,1-2.238,2.238H11.191a2.245,2.245,0,0,1-2.238-2.238v-58.2a2.245,2.245,0,0,1,2.238-2.238h61.18a2.245,2.245,0,0,1,2.238,2.238Z" />
                                        <path id="Icon_material-location-on" data-name="Icon material-location-on" d="M25.35,3A17.837,17.837,0,0,0,7.5,20.85C7.5,34.237,25.35,54,25.35,54S43.2,34.237,43.2,20.85A17.837,17.837,0,0,0,25.35,3Zm0,24.225a6.375,6.375,0,1,1,6.375-6.375A6.377,6.377,0,0,1,25.35,27.225Z" transform="translate(16.432 10.5)" fill="#1e9b31" />
                                    </svg>
                                </div>


                                <div className={styles.info} >
                                    <div className={styles.divCampos}>
                                        <label className={styles.atributo} >Totem</label>
                                        <label className={styles.valor} >{totem.nombre}</label>
                                    </div>
                                    <div className={styles.divCampos}>
                                        <label className={styles.atributo} >Fecha totem </label>
                                        <label className={styles.valor} >{totem.creadoEl}</label>
                                    </div>
                                    {totem.fechaVinculacion && (
                                        <div className={styles.divCampos}>
                                            <label className={styles.atributo} >Fecha vinculación dispositivo </label>
                                            <label className={styles.valor} >{totem.fechaVinculacion}</label>
                                        </div>
                                    )}
                                    {!totem.fechaVinculacion && (
                                        <div className={styles.divCampos}>
                                            <label className={styles.atributo} >Formulario pendiente en Dispositivo</label>
                                        </div>
                                    )}


                                    <div className={styles.divCampos}>
                                        <label className={styles.atributo} >TODO 28 encuestas recibidas</label>
                                    </div>
                                </div>


                                <div className={styles.botones} >

                                    <BotonPrincipal miwidth={'auto'} text="Activar nuevo dispositivo" icono={BotonPrincipal.ICONO_DISPOSITIVO}
                                        onClick={() => activarNuevoDispositivo(totem.idTotemHash)}
                                    />

                                    <BotonSecundario text="Desconectar dispositivo" onClick={() => navigate(-1)}></BotonSecundario>
                                </div>


                            </div>)
                            :
                            (
                                <div className={styles.contenedorTotemInactivo}>
                                    <label className={styles.atributo} >Totem no activado</label>
                                    <BotonPrincipal miwidth={'auto'} text="Activar nuevo dispositivo" icono={BotonPrincipal.ICONO_DISPOSITIVO}
                                        onClick={() => activarNuevoDispositivo(totem.idTotemHash)}
                                    />

                                </div>
                            )



                    ))}







                </div>

            </div>

            <ConfirmationModalComponent
                isOpen={isModalOpen}
                onClose={() => setModalOpen(false)}
                onConfirm={handleActivarNuevosTotems}
                title="Confirmar activación totems"
                titleConfirmar={cantidadTotemsAAgregar == 1 ? 'Si, agrega el nuevo totem' : `Si, Agrega los ${cantidadTotemsAAgregar} totems`}>
                {cantidadTotemsAAgregar == 1 ? `Vas a activar un nuevo totem${cantidadTotemsSinActivar > 1 ? ` de los ${cantidadTotemsSinActivar} disponibles` : ''}. Los Totems son intransferibles entre Spots y esta acción no se puede deshacer. ¿Deseas continuar?` : `Vas a activar ${cantidadTotemsAAgregar} nuevos Totems de los ${cantidadTotemsSinActivar} disponibles. Los Totems son intransferibles entre Spots y esta acción no se puede deshacer. ¿Deseas continuar?`}

            </ConfirmationModalComponent>

        </div >
    )
}

export default AgregarTotemsPage;