// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EtiquetaError_mensajeError__V3VGU {
  color: red;
  height: 25px;
  width: auto;
  text-align: left;
  padding-left: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/components/etiqueta-error/EtiquetaError.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,YAAY;EACZ,WAAW;EACX,gBAAgB;EAChB,iBAAiB;AACnB","sourcesContent":[".mensajeError {\n  color: red;\n  height: 25px;\n  width: auto;\n  text-align: left;\n  padding-left: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mensajeError": `EtiquetaError_mensajeError__V3VGU`
};
export default ___CSS_LOADER_EXPORT___;
