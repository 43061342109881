// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/images/banner.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PerfilPage_container__rtLNC {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
}

.PerfilPage_bannerContainer__cTNvl {
  position: relative; /* Establece el contenedor del banner como referencia para posicionar la imagen del perfil */
  width: 90%; /* El ancho completo de la vista */
  margin-top: 10px;
  border-radius: 10px;
  height: 50px; /* Altura del banner, ajústala según tus necesidades */
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center center; /* Asegúrate de tener la ruta al banner */
  background-size: cover; /* Asegúrate de que el banner cubra todo el espacio disponible */
}

.PerfilPage_profilePicture__QnlKY {
  width: 300px; /* Utiliza todo el espacio disponible del contenedor */
  height: auto; /* Mantiene la relación de aspecto de la imagen */
  border-radius: 12px; /* Hace que la imagen sea redonda */
  display: block; /* Asegura que no haya espacio extra debajo de la imagen */
}
.PerfilPage_nombreUsuario__fTreW {
  font-size: 2rem;
  margin-top: 40px;
}

.PerfilPage_perfilUsuario__NAvcH {
  font-size: 0.6rem;
  margin-top: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/principal/perfil/PerfilPage.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,qBAAqB;EACrB,mBAAmB;EACnB,2BAA2B;EAC3B,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,kBAAkB,EAAE,4FAA4F;EAChH,UAAU,EAAE,kCAAkC;EAC9C,gBAAgB;EAChB,mBAAmB;EACnB,YAAY,EAAE,sDAAsD;EACpE,2EAA6E,EAAE,yCAAyC;EACxH,sBAAsB,EAAE,gEAAgE;AAC1F;;AAEA;EACE,YAAY,EAAE,sDAAsD;EACpE,YAAY,EAAE,iDAAiD;EAC/D,mBAAmB,EAAE,mCAAmC;EACxD,cAAc,EAAE,0DAA0D;AAC5E;AACA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,eAAe;AACjB","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  align-content: center;\n  align-items: center;\n  justify-content: flex-start;\n  height: 100%;\n  width: 100%;\n}\n\n.bannerContainer {\n  position: relative; /* Establece el contenedor del banner como referencia para posicionar la imagen del perfil */\n  width: 90%; /* El ancho completo de la vista */\n  margin-top: 10px;\n  border-radius: 10px;\n  height: 50px; /* Altura del banner, ajústala según tus necesidades */\n  background: url(\"../../../assets/images/banner.webp\") no-repeat center center; /* Asegúrate de tener la ruta al banner */\n  background-size: cover; /* Asegúrate de que el banner cubra todo el espacio disponible */\n}\n\n.profilePicture {\n  width: 300px; /* Utiliza todo el espacio disponible del contenedor */\n  height: auto; /* Mantiene la relación de aspecto de la imagen */\n  border-radius: 12px; /* Hace que la imagen sea redonda */\n  display: block; /* Asegura que no haya espacio extra debajo de la imagen */\n}\n.nombreUsuario {\n  font-size: 2rem;\n  margin-top: 40px;\n}\n\n.perfilUsuario {\n  font-size: 0.6rem;\n  margin-top: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PerfilPage_container__rtLNC`,
	"bannerContainer": `PerfilPage_bannerContainer__cTNvl`,
	"profilePicture": `PerfilPage_profilePicture__QnlKY`,
	"nombreUsuario": `PerfilPage_nombreUsuario__fTreW`,
	"perfilUsuario": `PerfilPage_perfilUsuario__NAvcH`
};
export default ___CSS_LOADER_EXPORT___;
