import axios from 'axios';

// Función para realizar la solicitud GET a la API
export const srvActivarNuevosTotems = async (userData, idSpotHash, cantidadTotemsAAgregar) => {
    try {

        const response = await axios.patch(`${process.env.REACT_APP_SERVER_BACKEND}/v1/spots/${idSpotHash}/activar-nuevos-totems`,
            {
                nuevosTotems: cantidadTotemsAAgregar
            },
            {
                headers: {
                    Authorization: `Bearer ${userData.usuario.token}`,
                },
            }
        );

        if (response.status === 200) {
            console.log("response.data", response)
            return response;
        } else {
            throw new Error(`Error en la solicitud. Código de estado: ${response.status}`);
        }

    } catch (error) {
        console.error('Error al obtener los datos:', error);

        // Chequear si el error tiene 'response'
        if (error.response) {
            // El servidor respondió con un estado fuera del rango de 2xx
            console.error('Data:', error.response.data);
            console.error('Status:', error.response.status);
            console.error('Headers:', error.response.headers);
            throw new Error(`Error del servidor: ${error.response.status}`);
        } else if (error.request) {
            // La solicitud fue hecha pero no hubo respuesta
            console.error('Error de solicitud sin respuesta:', error.request);
            throw new Error('No se recibió respuesta del servidor');
        } else {
            // Algo ocurrió en la configuración de la solicitud que lanzó un Error
            console.error('Error:', error.message);
            throw new Error(error.message);
        }
    }
};
