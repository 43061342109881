import React, { useState } from 'react';
// Asegúrate de importar el archivo CSS o definir los estilos directamente
import styles from './Checkbox.module.css'; // Este es un ejemplo si estás usando CSS Modules.

const Checkbox = ({ label, onChange, isChecked }) => {
    const [checked, setChecked] = useState(false);

    React.useEffect(() => {
        if (isChecked)
            setChecked(isChecked)
    }, [isChecked])

    const handleCheckboxChange = (event) => {
        setChecked(event.target.checked);
        if (onChange) {
            onChange(event.target.checked);
        }
    };

    return (
        <div className={styles.checkboxContainer}>
            <input
                id="checkbox_id"
                type="checkbox"
                checked={checked}
                onChange={handleCheckboxChange}
            />
            <label for="checkbox_id" className={styles.checkboxLabel}>

                {label}
            </label>

        </div>
    );
};

export default Checkbox;
