// PasswordStrength.js
import React, { useEffect, useState } from 'react';
import styles from './PasswordStrength.module.css';

const PasswordStrength = ({ value, onChangeStrength }) => {
    const [strength, setStrength] = useState('');

    useEffect(() => {
        const calculateStrength = (password) => {
            if (password.length >= 8 && /[a-zA-Z]/.test(password) && /\d/.test(password)) {
                onChangeStrength('Segura')
                setStrength('Segura');
            } else {
                onChangeStrength('Débil')
                setStrength('Débil');
            }
        };

        calculateStrength(value);
    }, [value]);

    return (
        <div className={styles.container}>
            <div className={`${styles.indicator} ${styles[strength]}`}></div>
            <span>{strength.charAt(0).toUpperCase() + strength.slice(1)}</span>
        </div>
    );
};

export default PasswordStrength;
