

import styles from './PaginaIntroduccion.module.css'
import React from 'react';
import BotonPrincipal from '../../../../../../components/boton-principal/BotonPrincipal.js';
import logo from '../../../../../../assets/images/logo.png'


function PaginaIntroduccion({ onSiguiente, titulo, descripcion }) {




    return (
        <div className={styles.fondoComponente}>
            <img className={styles.logo} src={logo} />
            <div className={styles.midiv} >
                <label className={styles.bienvenido}>{titulo}</label>
                <label className={styles.mensaje}>{descripcion}</label>
                <a onClick={onSiguiente}>
                    <BotonPrincipal text="Comenzar"></BotonPrincipal>
                </a>
            </div>


        </div>

    )
}

export default PaginaIntroduccion;