

import styles from './PanelPage.module.css'
import stylesPanel from './../../../styles/Panel.module.css'
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
import HeaderTituloComponent from '../../../components/header-titulo/HeaderTituloComponent';
import CardUsuarioComponent from './card-usuario/CardUsuarioComponent';
import BotonCardComponent from '../../../components/boton-card/BotonCardComponent';
import Sidebar from '../../../components/sidebar/Sidebar';
import { useDispatch, useSelector } from 'react-redux';

function PanelPage(props) {

    const userData = useSelector(state => state.authInfo);
    console.log(userData)

    const dispatch = useDispatch();

    const navigate = useNavigate();
    const [error, setError] = useState(false);

    const abrirPage = (valor) => {
        // console.log("valor", valor)
        navigate(valor)
    }



    return (
        <div className={stylesPanel.containerPrincipal}>

            <HeaderTituloComponent pagina={HeaderTituloComponent.PAGINA_PANEL}></HeaderTituloComponent>
            {/* <CardUsuarioComponent titulo={'Social Profile'}></CardUsuarioComponent> */}
            <div className={styles.containerMenu}>
                {userData.usuario.idUsuarioTipo == 1 && (
                    <BotonCardComponent className={styles.botonCard} onClick={(ruta) => abrirPage(ruta)} opcion={BotonCardComponent.MENU_CLIENTE}  ></BotonCardComponent>
                )}

                <BotonCardComponent className={styles.botonCard} onClick={(ruta) => abrirPage(ruta)} opcion={BotonCardComponent.MENU_SPOTS}  ></BotonCardComponent>
                <BotonCardComponent className={styles.botonCard} onClick={(ruta) => abrirPage(ruta)} opcion={BotonCardComponent.MENU_ENCUESTAS}  ></BotonCardComponent>
                <BotonCardComponent className={styles.botonCard} onClick={(ruta) => abrirPage(ruta)} opcion={BotonCardComponent.MENU_USUARIOS}  ></BotonCardComponent>
                {/* <BotonCardComponent className={styles.botonCard} onClick={(ruta) => abrirPage(ruta)} opcion={BotonCardComponent.MENU_LICENCIAS}  ></BotonCardComponent> */}
                <BotonCardComponent className={styles.botonCard} onClick={(ruta) => abrirPage(ruta)} opcion={BotonCardComponent.MENU_INFORMES}  ></BotonCardComponent>
            </div>



        </div>
    )
}

export default PanelPage;