import EtiquetaError from '../etiqueta-error/EtiquetaError';
import styles from './SelectComponent.module.css'
import React, { useState, useEffect } from 'react';

const SelectComponent = ({ options, value, onChange, placeholder, mensajeError, miwidth, isReadOnly = false }) => {
    console.log("value", value)


    const [isFocused, setIsFocused] = useState(false);
    const [isModificadoDespuesDeError, setIsModificadoDespuesDeError] = useState(false);


    React.useEffect(() => {
        setIsModificadoDespuesDeError(false)
    }, [mensajeError])


    return (
        <div className={styles.inputContainerGlobal} style={miwidth ? { width: miwidth } : {}}>
            <div className={isFocused ? `${styles.inputContainer} ${styles.focused}` : (mensajeError != "" && isModificadoDespuesDeError == false ? (`${styles.inputContainer} ${styles.inputContainerConError}`) : styles.inputContainer)}>
                <label>{placeholder}</label>
                <select className={styles.select} value={value} disabled={isReadOnly} onChange={onChange}>
                    <option value="" disabled={value !== ''}>{value ? '' : !placeholder ? 'Seleccione elemento...' : placeholder}</option>
                    {options.map((option, index) => (
                        <option key={index} value={option.value}>
                            {option.texto}
                        </option>
                    ))}
                </select>

            </div>
            <EtiquetaError mensajeError={!isModificadoDespuesDeError ? mensajeError : ""}></EtiquetaError>
        </div >

    );
};


export default SelectComponent;
