

import styles from './ActivacionPage.module.css'
import stylesPanel from '../../../../../styles/Panel.module.css'
import React, { useState, useEffect } from 'react';
import HeaderTituloComponent from '../../../../../components/header-titulo/HeaderTituloComponent.js';
import InputText from '../../../../../components/InputText/InputText.js';
import BotonPrincipal from '../../../../../components/boton-principal/BotonPrincipal.js';
import { useDispatch, useSelector } from 'react-redux';
import { cerrarSesion } from '../../../../../redux/userActions.js';
import { BrowserRouter as Router, useLocation, useNavigate } from 'react-router-dom';

import imagenSpotNoFoto from '../../../../../assets/images/spot-no-image.jpg'

import { srvObtenerFotoPerfil } from '../../../../../services/usuarios/srvObtenerFotoPerfil.js';

import { srvBuscarTodosLosSpots } from '../../../../../services/spots/srvBuscarTodosLosSpots.js';
import ConfirmationModalComponent from '../../../../../components/confirmation-modal/ConfirmationModalComponent.js';
import { srvActivarEncuesta } from '../../../../../services/encuestas/srvActivarEncuesta.js';
import { srvActivarEncuestaEnTodosLosSpots } from '../../../../../services/encuestas/srvActivarEncuestaEnTodosLosSpots.js';


function ActivacionPage(props) {
    const dispatch = useDispatch();
    // dispatch(cerrarSesion());
    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const [errorBuscar, setErrorBuscar] = useState('');
    const [textoBuscado, setTextoBuscado] = useState('');
    const [spotsSinFiltrar, setSpotsSinFiltrar] = useState([]);
    const [spotsFiltrados, setSpotsFiltrados] = useState([]);

    const [spotSelected, setSpotSelected] = useState(null)
    const [isOpenModal, setIsOpenModal] = useState(null)
    const [isOpenModalTodos, setIsOpenModalTodos] = useState(null)



    const [isOpenInfoModal, setIsOpenInfoModal] = useState(null)
    const [mensajeConfirmacionDesdeServidor, setMensajeConfirmacionDesdeServidor] = useState("")




    const userData = useSelector(state => state.authInfo);

    const location = useLocation();
    const encuesta = location.state ? location.state.encuesta : null;




    React.useEffect(() => {
        console.log("encuesta ppl", encuesta)
    }, [encuesta])




    React.useEffect(() => {
        getSpotsFromServer()
    }, [])


    useEffect(() => {
        console.log("Nuevo texto a buscar ", textoBuscado)
        console.log("usuariosSinFiltrar ", spotsSinFiltrar)
        setSpotsFiltrados(spotsSinFiltrar.filter(usuario => {
            const textoBuscadoEnMinusculas = textoBuscado.toLowerCase();
            return usuario.spot.toLowerCase().includes(textoBuscadoEnMinusculas) || usuario.descripcion.toLowerCase().includes(textoBuscadoEnMinusculas) || usuario.creadoPor.toLowerCase().includes(textoBuscadoEnMinusculas)
        }
        ))

    }, [textoBuscado, spotsSinFiltrar]);



    const getSpotsFromServer = () => {
        // console.log("asds")
        const fetchData = async () => {
            try {
                const spotsFromServer = await srvBuscarTodosLosSpots(userData);
                console.log(spotsFromServer)
                // console.log("authorization", userInfo.headers.getAuthorization)
                if (spotsFromServer) {
                    console.log(spotsFromServer.data)

                    setSpotsSinFiltrar(spotsFromServer.data);
                    // Cargar las imágenes después de actualizar el estado de los usuarios
                    cargarImagenes(spotsFromServer.data);
                    // props.handleLogged(userInfo.data)
                }
                // navigate("/panel");
                else
                    setError("Error al buscar los usuarios")
            } catch (error) {
                console.log("error.message", "+" + error + "+")
                if (error == 'Error: Error del servidor: 401') {
                    console.log("ahora si 401")
                    dispatch(cerrarSesion());
                } else {
                    setError('Error al procesar la solicitud');
                }


            }
        };
        fetchData();
    }



    const defaultImage = '../../../../assets/icons/perfil.jpg'; // Ruta a la imagen por defecto en tu carpeta assets

    const cargarImagenes = async (usuarios) => {
        const usuariosConImagenes = await Promise.all(usuarios.map(async usuario => {
            if (usuario.imagenUrl && usuario.imagenUrl !== defaultImage && usuario.imagenUrl !== "") {
                try {
                    const imageObjectURL = await srvObtenerFotoPerfil(userData, usuario.imagenUrl)
                    return { ...usuario, imagenBlob: imageObjectURL };
                } catch (error) {
                    console.error(`Error al cargar la imagen para el usuario ${usuario.id}:`, error);
                    return { ...usuario, imagenBlob: usuario };
                }
            } else {
                return { ...usuario, imagenBlob: imagenSpotNoFoto };
            }
        }));

        setSpotsSinFiltrar(usuariosConImagenes);
    };

    const handleInputChange = (e) => {

        const { name, value } = e.target;
        console.log("Texto buscado", value)
        setTextoBuscado(value)

    };
    const crearSpot = () => {
        navigate("edicion", { state: { usuario: null } })
    }

    const activarEncuestaEnTodosLosSpots = () => {
        console.log("activarEncuestaEnTodosLosSpots")
        const fetchData = async () => {
            try {
                const spotsFromServer = await srvActivarEncuestaEnTodosLosSpots(userData, encuesta.idEncuestaHash);
                console.log(spotsFromServer)
                // console.log("authorization", userInfo.headers.getAuthorization)
                if (spotsFromServer) {
                    console.log(spotsFromServer.data)
                    setMensajeConfirmacionDesdeServidor(spotsFromServer.data.mensaje)
                    setIsOpenModalTodos(false)
                    setIsOpenInfoModal(true)
                    getSpotsFromServer()
                }
                // navigate("/panel");
                else
                    setError("Error al buscar los usuarios")
            } catch (error) {
                console.log("error.message", "+" + error + "+")
                if (error == 'Error: Error del servidor: 401') {
                    console.log("ahora si 401")
                    dispatch(cerrarSesion());
                } else {
                    setError('Error al procesar la solicitud');
                }


            }
        };
        fetchData()
    }

    const activarEncuesta = () => {
        console.log(spotSelected)
        console.log(encuesta)

        const fetchData = async () => {
            try {
                const spotsFromServer = await srvActivarEncuesta(userData, encuesta.idEncuestaHash, spotSelected.idSpotHash);
                console.log("encuesta", encuesta)
                console.log(spotsFromServer)
                // console.log("authorization", userInfo.headers.getAuthorization)
                if (spotsFromServer) {
                    console.log(spotsFromServer.data)
                    setMensajeConfirmacionDesdeServidor(spotsFromServer.data.mensaje)
                    setIsOpenModal(false)
                    setIsOpenInfoModal(true)
                    getSpotsFromServer()
                }
                // navigate("/panel");
                else
                    setError("Error al buscar los usuarios")
            } catch (error) {
                console.log("error.message", "+" + error + "+")
                if (error == 'Error: Error del servidor: 401') {
                    console.log("ahora si 401")
                    dispatch(cerrarSesion());
                } else {
                    setError('Error al procesar la solicitud');
                }


            }
        };
        fetchData()


    }
    return (
        <div className={stylesPanel.containerPrincipal}>
            <HeaderTituloComponent pagina={HeaderTituloComponent.PAGINA_ENCUESTAS_ACTIVAR}></HeaderTituloComponent>

            <div className={stylesPanel.containerPanel}>
                <div className={styles.containerGrillaUsuarios}>

                    <BotonPrincipal text="Activar todos" icono={BotonPrincipal.ICONO_PLAY} onClick={() => setIsOpenModalTodos(true)} />

                </div>



                <div className={styles.containerGrillaUsuarios}  >
                    {spotsFiltrados.map((spot, index) => (
                        <div key={index} className={styles.containerUsuario} style={{ marginBottom: '20px' }}>
                            <label className={styles.titulo}>{spot.nombre} {spot.apellido}</label>
                            <div className={styles.divTodoEnUnaFila}>

                                <div className={styles.divCampos} >
                                    <img src={spot.imagenBlob ? spot.imagenBlob : imagenSpotNoFoto} className={styles.circleImage} />
                                    <div className={styles.divCamposItem}>
                                        <label className={styles.atributo}>Spot:</label>
                                        <label className={styles.valor}>{spot.spot}</label>
                                    </div>
                                    <div className={styles.divCamposItem}>
                                        <label className={styles.atributo}>Descripcion:</label>
                                        <label className={styles.valor}>{spot.descripcion}</label>
                                    </div>

                                    <div className={styles.divCamposItem}>
                                        <label className={styles.atributo}>Fecha de creación:</label>
                                        <label className={styles.valor}>{spot.fechaCreacion}</label>
                                    </div>

                                    <div className={styles.divCamposItem}>
                                        <label className={styles.atributo}>Creado por:</label>
                                        <label className={styles.valor}>{spot.creadoPor}</label>
                                    </div>
                                    {spot.encuestaActiva ? (
                                        <div className={styles.divCamposItem}>
                                            <label className={styles.atributo}>Encuesta activa:</label>
                                            <label className={styles.valor}>{spot.encuestaActiva}</label>
                                        </div>
                                    ) : (
                                        <div className={styles.divCamposItem}>
                                            <label className={styles.atributo}>Sin encuesta activa</label>
                                        </div>
                                    )}

                                </div>
                            </div>


                            <div className={styles.divBotonera}>
                                <BotonPrincipal text="Activar" onClick={() => { setSpotSelected(spot); setIsOpenModal(true) }} width={'150px'} icono={BotonPrincipal.ICONO_PLAY} />

                            </div>

                        </div>
                    ))}


                </div>

            </div>



            <ConfirmationModalComponent
                isOpen={isOpenModal}
                onClose={() => setIsOpenModal(false)}
                onConfirm={() => activarEncuesta()}
                title="Confirmar activación">
                ¿Deseas activar esta encuesta en este spot?
            </ConfirmationModalComponent>


            <ConfirmationModalComponent
                isOpen={isOpenModalTodos}
                onClose={() => setIsOpenModalTodos(false)}
                onConfirm={() => activarEncuestaEnTodosLosSpots()}
                title="Confirmar activación en todos los Spots">
                ¿Deseas activar esta encuesta en TODOS los spot?
            </ConfirmationModalComponent>




            <ConfirmationModalComponent
                isOpen={isOpenInfoModal}
                onClose={() => setIsOpenInfoModal(false)}
                swModalInformativo={true}
                title="Activación">
                {mensajeConfirmacionDesdeServidor}
            </ConfirmationModalComponent>




        </div >
    )
}

export default ActivacionPage;