

import styles from './SpotsEdicionPage.module.css'
import stylesPanel from '../../../../../styles/Panel.module.css'
import React, { useState, useRef, useEffect } from 'react';
import { BrowserRouter as Router, useLocation, useNavigate } from 'react-router-dom';
import HeaderTituloComponent from '../../../../../components/header-titulo/HeaderTituloComponent.js';
import InputText from '../../../../../components/InputText/InputText.js';
import BotonPrincipal from '../../../../../components/boton-principal/BotonPrincipal.js';

import { useDispatch, useSelector } from 'react-redux';
import { cerrarSesion } from '../../../../../redux/userActions.js';

import imagenSpotNoFoto from '../../../../../assets/images/spot-no-image.jpg'

import BotonSecundario from '../../../../../components/boton-secundario/BotonSecundario.js';
import SelectComponent from '../../../../../components/select/SelectComponent.js';
import { srvGuardarUsuarioExistente } from '../../../../../services/usuarios/srvGuardarUsuarioExistente.js';
import { srvSubirFotoPerfil } from '../../../../../services/usuarios/srvSubirFotoPerfil.js';
import ConfirmationModalComponent from '../../../../../components/confirmation-modal/ConfirmationModalComponent.js';
import { srvObtenerFotoPerfil } from '../../../../../services/usuarios/srvObtenerFotoPerfil.js';
import { srvGuardarNuevoUsuario } from '../../../../../services/usuarios/srvGuardarNuevoUsuario.js';
import { srvBuscarUsuario } from '../../../../../services/usuarios/srvBuscarUsuario.js';
import { srvBuscarTodosLosSpots } from '../../../../../services/spots/srvBuscarTodosLosSpots.js';
import Checkbox from '../../../../../components/checkbox/Checkbox.js';
import { srvBuscarSpot } from '../../../../../services/spots/srvBuscarSpot.js';
import { srvObtenerFotoSpot } from '../../../../../services/spots/srvObtenerFotoSpot.js';
import { srvGuardarSpotExistente } from '../../../../../services/spots/srvGuardarSpotExistente.js';
import { srvGuardarNuevoSpot } from '../../../../../services/spots/srvGuardarNuevoSpot.js';
import { srvSubirFotoSpot } from '../../../../../services/spots/srvSubirFotoSpot.js';

function SpotsEdicionPage(props) {
    const dispatch = useDispatch();
    // dispatch(cerrarSesion());
    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const [errorDescripcion, setErrorDescripcion] = useState('');
    const [errorSpot, setErrorSpot] = useState('');

    const [file, setFile] = useState(null);
    const [spots, setSpots] = useState([]);
    const [imageUrl, setImageUrl] = useState(imagenSpotNoFoto);
    const fileInputRef = useRef(null);


    const [imagenDesdeServidor, setImagenDesdeServidor] = useState(null);

    const [isModalOpen, setModalOpen] = useState(false);



    const location = useLocation();
    const spot = location.state.spot; // Aquí está tu data enviada

    const perfiles = [{ texto: "Master", value: 1 },
    { texto: "Administrador", value: 2 },
    { texto: "Gestor", value: 3 }]

    const userData = useSelector(state => state.authInfo);


    const [formData, setFormData] = useState({
        idSpotHash: -1,
        spot: '',
        descripcion: '',
        fechaCreacion: '',
        creadoPor: '',
        imagenUrl: '',
        imagenBlob: null
    })


    React.useEffect(() => {
        console.log("spot", spot)
        if (spot)
            buscarSpot(spot.idSpotHash)


    }, [spot])





    const buscarSpot = (idSpotHash) => {
        const fetchData = async () => {
            try {
                const spotFromServer = await srvBuscarSpot(userData, idSpotHash);
                console.log(spotFromServer)
                // console.log("authorization", userInfo.headers.getAuthorization)
                if (spotFromServer) {
                    console.log("holi")

                    setFormData(prevFormData => ({
                        ...prevFormData,

                        idSpotHash: spotFromServer.data.idSpotHash,
                        spot: spotFromServer.data.spot,
                        descripcion: spotFromServer.data.descripcion,
                        fechaCreacion: spotFromServer.data.fechaCreacion,
                        creadoPor: spotFromServer.data.creadoPor,
                        imagenUrl: spotFromServer.data.imagenUrl || imagenSpotNoFoto

                    }));
                }
                // navigate("/panel");
                else
                    setError("Error al buscar los usuarios")
            } catch (error) {
                console.log("error.message", "+" + error + "+")
                if (error == 'Error: Error del servidor: 401') {
                    console.log("ahora si 401")
                    dispatch(cerrarSesion());
                } else {
                    setError('Error al procesar la solicitud');
                }


            }
        };
        fetchData();
    }

    useEffect(() => {
        if (formData.imagenUrl && formData.imagenUrl != '') {

            console.log('formData.imagenUrl nuevo:', formData.imagenUrl);
            cargarImagenFromServer();
        }
    }, [formData.imagenUrl]);


    const cargarImagenFromServer = async () => {
        const defaultImage = '../../../../../assets/icons/perfil.jpg'; // Ruta a la imagen por defecto en tu carpeta assets
        console.log("Cargando imagen:", formData.imagenUrl);
        if (formData.imagenUrl && formData.imagenUrl !== defaultImage && formData.imagenUrl !== "") {
            try {
                console.log("hola 1")
                const imageObjectURL = await srvObtenerFotoSpot(userData, formData.imagenUrl);
                setFormData(prevFormData => ({ ...prevFormData, imagenBlob: imageObjectURL }));
            } catch (error) {
                console.log("hola 2")
                setFormData(prevFormData => ({ ...prevFormData, imagenBlob: defaultImage }));
            }
        } else {
            console.log("hola 3")
            setFormData(prevFormData => ({ ...prevFormData, imagenBlob: defaultImage }));
        }
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        // console.log(value)
        setFormData({ ...formData, [name]: value });
    };





    const onFileChange = event => {
        setFile(event.target.files[0]);
        if (event.target.files[0]) {
            // Crear una URL para la imagen seleccionada y actualizar la imagen que se muestra
            // setImageUrl(URL.createObjectURL(event.target.files[0]));
            setFormData({ ...formData, imagenBlob: URL.createObjectURL(event.target.files[0]) })
        }
    };



    const guardar = () => {
        console.log(formData)
        let sw = 1
        if (formData.spot == "") {
            sw = 0
            setErrorSpot("Falta el nombre del spot")
        }
        if (formData.descripcion == "") {
            sw = 0
            setErrorDescripcion("Falta la descripción")
        }
        if (sw == 1) {
            setModalOpen(true)
        }
    }

    const enviarCambiosAlServidor = () => {
        const fetchData = async () => {
            try {
                console.log("Guardando formData", formData)
                let spotFromServer
                if (formData.idSpotHash != -1)
                    spotFromServer = await srvGuardarSpotExistente(userData, formData);
                else
                    spotFromServer = await srvGuardarNuevoSpot(userData, formData);
                console.log(spotFromServer)
                // console.log("authorization", userInfo.headers.getAuthorization)
                if (spotFromServer) {
                    console.log("imageUrl", imageUrl)
                    console.log("usuariosFromServer.data", spotFromServer)

                    await srvSubirFotoSpot(
                        userData,
                        file,
                        spotFromServer.data.datos.idSpotHash,
                    );
                    navigate("/principal/spots");

                }
                // navigate("/panel");

                else
                    setError("Error al guardar cambios")
            } catch (error) {
                console.log("error.message", "+" + error + "+")
                if (error == 'Error: Error del servidor: 401') {
                    console.log("ahora si 401")
                    dispatch(cerrarSesion());
                } else {
                    setError('Error al procesar la solicitud');
                }
            }
        };
        fetchData();
    }

    const triggerFileInput = () => {
        fileInputRef.current.click();
    };

    const handleGuardar = () => {
        // Lógica para eliminar usuario

        enviarCambiosAlServidor()
        setModalOpen(false); // Cerrar el modal
    };


    console.log("formData", formData)

    return (
        <div className={stylesPanel.containerPrincipal}>
            <HeaderTituloComponent pagina={HeaderTituloComponent.PAGINA_SPOTS_EDICION}></HeaderTituloComponent>

            <div className={stylesPanel.containerPanelSinBorde}>
                <div className={styles.containerUsuario} style={{ marginBottom: '20px' }}>
                    <img src={formData.imagenBlob ? formData.imagenBlob : imagenSpotNoFoto} onClick={triggerFileInput} className={styles.circleImage} width={400} height={400} />
                    <BotonSecundario text={"Cambiar imagen"} onClick={triggerFileInput} ></BotonSecundario>
                    <input
                        type="file"
                        onChange={onFileChange}
                        style={{ display: 'none' }}
                        ref={fileInputRef}
                    />


                    <div className={styles.divFormulario}>


                        <InputText
                            id="spot"
                            name="spot"
                            miMarginTop={20}
                            value={formData.spot}
                            required
                            type={"text"}
                            onChangeInput={handleInputChange}
                            placeholder={"Spot..."}
                            mensajeError={errorSpot}></InputText>



                        <InputText
                            id="descripcion"
                            name="descripcion"
                            value={formData.descripcion}
                            required
                            type={"text"}
                            onChangeInput={handleInputChange}
                            placeholder={"Descripción..."}
                            mensajeError={errorDescripcion}></InputText>







                    </div>



                    <div className={styles.divBotonera}>
                        <BotonPrincipal text="Guardar" icono={BotonPrincipal.ICONO_GUARDAR}
                            onClick={() => guardar()}
                        />

                        <BotonSecundario text="Cancelar" onClick={() => navigate(-1)}></BotonSecundario>
                    </div>

                </div>

            </div>

            <ConfirmationModalComponent
                isOpen={isModalOpen}
                onClose={() => setModalOpen(false)}
                onConfirm={handleGuardar}
                title="Confirmar guardado">
                ¿Estás seguro de que deseas guardar este spot?
            </ConfirmationModalComponent>

        </div >
    )
}

export default SpotsEdicionPage;