import styles from './PreguntaEdicionModal.module.css'
import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { cerrarSesion } from '../../../../../../redux/userActions.js';

import BotonSecundario from '../../../../../../components/boton-secundario/BotonSecundario.js';
import { srvBuscarPreguntasPerfil } from '../../../../../../services/preguntas-perfil/srvBuscarPreguntasPerfil.js';
import MiniBoton from '../../../../../../components/mini-boton/MiniBoton.js';
import EtiquetaError from '../../../../../../components/etiqueta-error/EtiquetaError.js';
import InputText from '../../../../../../components/InputText/InputText.js';
import SelectComponent from '../../../../../../components/select/SelectComponent.js';
import cara1 from '../../../../../../assets/icons/cara_1.png';
import cara2 from '../../../../../../assets/icons/cara_2.png';
import cara3 from '../../../../../../assets/icons/cara_3.png';
import cara4 from '../../../../../../assets/icons/cara_4.png';
import cara5 from '../../../../../../assets/icons/cara_5.png';
import BotonPrincipal from '../../../../../../components/boton-principal/BotonPrincipal.js';
import { v4 as uuidv4 } from 'uuid';

function PreguntaEdicionModal({ isOpen, onClose, onChange, preguntaOriginal, isReadOnly = false, titulo = null, isPreguntaDePerfil = false }) {
    const dispatch = useDispatch();
    const [pregunta, setPregunta] = useState({
        cantAlternativas: 0,
        idPregunta: uuidv4(),
        pregunta: '',
        idPreguntaTipo: 1
    });

    const [alternativas, setAlternativas] = useState([
        { idAlternativa: uuidv4(), alternativa: ':(' },
        { idAlternativa: uuidv4(), alternativa: ':/' },
        { idAlternativa: uuidv4(), alternativa: ':|' },
        { idAlternativa: uuidv4(), alternativa: ':)' },
        { idAlternativa: uuidv4(), alternativa: ':D' },
    ])

    const preguntaTipoList = [
        { value: 1, texto: "Selección cuantitativa" },
        { value: 2, texto: "Alternativas múltiples" },
    ]
    const [error, setError] = useState("");

    const [errorPregunta, setErrorPregunta] = useState("");

    const userData = useSelector(state => state.authInfo);

    React.useEffect(() => {
        console.log("preguntaOriginal", preguntaOriginal)
        if (preguntaOriginal != null) {
            setPregunta({
                idPregunta: preguntaOriginal.idPregunta,
                pregunta: preguntaOriginal.pregunta,
                orden: preguntaOriginal.orden,
                idPreguntaTipo: preguntaOriginal.idPreguntaTipo,
                alternativas: preguntaOriginal.alternativas
            })

            setAlternativas(preguntaOriginal.alternativas)
        } else {
            setPregunta({
                cantAlternativas: 0,
                idPregunta: uuidv4(),
                pregunta: '',
                idPreguntaTipo: 1
            })

            setAlternativas([
                { idAlternativa: uuidv4(), alternativa: ':(' },
                { idAlternativa: uuidv4(), alternativa: ':/' },
                { idAlternativa: uuidv4(), alternativa: ':|' },
                { idAlternativa: uuidv4(), alternativa: ':)' },
                { idAlternativa: uuidv4(), alternativa: ':D' },
            ])
        }
    }, [preguntaOriginal, isOpen])


    React.useEffect(() => {

        reiniciarModal()
    }, [isOpen])

    const reiniciarModal = () => {
        if (!isOpen) {
            console.log("PPL Reiniciando alternativas")
            setPregunta({
                cantAlternativas: 0,
                idPregunta: uuidv4(),
                pregunta: '',
                idPreguntaTipo: 1
            })

            setAlternativas([])
            setError("")
            setErrorPregunta("")
        }
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPregunta(prevData => ({
            ...prevData,
            [name]: value
        }));
    }

    const onChangePreguntaTipo = (e) => {
        setPregunta(prevData => ({
            ...prevData,
            idPreguntaTipo: e.target.value
        }))
        if (e.target.value == 1)
            console.log("PPL 2")
        setAlternativas([
            { idAlternativa: uuidv4(), alternativa: ':(' },
            { idAlternativa: uuidv4(), alternativa: ':/' },
            { idAlternativa: uuidv4(), alternativa: ':|' },
            { idAlternativa: uuidv4(), alternativa: ':)' },
            { idAlternativa: uuidv4(), alternativa: ':D' },
        ])
        if (e.target.value == 2)
            setAlternativas([])
    }

    const eliminarAlternativa = (alternativaSeleccionada) => {
        console.log("PPL 3")
        setAlternativas(alternativas.filter(alternativa => alternativa.idAlternativa != alternativaSeleccionada.idAlternativa))
    }

    const nuevaAlternativa = () => {
        console.log("PPL 4")
        setAlternativas(prevAlternativas => [
            ...prevAlternativas,
            { idAlternativa: uuidv4(), alternativa: '' }
        ]);
    }

    const handleInputChangeAlternativa = (e, idAlternativa) => {
        const { value } = e.target;
        console.log("PPL 5")
        setAlternativas(prevAlternativas =>
            prevAlternativas.map(alternativa =>
                alternativa.idAlternativa === idAlternativa
                    ? { ...alternativa, alternativa: value }
                    : alternativa
            )
        );
    };

    const guardar = () => {
        console.log("Pregunta a guardar", pregunta)
        console.log("alternativas a guardar", alternativas)
        var sw = 1
        setError("")
        setErrorPregunta("")

        if (pregunta.idPreguntaTipo == 2 && alternativas.filter(x => x.alternativa != "").length < 2) {
            sw = 0
            setError("Debe haber al menos una alternativa no en blanco")
        }

        if (pregunta.pregunta == "") {
            sw = 0
            setErrorPregunta("La pregunta no puede estar vacia")
        }

        if (sw == 1) {
            console.log("PPL 6", alternativas)
            setAlternativas(alternativas.filter(alternativa => alternativa.alternativa != ""))

            onChange(
                {
                    ...pregunta,
                    cantAlternativas: alternativas.filter(alternativa => alternativa.alternativa != "").length,
                    alternativas: alternativas.filter(alternativa => alternativa.alternativa != "")
                })
            console.log("Guardado correcto")
        }
    }

    if (!isOpen) return null;

    console.log("alternatias", alternativas)

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modalContent}>
                <div className={styles.divFormulario}>
                    <h3>{titulo ? titulo : "Modificar pregunta"}</h3>
                    <InputText
                        id="pregunta"
                        name="pregunta"
                        miMarginTop={20}
                        miwidth={'100%'}
                        value={pregunta.pregunta}
                        required
                        type={"text"}
                        isReadOnly={isReadOnly}
                        onChangeInput={handleInputChange}
                        placeholder={"Pregunta..."}
                        mensajeError={errorPregunta}
                    />
                    <SelectComponent options={preguntaTipoList} value={pregunta.idPreguntaTipo} isReadOnly={isReadOnly} onChange={onChangePreguntaTipo}></SelectComponent>
                    {pregunta.idPreguntaTipo == 1 && (
                        <>
                            <h4>Opciones cuantitativas</h4>
                            <div className={styles.contenedorTipoCuantitativa}>
                                <img className={styles.imgCarita} src={cara1} />
                                <img className={styles.imgCarita} src={cara2} />
                                <img className={styles.imgCarita} src={cara3} />
                                <img className={styles.imgCarita} src={cara4} />
                                <img className={styles.imgCarita} src={cara5} />
                            </div>
                        </>
                    )}
                    {pregunta.idPreguntaTipo == 2 && (
                        <>
                            <h4>Alternativas</h4>
                            {alternativas.map((alternativa, index) => (
                                <div className={styles.divAlternativa} key={alternativa.idAlternativa}>
                                    <InputText
                                        id={`alternativa-${alternativa.idAlternativa}`}
                                        name={`alternativa-${alternativa.idAlternativa}`}
                                        miMarginTop={5}
                                        miwidth={'100%'}
                                        value={alternativa.alternativa}
                                        required
                                        type={"text"}
                                        isReadOnly={isReadOnly}
                                        onChangeInput={(e) => handleInputChangeAlternativa(e, alternativa.idAlternativa)}
                                        placeholder={`Pregunta ${index + 1}...`}
                                        mensajeError={""}
                                    />
                                    {!isReadOnly && (
                                        <MiniBoton icono={MiniBoton.ICONO_BORRAR} onClick={() => eliminarAlternativa(alternativa)}></MiniBoton>
                                    )}

                                </div>
                            ))}
                            {!isReadOnly && (
                                <div style={{ justifyContent: 'center', display: 'flex' }}>
                                    <BotonSecundario text="Agregar alternativa" icono={BotonPrincipal.ICONO_PREGUNTA_PERFIL} onClick={() => nuevaAlternativa()} />
                                </div>
                            )}


                        </>
                    )}

                    {!isReadOnly ? (
                        <div style={{ justifyContent: 'center', display: 'flex', marginTop: '20px' }}>
                            <BotonPrincipal text="Guardar y continuar" icono={BotonPrincipal.ICONO_GUARDAR} onClick={() => guardar()} />
                        </div>
                    ) : (
                        <div style={{ justifyContent: 'center', display: 'flex', marginTop: '20px' }}>
                            <BotonPrincipal text="Volver" icono={BotonPrincipal.ICONO_VOLVER} onClick={onClose} />
                        </div>
                    )}


                </div>
                {!isReadOnly && (
                    <div className={styles.divBotonera}>
                        <BotonSecundario text="Cancelar" onClick={onClose}></BotonSecundario>
                    </div>
                )}

                <EtiquetaError mensajeError={error} ></EtiquetaError>
            </div>
        </div>
    )
}

export default PreguntaEdicionModal;