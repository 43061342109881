
import stylesLogin from './../../../styles/LoginPage.module.css'
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import imgLogo from '../../../assets/images/logo.png'
import InputText from '../../../components/InputText/InputText.js';
import BotonPrincipal from '../../../components/boton-principal/BotonPrincipal.js';
import BotonSecundario from '../../../components/boton-secundario/BotonSecundario.js';

import { useLocation } from 'react-router-dom';
import { srvValidarCodigo } from '../../../services/registro/srvValidarCodigo.js';


function ValidarPage() {

    // Dentro del componente de la ruta "/validar"
    const location = useLocation();
    const correo = location.state.correo; // Aquí está tu data enviada

    console.log("correo", correo)

    const dispatch = useDispatch();

    const [errorCodigo, setErrorCodigo] = React.useState("");

    const navigate = useNavigate();
    const [codigo, setCodigo] = useState('');

    const registroEnServidor = () => {
        // console.log("asds")
        const fetchData = async () => {
            try {
                const userInfo = await srvValidarCodigo(correo, codigo);
                console.log(userInfo.data)

                if (userInfo.data.estado == 1) {
                    navigate("/registro/validacion-exitosa");
                }
                // navigate("/panel");
                else
                    setErrorCodigo("Código inválido")
            } catch (error) {
                // setError(error.message);
                setErrorCodigo("Código inválido")
            }
        };
        fetchData();
    }

    const handleInputChange = (e) => {

        const { name, value } = e.target;
        console.log(value)
        setCodigo(value);

    };


    const validarLogin = () => {
        let sw = 1
        setErrorCodigo("")
        if (codigo == "") {
            sw = 0
            setErrorCodigo("Código no puede estar vacio")
        }
        if (sw == 1) {
            registroEnServidor()
        }
    }


    return (
        <div className='App-fondo'>
            <div className={`Card ${stylesLogin.CardLogin}`} >
                <div className={stylesLogin.filaTitulo}>
                    <h1 className='tituloVerde'>
                        Validación de correo
                    </h1>
                    <img className={stylesLogin.logo} src={imgLogo}></img>
                </div>

                <div className={stylesLogin.filaSubtitulo}>
                    <label className='subtituloNegro'>
                        Ingresa el código enviado al correo '{correo}'
                    </label>
                </div>




                <div style={{ marginTop: "30px", display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                    <InputText
                        id="codigo"
                        name="codigo"
                        value={codigo}
                        type={"text"}
                        onChangeInput={handleInputChange}
                        width={'100%'}
                        required
                        placeholder={"Código"}
                        mensajeError={errorCodigo}></InputText>


                </div>




                <div style={{ width: '100%', marginTop: '30px' }}>
                    <BotonPrincipal text={"Validar"} onClick={() => validarLogin()}></BotonPrincipal>
                </div>

                {/* <Button variant="contained">Ingresar</Button> */}
                <hr></hr>

                <div className={stylesLogin.FilaNoEstasRegistrado}>
                    <BotonSecundario colorTexto='black' text={"¿Ya tienes una cuenta?"} onClick={() => navigate('/login')}></BotonSecundario>
                </div>



            </div>
        </div >
    )
}

export default ValidarPage;