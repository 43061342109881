
import styles from './../../styles/LoginPage.module.css'
import React, { useState, useEffect } from 'react';
import { getAuth } from '../../services/auth/getAuth.js'
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { iniciarSesion } from '../../redux/userActions.js';
import imgLogo from '../../assets/images/logo.png'
import InputText from '../../components/InputText/InputText.js';
import BotonPrincipal from '../../components/boton-principal/BotonPrincipal.js';
import BotonSecundario from '../../components/boton-secundario/BotonSecundario.js';
import EtiquetaError from '../../components/etiqueta-error/EtiquetaError.js';


function LoginPage(props) {
    const dispatch = useDispatch();

    const [errorUsuario, setErrorUsuario] = React.useState("");
    const [errorPassword, setErrorPassword] = React.useState("");
    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const [formData, setFormData] = useState({
        usuario: '',
        password: ''
    });

    const login = () => {
        // console.log("asds")
        const fetchData = async () => {
            try {
                const userInfo = await getAuth(formData.usuario, formData.password);
                console.log(userInfo)
                // console.log("authorization", userInfo.headers.getAuthorization)
                if (userInfo) {
                    dispatch(iniciarSesion(userInfo.data));
                    // props.handleLogged(userInfo.data)
                }
                // navigate("/panel");
                else
                    setError("Usuario/Password incorrecto")
            } catch (error) {
                // setError(error.message);
                setError("Usuario/Password incorrecto")
            }
        };
        fetchData();
    }

    const handleInputChange = (e) => {

        const { name, value } = e.target;
        // console.log(value)
        setFormData({ ...formData, [name]: value });

    };


    const registrar = () => {
        navigate("/registro/registro");
    }

    const miclick = () => {
        console.log("Clieckeado")
    }
    const validarLogin = () => {
        let sw = 1
        setErrorUsuario("")
        setErrorPassword("")
        if (formData.usuario == "") {
            sw = 0
            setErrorUsuario("Usuario no puede estar vacío")
        }

        if (formData.password == "") {
            sw = 0
            setErrorPassword("Password no puede estar vacío")
        }
        if (sw == 1) {
            login()
        }
    }


    return (
        <div className={styles.container}>
            <div className={styles.CardLogin} >
                <div className={styles.filaTitulo}>
                    <h1 className='tituloVerde'>
                        ¡Hola!, Bienvenido
                    </h1>
                    <img className={styles.logo} src={imgLogo}></img>
                </div>

                <div className={styles.filaSubtitulo}>
                    <label className='subtituloNegro'>
                        Ingresa a tu cuenta
                    </label>
                </div>



                <div style={{ marginTop: "30px" }}>
                    <InputText
                        id="usuario"
                        name="usuario"
                        value={formData.usuario}
                        type={"email"}
                        onChangeInput={handleInputChange}
                        required
                        placeholder={"Email"}
                        mensajeError={errorUsuario}></InputText>
                </div>

                <div style={{ marginTop: "20px" }}>
                    <InputText
                        id="password"
                        name="password"
                        value={formData.password}
                        type={"password"}
                        onChangeInput={handleInputChange}
                        required
                        placeholder={"Password"}
                        mensajeError={errorPassword}></InputText>
                </div>


                <div className={styles.FilaNoEstasRegistrado}>
                    <BotonSecundario text={"¿Olvidaste tu contraseña?"} onClick={() => { navigate('/recuperar-password/1-solicitar-correo') }}></BotonSecundario>
                </div>



                <BotonPrincipal text={"Ingresar"} swLabelDebeEstarCentrado={true} onClick={() => validarLogin()}></BotonPrincipal>
                {/* <Button variant="contained">Ingresar</Button> */}
                <EtiquetaError mensajeError={error}></EtiquetaError>

                <hr></hr>

                <div className={styles.FilaNoEstasRegistrado}>
                    <BotonSecundario colorTexto='black' text={"¿no estás registrado?"} onClick={() => registrar()}></BotonSecundario>
                </div>



            </div>
        </div >
    )
}

export default LoginPage;