
import styles from './BotonSecundario.module.css'
import React, { useState, useEffect } from 'react';

function BotonSecundario(props) {


    return (

        <a className={styles.botonSecundarioContainer} onClick={props.onClick}>

            <label style={{ color: props.colorTexto !== '' ? props.colorTexto : undefined }}>{props.text}</label>



        </a>
    )
}

export default BotonSecundario;