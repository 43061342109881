
import styles from './EtiquetaError.module.css'
import React, { useState, useEffect } from 'react';

function EtiquetaError({ mensajeError }) {


    return (

        <label className={styles.mensajeError}>{mensajeError}</label>


    )
}

export default EtiquetaError;