// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a {
  cursor: pointer;
}

.Incrementador_aContainerPadre__jk-ic {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 70px;
  width: 100%;
  border-radius: 8px;
  border: 1px;
  gap: 5px;
  margin-top: 20px;
}

.Incrementador_aContainer__IFNxj {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 100%;
  border-radius: 8px;
  border: 1px;
  gap: 5px;
  margin-top: 20px;
}

.Incrementador_botonPrincipalContainer__TJI75 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  background-color: var(--primary-color-seleccionado);
  border-radius: 50%;
  border: 1px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.Incrementador_botonPrincipalContainer__TJI75:hover {
  background-color: var(--primary-color);
}

.Incrementador_botonPrincipalContainer__TJI75:active {
  background-color: #357262;
}

.Incrementador_botonPrincipalContainer__TJI75:disabled {
  background-color: gray;
}

.Incrementador_icono__kg1Hq {
  fill: var(--primary-color);
}

.Incrementador_botonPrincipalContainer__TJI75:hover .Incrementador_icono__kg1Hq {
  fill: #ffffff;
}
`, "",{"version":3,"sources":["webpack://./src/components/incrementador/Incrementador.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;EACtB,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,WAAW;EACX,QAAQ;EACR,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,WAAW;EACX,QAAQ;EACR,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,WAAW;EACX,mDAAmD;EACnD,kBAAkB;EAClB,WAAW;EACX,wCAAwC;AAC1C;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,aAAa;AACf","sourcesContent":["a {\n  cursor: pointer;\n}\n\n.aContainerPadre {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  height: 70px;\n  width: 100%;\n  border-radius: 8px;\n  border: 1px;\n  gap: 5px;\n  margin-top: 20px;\n}\n\n.aContainer {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 50px;\n  width: 100%;\n  border-radius: 8px;\n  border: 1px;\n  gap: 5px;\n  margin-top: 20px;\n}\n\n.botonPrincipalContainer {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 40px;\n  width: 40px;\n  background-color: var(--primary-color-seleccionado);\n  border-radius: 50%;\n  border: 1px;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\n}\n\n.botonPrincipalContainer:hover {\n  background-color: var(--primary-color);\n}\n\n.botonPrincipalContainer:active {\n  background-color: #357262;\n}\n\n.botonPrincipalContainer:disabled {\n  background-color: gray;\n}\n\n.icono {\n  fill: var(--primary-color);\n}\n\n.botonPrincipalContainer:hover .icono {\n  fill: #ffffff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"aContainerPadre": `Incrementador_aContainerPadre__jk-ic`,
	"aContainer": `Incrementador_aContainer__IFNxj`,
	"botonPrincipalContainer": `Incrementador_botonPrincipalContainer__TJI75`,
	"icono": `Incrementador_icono__kg1Hq`
};
export default ___CSS_LOADER_EXPORT___;
