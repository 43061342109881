import axios from 'axios';

// Función para realizar la solicitud GET a la API
export const srvRegistrarNuevoUsuario = async (nombre, apellido, correo, password) => {
    try {



        const response = await axios.post(`${process.env.REACT_APP_SERVER_BACKEND}/v1/auth/registro/1-registro-inicial`, { nombre: nombre, apellido: apellido, correo: correo, password: password });

        if (response.status === 200) {
            console.log("response.data", response)
            return response;
        } else {
            throw new Error(`Error en la solicitud. Código de estado: ${response.status}`);
        }

    } catch (error) {
        console.error('Error al obtener los datos:', error);
        throw new Error(error);
    }
};
