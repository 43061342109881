// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"20\" height=\"20\" fill=\"currentColor\" viewBox=\"0 0 16 16\"><path d=\"M16 2s-1.333 2.667-8 10C1.333 4.667 0 2 0 2h16z\"/></svg>", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SelectComponent_inputContainerGlobal__aRwAk {
  display: flex;
  height: 80px;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-start;
}

.SelectComponent_inputContainer__ohR04 {
  box-sizing: border-box;
  display: flex;
  height: 66px;
  flex-direction: column;
  align-items: flex-start;

  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #f8fafc;
  border-width: 1px;
  border-style: solid;
  border-radius: 12px;
  border-color: gray;
}

.SelectComponent_focused__DfWZY {
  border-width: 2px;
  border-color: black;

  padding-left: 14px;
  padding-right: 14px;
  padding-top: 9px;
  padding-bottom: 9px;
  margin-top: 0px;
}

.SelectComponent_select__EhlO\\+ {
  width: 100%;

  padding: 8px 32px 8px 8px;
  border: 1px solid #ccc;
  border-radius: 15px;
  appearance: none;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___})
    no-repeat right 10px center;
  background-size: 20px;
  cursor: pointer;

  font-size: 1rem;
  font-weight: 500;
  color: black;
  -webkit-appearance: none;
  -moz-appearance: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/select/SelectComponent.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,sBAAsB;EACtB,yBAAyB;EACzB,2BAA2B;AAC7B;;AAEA;EACE,sBAAsB;EACtB,aAAa;EACb,YAAY;EACZ,sBAAsB;EACtB,uBAAuB;;EAEvB,kBAAkB;EAClB,mBAAmB;EACnB,iBAAiB;EACjB,oBAAoB;EACpB,yBAAyB;EACzB,iBAAiB;EACjB,mBAAmB;EACnB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;;EAEnB,kBAAkB;EAClB,mBAAmB;EACnB,gBAAgB;EAChB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,WAAW;;EAEX,yBAAyB;EACzB,sBAAsB;EACtB,mBAAmB;EACnB,gBAAgB;EAChB;+BAC6B;EAC7B,qBAAqB;EACrB,eAAe;;EAEf,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,wBAAwB;EACxB,qBAAqB;AACvB","sourcesContent":[".inputContainerGlobal {\n  display: flex;\n  height: 80px;\n  flex-direction: column;\n  align-content: flex-start;\n  justify-content: flex-start;\n}\n\n.inputContainer {\n  box-sizing: border-box;\n  display: flex;\n  height: 66px;\n  flex-direction: column;\n  align-items: flex-start;\n\n  padding-left: 15px;\n  padding-right: 15px;\n  padding-top: 10px;\n  padding-bottom: 10px;\n  background-color: #f8fafc;\n  border-width: 1px;\n  border-style: solid;\n  border-radius: 12px;\n  border-color: gray;\n}\n\n.focused {\n  border-width: 2px;\n  border-color: black;\n\n  padding-left: 14px;\n  padding-right: 14px;\n  padding-top: 9px;\n  padding-bottom: 9px;\n  margin-top: 0px;\n}\n\n.select {\n  width: 100%;\n\n  padding: 8px 32px 8px 8px;\n  border: 1px solid #ccc;\n  border-radius: 15px;\n  appearance: none;\n  background: url('data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"20\" height=\"20\" fill=\"currentColor\" viewBox=\"0 0 16 16\"><path d=\"M16 2s-1.333 2.667-8 10C1.333 4.667 0 2 0 2h16z\"/></svg>')\n    no-repeat right 10px center;\n  background-size: 20px;\n  cursor: pointer;\n\n  font-size: 1rem;\n  font-weight: 500;\n  color: black;\n  -webkit-appearance: none;\n  -moz-appearance: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputContainerGlobal": `SelectComponent_inputContainerGlobal__aRwAk`,
	"inputContainer": `SelectComponent_inputContainer__ohR04`,
	"focused": `SelectComponent_focused__DfWZY`,
	"select": `SelectComponent_select__EhlO+`
};
export default ___CSS_LOADER_EXPORT___;
