

import styles from './PaginaCaritas.module.css'
import React from 'react';
import BotonPrincipal from '../../../../../../components/boton-principal/BotonPrincipal.js';
import logo from '../../../../../../assets/images/logo.png'
import cara1 from '../../../../../../assets/icons/cara_1.png'
import cara2 from '../../../../../../assets/icons/cara_2.png'
import cara3 from '../../../../../../assets/icons/cara_3.png'
import cara4 from '../../../../../../assets/icons/cara_4.png'
import cara5 from '../../../../../../assets/icons/cara_5.png'


function PaginaCaritas({ onSiguiente, pregunta, alternativas, numeroPregunta, totalPreguntas }) {




    return (
        <div className={styles.fondoComponente}>
            <img className={styles.logo} src={logo} />
            <div className={styles.midiv} >
                <label className={styles.indexPregunta}>Pregunta {numeroPregunta} de {totalPreguntas}</label>
                <label className={styles.pregunta}>{pregunta}</label>

                <div className={styles.botonera}>
                    <a onClick={onSiguiente} >
                        <img src={cara1} />
                    </a>
                    <a onClick={onSiguiente} >
                        <img src={cara2} />
                    </a>
                    <a onClick={onSiguiente} >
                        <img src={cara3} />
                    </a>
                    <a onClick={onSiguiente} >
                        <img src={cara4} />
                    </a>
                    <a onClick={onSiguiente} >
                        <img src={cara5} />
                    </a>
                </div>

            </div>


        </div >

    )
}

export default PaginaCaritas;