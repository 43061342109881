

import styles from './SeleccionEncuestaPage.module.css'
import stylesPanel from '../../../../../styles/Panel.module.css'
import React, { useState, useRef, useEffect } from 'react';
import { BrowserRouter as Router, useLocation, useNavigate } from 'react-router-dom';
import HeaderTituloComponent from '../../../../../components/header-titulo/HeaderTituloComponent.js';
import InputText from '../../../../../components/InputText/InputText.js';
import BotonPrincipal from '../../../../../components/boton-principal/BotonPrincipal.js';

import { useDispatch, useSelector } from 'react-redux';
import { cerrarSesion } from '../../../../../redux/userActions.js';

import imagenSpotNoFoto from '../../../../../assets/images/spot-no-image.jpg'

import BotonSecundario from '../../../../../components/boton-secundario/BotonSecundario.js';
import SelectComponent from '../../../../../components/select/SelectComponent.js';
import { srvGuardarUsuarioExistente } from '../../../../../services/usuarios/srvGuardarUsuarioExistente.js';
import { srvSubirFotoPerfil } from '../../../../../services/usuarios/srvSubirFotoPerfil.js';
import ConfirmationModalComponent from '../../../../../components/confirmation-modal/ConfirmationModalComponent.js';
import { srvObtenerFotoPerfil } from '../../../../../services/usuarios/srvObtenerFotoPerfil.js';
import { srvGuardarNuevoUsuario } from '../../../../../services/usuarios/srvGuardarNuevoUsuario.js';
import { srvBuscarUsuario } from '../../../../../services/usuarios/srvBuscarUsuario.js';
import { srvBuscarTodosLosSpots } from '../../../../../services/spots/srvBuscarTodosLosSpots.js';
import Checkbox from '../../../../../components/checkbox/Checkbox.js';
import { srvBuscarSpot } from '../../../../../services/spots/srvBuscarSpot.js';
import { srvObtenerFotoSpot } from '../../../../../services/spots/srvObtenerFotoSpot.js';
import { srvGuardarSpotExistente } from '../../../../../services/spots/srvGuardarSpotExistente.js';
import { srvGuardarNuevoSpot } from '../../../../../services/spots/srvGuardarNuevoSpot.js';
import { srvSubirFotoSpot } from '../../../../../services/spots/srvSubirFotoSpot.js';
import { srvBuscarTodasLasEncuestas } from '../../../../../services/encuestas/srvBuscarTodasLasEncuestas.js';
import SimuladorPage from '../../encuestas/simulador/SimuladorModal.js';
import { srvActivarEncuesta } from '../../../../../services/encuestas/srvActivarEncuesta.js';

function SeleccionEncuestaPage(props) {
    const dispatch = useDispatch();
    // dispatch(cerrarSesion());
    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const [errorDescripcion, setErrorDescripcion] = useState('');
    const [errorSpot, setErrorSpot] = useState('');

    const [file, setFile] = useState(null);
    const [spots, setSpots] = useState([]);
    const [imageUrl, setImageUrl] = useState(imagenSpotNoFoto);
    const fileInputRef = useRef(null);

    const [isModalSimuladorOpen, setIsModalSimuladorOpen] = useState(false);

    const [imagenDesdeServidor, setImagenDesdeServidor] = useState(null);

    const [isModalOpen, setModalOpen] = useState(false);

    const [encuestas, setEncuestas] = useState([])


    const [isOpenInfoModal, setIsOpenInfoModal] = useState(null)
    const [mensajeConfirmacionDesdeServidor, setMensajeConfirmacionDesdeServidor] = useState("")

    const [encuestaSelected, setEncuestaSelected] = useState(null);

    const location = useLocation();
    const spot = location.state.spot; // Aquí está tu data enviada

    const perfiles = [{ texto: "Master", value: 1 },
    { texto: "Administrador", value: 2 },
    { texto: "Gestor", value: 3 }]

    const userData = useSelector(state => state.authInfo);


    const [formData, setFormData] = useState({
        idSpotHash: -1,
        spot: '',
        descripcion: '',
        fechaCreacion: '',
        creadoPor: '',
        imagenUrl: '',
        imagenBlob: null
    })


    React.useEffect(() => {
        console.log("spot", spot)
        if (spot)
            getEncuestas()


    }, [spot])





    const buscarSpot = (idSpotHash) => {
        const fetchData = async () => {
            try {
                const spotFromServer = await srvBuscarSpot(userData, idSpotHash);
                console.log(spotFromServer)
                // console.log("authorization", userInfo.headers.getAuthorization)
                if (spotFromServer) {
                    console.log("holi")

                    setFormData(prevFormData => ({
                        ...prevFormData,

                        idSpotHash: spotFromServer.data.idSpotHash,
                        spot: spotFromServer.data.spot,
                        descripcion: spotFromServer.data.descripcion,
                        fechaCreacion: spotFromServer.data.fechaCreacion,
                        creadoPor: spotFromServer.data.creadoPor,
                        imagenUrl: spotFromServer.data.imagenUrl || imagenSpotNoFoto

                    }));
                }
                // navigate("/panel");
                else
                    setError("Error al buscar los usuarios")
            } catch (error) {
                console.log("error.message", "+" + error + "+")
                if (error == 'Error: Error del servidor: 401') {
                    console.log("ahora si 401")
                    dispatch(cerrarSesion());
                } else {
                    setError('Error al procesar la solicitud');
                }


            }
        };
        fetchData();
    }

    useEffect(() => {
        if (formData.imagenUrl && formData.imagenUrl != '') {

            console.log('formData.imagenUrl nuevo:', formData.imagenUrl);
            cargarImagenFromServer();
        }
    }, [formData.imagenUrl]);


    const cargarImagenFromServer = async () => {
        const defaultImage = '../../../../../assets/icons/perfil.jpg'; // Ruta a la imagen por defecto en tu carpeta assets
        console.log("Cargando imagen:", formData.imagenUrl);
        if (formData.imagenUrl && formData.imagenUrl !== defaultImage && formData.imagenUrl !== "") {
            try {
                console.log("hola 1")
                const imageObjectURL = await srvObtenerFotoSpot(userData, formData.imagenUrl);
                setFormData(prevFormData => ({ ...prevFormData, imagenBlob: imageObjectURL }));
            } catch (error) {
                console.log("hola 2")
                setFormData(prevFormData => ({ ...prevFormData, imagenBlob: defaultImage }));
            }
        } else {
            console.log("hola 3")
            setFormData(prevFormData => ({ ...prevFormData, imagenBlob: defaultImage }));
        }
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        // console.log(value)
        setFormData({ ...formData, [name]: value });
    };





    const onFileChange = event => {
        setFile(event.target.files[0]);
        if (event.target.files[0]) {
            // Crear una URL para la imagen seleccionada y actualizar la imagen que se muestra
            // setImageUrl(URL.createObjectURL(event.target.files[0]));
            setFormData({ ...formData, imagenBlob: URL.createObjectURL(event.target.files[0]) })
        }
    };



    const guardar = () => {
        console.log(formData)
        let sw = 1
        if (formData.spot == "") {
            sw = 0
            setErrorSpot("Falta el nombre del spot")
        }
        if (formData.descripcion == "") {
            sw = 0
            setErrorDescripcion("Falta la descripción")
        }
        if (sw == 1) {
            setModalOpen(true)
        }
    }


    const getEncuestas = () => {
        const fetchData = async () => {
            try {
                console.log("Guardando formData", formData)
                let encuestasFromServer
                encuestasFromServer = await srvBuscarTodasLasEncuestas(userData, false);
                console.log(encuestasFromServer)
                // console.log("authorization", userInfo.headers.getAuthorization)
                if (encuestasFromServer) {
                    console.log("imageUrl", imageUrl)
                    console.log("usuariosFromServer.data", encuestasFromServer)

                    setEncuestas(encuestasFromServer.data)
                }
                // navigate("/panel");

                else
                    setError("Error al guardar cambios")
            } catch (error) {
                console.log("error.message", "+" + error + "+")
                if (error == 'Error: Error del servidor: 401') {
                    console.log("ahora si 401")
                    dispatch(cerrarSesion());
                } else {
                    setError('Error al procesar la solicitud');
                }
            }
        };
        fetchData();
    }

    const enviarCambiosAlServidor = () => {
        const fetchData = async () => {
            try {
                console.log("Guardando formData", formData)
                let spotFromServer
                if (formData.idSpotHash != -1)
                    spotFromServer = await srvGuardarSpotExistente(userData, formData);
                else
                    spotFromServer = await srvGuardarNuevoSpot(userData, formData);
                console.log(spotFromServer)
                // console.log("authorization", userInfo.headers.getAuthorization)
                if (spotFromServer) {
                    console.log("imageUrl", imageUrl)
                    console.log("usuariosFromServer.data", spotFromServer)

                    await srvSubirFotoSpot(
                        userData,
                        file,
                        spotFromServer.data.datos.idSpotHash,
                    );
                    navigate("/principal/spots");

                }
                // navigate("/panel");

                else
                    setError("Error al guardar cambios")
            } catch (error) {
                console.log("error.message", "+" + error + "+")
                if (error == 'Error: Error del servidor: 401') {
                    console.log("ahora si 401")
                    dispatch(cerrarSesion());
                } else {
                    setError('Error al procesar la solicitud');
                }
            }
        };
        fetchData();
    }

    const triggerFileInput = () => {
        fileInputRef.current.click();
    };

    const handleGuardar = () => {
        // Lógica para eliminar usuario

        enviarCambiosAlServidor()
        setModalOpen(false); // Cerrar el modal
    };

    const simular = (encuesta) => {


        setEncuestaSelected(encuesta)
        setIsModalSimuladorOpen(true)


    }

    const seleccionar = (encuesta) => {
        const fetchData = async () => {
            try {
                const spotsFromServer = await srvActivarEncuesta(userData, encuesta.idEncuestaHash, spot.idSpotHash);
                console.log("encuesta", encuesta)
                console.log(spotsFromServer)
                // console.log("authorization", userInfo.headers.getAuthorization)
                if (spotsFromServer) {
                    console.log(spotsFromServer.data)

                    setIsOpenInfoModal(true)
                    setMensajeConfirmacionDesdeServidor(spotsFromServer.data.mensaje)
                    navigate('/principal/spots')
                }
                // navigate("/panel");
                else
                    setError("Error al buscar los usuarios")
            } catch (error) {
                console.log("error.message", "+" + error + "+")
                if (error == 'Error: Error del servidor: 401') {
                    console.log("ahora si 401")
                    dispatch(cerrarSesion());
                } else {
                    setError('Error al procesar la solicitud');
                }


            }
        };
        fetchData()
    }

    console.log("formData", formData)

    return (
        <div className={stylesPanel.containerPrincipal}>
            <HeaderTituloComponent pagina={HeaderTituloComponent.PAGINA_SPOTS_SELECCION_REPORTE}></HeaderTituloComponent>

            <div className={stylesPanel.containerPanelSinBorde}>
                <label className={styles.resumenLicenciasSinUsar} style={{ marginTop: '20px' }} >Listado de licencias cargadas</label>

                {encuestas.map((encuesta, index) => (
                    <div key={index} className={styles.info}>
                        <svg xmlns="http://www.w3.org/2000/svg" width={70} style={{ marginRight: 10 }} viewBox="0 0 29 38">
                            <path id="Icon_awesome-clipboard-check" data-name="Icon awesome-clipboard-check" d="M23.625,4.5H18a4.5,4.5,0,0,0-9,0H3.375A3.376,3.376,0,0,0,0,7.875v24.75A3.376,3.376,0,0,0,3.375,36h20.25A3.376,3.376,0,0,0,27,32.625V7.875A3.376,3.376,0,0,0,23.625,4.5ZM13.5,2.813A1.688,1.688,0,1,1,11.813,4.5,1.683,1.683,0,0,1,13.5,2.813Zm8.522,16.3-10.055,9.97a.845.845,0,0,1-1.2-.007L4.964,23.217a.845.845,0,0,1,.007-1.2l2-1.983a.845.845,0,0,1,1.2.007L11.4,23.309l7.453-7.4a.845.845,0,0,1,1.2.007l1.983,2A.845.845,0,0,1,22.022,19.111Z" transform="translate(1 1)" fill="none" stroke="#000" stroke-width="2" />
                        </svg>
                        <div className={styles.divInfoLicencia} >
                            <label className={styles.resumenLicenciasSinUsar}>Nombre de la encuesta: {encuesta.titulo}</label>
                            <label className={styles.resumenLicenciasSinUsar}>Descripción: {encuesta.descripcion}</label>
                            <label className={styles.resumenLicenciasSinUsar}>Usuario creador: {encuesta.usuarioCreador}</label>

                        </div>
                        <div   >
                            <BotonPrincipal icono={BotonPrincipal.ICONO_SIMULAR} onClick={() => simular(encuesta)} text="Simular"></BotonPrincipal>
                            <div style={{ width: 10, height: 10 }}></div>
                            <BotonPrincipal icono={BotonPrincipal.ICONO_ACTIVAR} onClick={() => seleccionar(encuesta)} text="Activar encuesta"></BotonPrincipal>
                        </div>

                    </div>
                ))}

            </div>

            <ConfirmationModalComponent
                isOpen={isModalOpen}
                onClose={() => setModalOpen(false)}
                onConfirm={handleGuardar}
                title="Confirmar guardado">
                ¿Estás seguro de que deseas guardar este spot?
            </ConfirmationModalComponent>

            <SimuladorPage
                isOpen={isModalSimuladorOpen}
                onClose={() => setIsModalSimuladorOpen(false)}
                encuesta={encuestaSelected}

            />


            <ConfirmationModalComponent
                isOpen={isOpenInfoModal}
                onClose={() => setIsOpenInfoModal(false)}
                swModalInformativo={true}
                title="Activación">
                {mensajeConfirmacionDesdeServidor}
            </ConfirmationModalComponent>



        </div >
    )
}

export default SeleccionEncuestaPage;