import axios from 'axios';

// Función para realizar la solicitud GET a la API
export const srvCambiarContrasena = async (correo, codigo, password) => {
    try {

        const response = await axios.post(`${process.env.REACT_APP_SERVER_BACKEND}/v1/auth/recuperacion/3-cambio-contrasena`, { correo: correo, codigo: codigo, password: password });

        if (response.status === 200) {
            console.log("response.data", response)
            return response;
        } else {
            throw new Error(`Error en la solicitud. Código de estado: ${response.status}`);
        }

    } catch (error) {
        console.error('Error al obtener los datos:', error);
        throw new Error(error);
    }
};
