import React, { useState } from 'react';
import BotonPrincipal from '../boton-principal/BotonPrincipal';
import BotonSecundario from '../boton-secundario/BotonSecundario';
// Asegúrate de importar el archivo CSS o definir los estilos directamente 

const ConfirmationModalComponent = ({ isOpen, onClose, onConfirm, title, children, titleConfirmar = null, titleCancelar = null, swModalInformativo = null }) => {
    if (!isOpen) return null;

    return (
        <div style={{ zIndex: 1000, position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ background: 'white', padding: 20, borderRadius: 5, width: '400px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                <h2>{title}</h2>
                <div>{children}</div>
                <div style={{ marginTop: 20, display: 'flex', justifyContent: 'flex-end' }}>
                    {!swModalInformativo && (
                        <>
                            <BotonPrincipal onClick={onConfirm} marginRight={30} text={titleConfirmar && titleConfirmar != "" ? titleConfirmar : "Confirmar"} ></BotonPrincipal>
                            <BotonSecundario onClick={onClose} text={titleCancelar && titleCancelar != "" ? titleCancelar : "Cancelar"}></BotonSecundario>
                        </>
                    )}

                    {swModalInformativo == true && (
                        <>

                            <BotonSecundario onClick={onClose} text={titleCancelar && titleCancelar != "" ? titleCancelar : "Cerrar"}></BotonSecundario>
                        </>
                    )}



                </div>
            </div>
        </div>
    );
};


export default ConfirmationModalComponent;
