

import styles from './ActivarNuevoDispositivoPage.module.css'
import stylesPanel from '../../../../../../styles/Panel.module.css'
import React, { useState, useRef, useEffect } from 'react';
import { BrowserRouter as Router, useLocation, useNavigate } from 'react-router-dom';
import HeaderTituloComponent from '../../../../../../components/header-titulo/HeaderTituloComponent.js';

import BotonPrincipal from '../../../../../../components/boton-principal/BotonPrincipal.js';

import { useDispatch, useSelector } from 'react-redux';
import { cerrarSesion } from '../../../../../../redux/userActions.js';

import imagenSpotNoFoto from '../../../../../../assets/images/spot-no-image.jpg'

import BotonSecundario from '../../../../../../components/boton-secundario/BotonSecundario.js';

import ConfirmationModalComponent from '../../../../../../components/confirmation-modal/ConfirmationModalComponent.js';
import { srvBuscarSpot } from '../../../../../../services/spots/srvBuscarSpot.js';
import { srvObtenerFotoSpot } from '../../../../../../services/spots/srvObtenerFotoSpot.js';
import Incrementador from '../../../../../../components/incrementador/Incrementador.js';
import { srvBuscarCantidadTotemsSinActivar } from '../../../../../../services/totems/srvBuscarCantidadTotemsSinActivar.js';
import CuadroAyuda from '../../../../../../components/cuadro-ayuda/CuadroAyuda.js';
import ProgressBar from '../../../../../../components/progress-bar/ProgressBar.js';
import { srvBuscarTotemsBySpot } from '../../../../../../services/totems/srvBuscarTotemsBySpot.js';
import { srvActivarNuevosTotems } from '../../../../../../services/spots/srvActivarNuevosTotems.js';
import InputText from '../../../../../../components/InputText/InputText.js';
import EtiquetaError from '../../../../../../components/etiqueta-error/EtiquetaError.js';
import { srvEnviarCodigoValidacion } from '../../../../../../services/totems/srvEnviarCodigoValidacion.js';

function ActivarNuevoDispositivoPage(props) {
    const dispatch = useDispatch();
    // dispatch(cerrarSesion());
    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const [errorDescripcion, setErrorDescripcion] = useState('');
    const [errorSpot, setErrorSpot] = useState('');

    const [file, setFile] = useState(null);
    const [totems, setTotems] = useState([]);
    const [imageUrl, setImageUrl] = useState(imagenSpotNoFoto);
    const fileInputRef = useRef(null);


    const [imagenDesdeServidor, setImagenDesdeServidor] = useState(null);

    const [isModalOpen, setModalOpen] = useState(false);
    const [codigo, setCodigo] = useState('');
    const [cantidadTotemsSinActivar, setCantidadTotemsSinActivar] = useState(null);
    const [cantidadTotemsAAgregar, setCantidadTotemsAAgregar] = useState(null)



    const location = useLocation();
    const spot = location.state.spot; // Aquí está tu data enviada
    const idTotemHash = location.state.idTotemHash

    const perfiles = [{ texto: "Master", value: 1 },
    { texto: "Administrador", value: 2 },
    { texto: "Gestor", value: 3 }]

    const userData = useSelector(state => state.authInfo);


    const [formData, setFormData] = useState({
        idSpotHash: -1,
        spot: '',
        descripcion: '',
        fechaCreacion: '',
        creadoPor: '',
        imagenUrl: '',
        imagenBlob: null
    })

    React.useEffect(() => {
        buscarCantidadTotemsSinActivar()
    }, [])


    React.useEffect(() => {
        console.log("spot", spot)
        if (spot) {
            buscarSpot(spot.idSpotHash)
            buscarTotemsBySpot(spot.idSpotHash)
        }

    }, [spot])





    const buscarSpot = (idSpotHash) => {
        const fetchData = async () => {
            try {
                const spotFromServer = await srvBuscarSpot(userData, idSpotHash);
                console.log(spotFromServer)
                // console.log("authorization", userInfo.headers.getAuthorization)
                if (spotFromServer) {
                    console.log("holi")

                    setFormData(prevFormData => ({
                        ...prevFormData,

                        idSpotHash: spotFromServer.data.idSpotHash,
                        spot: spotFromServer.data.spot,
                        descripcion: spotFromServer.data.descripcion,
                        fechaCreacion: spotFromServer.data.fechaCreacion,
                        creadoPor: spotFromServer.data.creadoPor,
                        imagenUrl: spotFromServer.data.imagenUrl || imagenSpotNoFoto

                    }));
                }
                // navigate("/panel");
                else
                    setError("Error al buscar los usuarios")
            } catch (error) {
                console.log("error.message", "+" + error + "+")
                if (error == 'Error: Error del servidor: 401') {
                    console.log("ahora si 401")
                    dispatch(cerrarSesion());
                } else {
                    setError('Error al procesar la solicitud');
                }


            }
        };
        fetchData();
    }

    const buscarTotemsBySpot = (idSpotHash) => {
        const fetchData = async () => {
            try {
                const totemsFromServer = await srvBuscarTotemsBySpot(userData, idSpotHash);
                console.log(totemsFromServer)
                // console.log("authorization",  userInfo.headers.getAuthorization) 
                if (totemsFromServer) {
                    console.log("holi")
                    setTotems(totemsFromServer.data.datos.totems)

                }
                // navigate("/panel");  
                else
                    setError("Error al buscar los usuarios")
            } catch (error) {
                console.log("error.message", "+" + error + "+")
                if (error == 'Error: Error del servidor: 401') {
                    console.log("ahora si 401")
                    dispatch(cerrarSesion());
                } else {
                    setError('Error al procesar la solicitud');
                }


            }
        };
        fetchData();
    }
    useEffect(() => {
        if (formData.imagenUrl && formData.imagenUrl != '') {

            console.log('formData.imagenUrl nuevo:', formData.imagenUrl);
            cargarImagenFromServer();
        }
    }, [formData.imagenUrl]);


    const cargarImagenFromServer = async () => {
        const defaultImage = '../../../../../assets/icons/perfil.jpg'; // Ruta a la imagen por defecto en tu carpeta assets
        console.log("Cargando imagen:", formData.imagenUrl);
        if (formData.imagenUrl && formData.imagenUrl !== defaultImage && formData.imagenUrl !== "") {
            try {
                console.log("hola 1")
                const imageObjectURL = await srvObtenerFotoSpot(userData, formData.imagenUrl);
                setFormData(prevFormData => ({ ...prevFormData, imagenBlob: imageObjectURL }));
            } catch (error) {
                console.log("hola 2")
                setFormData(prevFormData => ({ ...prevFormData, imagenBlob: defaultImage }));
            }
        } else {
            console.log("hola 3")
            setFormData(prevFormData => ({ ...prevFormData, imagenBlob: defaultImage }));
        }
    };


    const solicitarActivacionTotems = () => {
        const fetchData = async () => {
            try {

                let respuesta = await srvActivarNuevosTotems(userData, spot.idSpotHash, cantidadTotemsAAgregar);
                console.log("respuesta", respuesta)
                if (respuesta.data.estado == 1 && respuesta.data.datos.exito == 1) {
                    buscarSpot(spot.idSpotHash)
                    buscarTotemsBySpot(spot.idSpotHash)
                    buscarCantidadTotemsSinActivar()
                }
            } catch (error) {
                console.log("error.message", "+" + error + "+")
                if (error == 'Error: Error del servidor: 401') {
                    console.log("ahora si 401")
                    dispatch(cerrarSesion());
                } else {
                    setError('Error al procesar la solicitud');
                }
            }
        };
        fetchData();
    }

    const triggerFileInput = () => {
        fileInputRef.current.click();
    };

    const handleActivarNuevosTotems = () => {
        // Lógica para eliminar usuario

        solicitarActivacionTotems()
        setModalOpen(false); // Cerrar el modal
    };

    const buscarCantidadTotemsSinActivar = () => {
        // console.log("asds")
        const fetchData = async () => {
            try {
                const spotsFromServer = await srvBuscarCantidadTotemsSinActivar(userData);
                console.log(spotsFromServer)
                // console.log("authorization", userInfo.headers.getAuthorization)
                if (spotsFromServer) {
                    console.log(spotsFromServer.data.datos.cantidadTotemsSinActivar)

                    setCantidadTotemsSinActivar(spotsFromServer.data.datos.cantidadTotemsSinActivar)
                    // Cargar las imágenes después de actualizar el estado de los usuarios

                    // props.handleLogged(userInfo.data)
                }
                // navigate("/panel");
                else
                    setError("Error al buscar los usuarios")
            } catch (error) {
                console.log("error.message", "+" + error + "+")
                if (error == 'Error: Error del servidor: 401') {
                    console.log("ahora si 401")
                    dispatch(cerrarSesion());
                } else {
                    setError('Error al procesar la solicitud');
                }


            }
        };
        fetchData();
    }

    const activarNuevoDispositivo = (idTotemHash) => {
        console.log("TODO Activar dispositivo en " + idTotemHash)
    }
    const agregarTotems = () => {
        console.log("Se agregarán  " + cantidadTotemsAAgregar + " nuevos totems")
    }
    console.log("codigo", codigo)

    const validarCodigo = () => {
        let sw = 1
        if (codigo.length != 6) {
            sw = 0
            setError("El código debe tener 6 digitos")

        }
        if (sw == 1) {
            enviarCodigoAlServidor()
        }

    }

    const enviarCodigoAlServidor = () => {
        const fetchData = async () => {
            try {
                const respuesta = await srvEnviarCodigoValidacion(userData, codigo, idTotemHash);
                console.log(respuesta)
                // console.log("authorization", userInfo.headers.getAuthorization)
                if (respuesta.data.estado == 1) {
                    navigate(-1)
                }
                // navigate("/panel");
                else
                    setError("Error al buscar los usuarios")
            } catch (error) {
                console.log("error.message", "+" + error + "+")
                if (error == 'Error: Error del servidor: 401') {
                    console.log("ahora si 401")
                    dispatch(cerrarSesion());
                } else {
                    setError('Error al procesar la solicitud');
                }


            }
        };
        fetchData();
    }

    return (
        <div className={stylesPanel.containerPrincipal}>
            <HeaderTituloComponent pagina={HeaderTituloComponent.PAGINA_LICENCIAS_NUEVO_DISPOSITIVO}></HeaderTituloComponent>

            <div className={stylesPanel.containerPanel}>
                <div className={styles.containerUsuario} style={{ marginBottom: '20px', borderStyle: 'none' }}>
                    <div className={styles.divCampos}>
                        <img src={formData.imagenBlob ? formData.imagenBlob : imagenSpotNoFoto} onClick={triggerFileInput} className={styles.circleImage} width={400} height={400} />
                        <div style={{ marginTop: '10px' }} >

                            <div className={styles.divCamposItem}>
                                <label className={styles.atributo}>Spot:</label>
                                <label className={styles.valor}>{formData.spot}</label>
                            </div>


                        </div>

                    </div>

                    <div className={styles.divAyuda}>
                        <label className={styles.titulo}>Pasos para activar nuevo dispositivo</label>
                        <label className={styles.paso}>1-Abrir la aplicación Spoti en el dispositivo</label>
                        <div className={styles.subAyuda}>
                            <label className={styles.paso}>¿No tienes la aplicación?, entonces sigue estos pasos</label>
                            <div style={{ marginTop: 10 }}>
                                <label className={styles.subpaso}>Paso 1: Accede a la Configuración</label>
                                <p className={styles.subdescripcion}>Abre la aplicación <strong>Configuración</strong> en tu dispositivo Android.</p>
                            </div>
                            <div>
                                <label className={styles.subpaso}>Paso 2: Información del Teléfono</label>
                                <p className={styles.subdescripcion}>Desplázate hacia abajo y selecciona <strong>Acerca del teléfono</strong> o <strong>Acerca del dispositivo</strong>.</p>
                            </div>
                            <div>
                                <label className={styles.subpaso}>Paso 3: Número de Compilación</label>
                                <p className={styles.subdescripcion}>Desplázate hasta encontrar el <strong>Número de compilación</strong>. Toca el <strong>Número de compilación</strong> siete veces. Deberás ingresar tu PIN o patrón de desbloqueo si tienes uno configurado.</p>
                            </div>
                            <div>
                                <label className={styles.subpaso}>Paso 4: Confirmación</label>
                                <p className={styles.subdescripcion}>Verás un mensaje que dice "¡Ahora eres un desarrollador!" o similar.</p>
                            </div>
                            <div>
                                <label className={styles.subpaso}>Paso 5: Accede a las Opciones de Desarrollador</label>
                                <p className={styles.subdescripcion}>Vuelve a la aplicación <strong>Configuración</strong>. Desplázate hacia abajo y selecciona <strong>Sistema</strong> (puede variar según el dispositivo). Selecciona <strong>Opciones de desarrollador</strong>.</p>
                            </div>
                            <div>
                                <label className={styles.subpaso}>Paso 6: Depuración USB (Opcional)</label>
                                <p className={styles.subdescripcion}>Activa <strong>Depuración USB</strong> si necesitas depurar aplicaciones directamente desde tu computadora.</p>
                            </div>
                            <div>
                                <label className={styles.subpaso}>Paso 7: Fuentes Desconocidas</label>
                                <p className={styles.subdescripcion}>Sal de las Opciones de Desarrollador y regresa a la pantalla principal de Configuración. Ve a <strong>Seguridad</strong> o <strong>Aplicaciones</strong> (puede variar según el dispositivo). Habilita la opción <strong>Fuentes desconocidas</strong> o <strong>Instalar aplicaciones desconocidas</strong>.</p>
                            </div>
                            <div>
                                <label className={styles.subpaso}>Paso 8: Permitir Instalación desde Ubicaciones Específicas</label>
                                <p className={styles.subdescripcion}>En versiones más recientes de Android, es posible que necesites permitir la instalación desde ubicaciones específicas. Por ejemplo, si estás instalando una aplicación desde un navegador, selecciona el navegador de la lista y activa <strong>Permitir desde esta fuente</strong>.</p>
                            </div>
                        </div>
                        <label className={styles.paso}>2-Abre la opción de Enlazar con Totem en la aplicación</label>
                        <label className={styles.paso}>3-Ingresa el código que te aparecerá en la pantalla</label>

                        <div className={styles.divInput}>
                            <div> <InputText
                                id="codigo"
                                name="codigo"
                                miMarginTop={20}
                                value={codigo}
                                required
                                type={"numeric"}
                                onChangeInput={(e) => setCodigo(e.target.value)}
                                placeholder={"Código..."}
                                mensajeError={errorSpot}></InputText>
                            </div>
                            <div>   <BotonPrincipal text="Validar código" onClick={() => validarCodigo()} width={'150px'} icono={BotonPrincipal.ICONO_DISPOSITIVO} />
                            </div>
                            <div>
                                <EtiquetaError mensajeError={error}></EtiquetaError>
                            </div>




                        </div>


                    </div>



                </div>

            </div>

            <ConfirmationModalComponent
                isOpen={isModalOpen}
                onClose={() => setModalOpen(false)}
                onConfirm={handleActivarNuevosTotems}
                title="Confirmar activación totems"
                titleConfirmar={cantidadTotemsAAgregar == 1 ? 'Si, agrega el nuevo totem' : `Si, Agrega los ${cantidadTotemsAAgregar} totems`}>
                {cantidadTotemsAAgregar == 1 ? `Vas a activar un nuevo totem${cantidadTotemsSinActivar > 1 ? ` de los ${cantidadTotemsSinActivar} disponibles` : ''}. Los Totems son intransferibles entre Spots y esta acción no se puede deshacer. ¿Deseas continuar?` : `Vas a activar ${cantidadTotemsAAgregar} nuevos Totems de los ${cantidadTotemsSinActivar} disponibles. Los Totems son intransferibles entre Spots y esta acción no se puede deshacer. ¿Deseas continuar?`}

            </ConfirmationModalComponent>

        </div >
    )
}

export default ActivarNuevoDispositivoPage;