

import styles from './UsuariosPage.module.css'
import stylesPanel from '../../../../styles/Panel.module.css'
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
import HeaderTituloComponent from '../../../../components/header-titulo/HeaderTituloComponent.js';
import InputText from '../../../../components/InputText/InputText.js';
import BotonPrincipal from '../../../../components/boton-principal/BotonPrincipal.js';
import { srvBuscarTodasLasEncuestas } from '../../../../services/encuestas/srvBuscarTodasLasEncuestas.js';
import { useDispatch, useSelector } from 'react-redux';
import { cerrarSesion } from '../../../../redux/userActions.js';

import imagenPerfil from '../../../../assets/icons/perfil.jpg'
import { srvBuscarTodosLosUsuarios } from '../../../../services/usuarios/srvBuscarTodosLosUsuarios.js';
import { srvObtenerFotoPerfil } from '../../../../services/usuarios/srvObtenerFotoPerfil.js';
import axios from 'axios';


function UsuariosPage(props) {
    const dispatch = useDispatch();
    // dispatch(cerrarSesion());
    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const [errorBuscar, setErrorBuscar] = useState('');
    const [textoBuscado, setTextoBuscado] = useState('');
    const [usuariosSinFiltrar, setUsuariosSinFiltrar] = useState([]);
    const [usuariosFiltrados, setUsuariosFiltrados] = useState([]);

    const userData = useSelector(state => state.authInfo);

    React.useEffect(() => {
        getUsuariosFromServer()
    }, [])


    useEffect(() => {
        console.log("Nuevo texto a buscar ", textoBuscado)
        console.log("usuariosSinFiltrar ", usuariosSinFiltrar)
        setUsuariosFiltrados(usuariosSinFiltrar.filter(usuario => {
            const textoBuscadoEnMinusculas = textoBuscado.toLowerCase();
            return usuario.nombre.toLowerCase().includes(textoBuscadoEnMinusculas) || usuario.apellido.toLowerCase().includes(textoBuscadoEnMinusculas) || usuario.correo.toLowerCase().includes(textoBuscadoEnMinusculas) || usuario.usuarioTipo.toLowerCase().includes(textoBuscadoEnMinusculas)
        }
        ))

    }, [textoBuscado, usuariosSinFiltrar]);



    const getUsuariosFromServer = () => {
        // console.log("asds")
        const fetchData = async () => {
            try {
                const usuariosFromServer = await srvBuscarTodosLosUsuarios(userData);
                console.log(usuariosFromServer)
                // console.log("authorization", userInfo.headers.getAuthorization)
                if (usuariosFromServer) {
                    console.log(usuariosFromServer.data)

                    setUsuariosSinFiltrar(usuariosFromServer.data);
                    // Cargar las imágenes después de actualizar el estado de los usuarios
                    cargarImagenes(usuariosFromServer.data);
                    // props.handleLogged(userInfo.data)
                }
                // navigate("/panel");
                else
                    setError("Error al buscar los usuarios")
            } catch (error) {
                console.log("error.message", "+" + error + "+")
                if (error == 'Error: Error del servidor: 401') {
                    console.log("ahora si 401")
                    dispatch(cerrarSesion());
                } else {
                    setError('Error al procesar la solicitud');
                }


            }
        };
        fetchData();
    }



    const defaultImage = '../../../../assets/icons/perfil.jpg'; // Ruta a la imagen por defecto en tu carpeta assets

    const cargarImagenes = async (usuarios) => {
        const usuariosConImagenes = await Promise.all(usuarios.map(async usuario => {
            if (usuario.imagenUrl && usuario.imagenUrl !== defaultImage && usuario.imagenUrl !== "") {
                try {
                    const imageObjectURL = await srvObtenerFotoPerfil(userData, usuario.imagenUrl)
                    return { ...usuario, imagenBlob: imageObjectURL };
                } catch (error) {
                    console.error(`Error al cargar la imagen para el usuario ${usuario.id}:`, error);
                    return { ...usuario, imagenBlob: usuario };
                }
            } else {
                return { ...usuario, imagenBlob: imagenPerfil };
            }
        }));

        setUsuariosSinFiltrar(usuariosConImagenes);
    };

    const handleInputChange = (e) => {

        const { name, value } = e.target;
        console.log("Texto buscado", value)
        setTextoBuscado(value)

    };
    const crearUsuario = () => {
        navigate("edicion", { state: { usuario: null } })
    }
    return (
        <div className={stylesPanel.containerPrincipal}>
            <HeaderTituloComponent pagina={HeaderTituloComponent.PAGINA_USUARIOS_PRINCIPAL}></HeaderTituloComponent>

            <div className={stylesPanel.containerPanel}>
                <div className={styles.containerFilaBusqueda}>
                    <InputText
                        id="buscar"
                        name="buscar"
                        value={textoBuscado}
                        type={"search"}
                        onChangeInput={handleInputChange}
                        placeholder={"Busca usuarios..."}
                        mensajeError={errorBuscar}></InputText>
                    {/* <BarraBuscar handleNuevaBusqueda={handleNuevaBusqueda} toolTip={"Busca encuestas"} habilitarBusqueda={true}></BarraBuscar> */}

                    <BotonPrincipal text="Crear" icono={BotonPrincipal.ICONO_CREAR} onClick={() => crearUsuario()} />

                </div>



                <div className={styles.containerGrillaUsuarios}  >
                    {usuariosFiltrados.map((usuario, index) => (
                        <div key={index} className={styles.containerUsuario} style={{ marginBottom: '20px' }}>
                            <label className={styles.titulo}>{usuario.nombre} {usuario.apellido}</label>
                            <div className={styles.divTodoEnUnaFila}>
                                <img src={usuario.imagenBlob ? usuario.imagenBlob : imagenPerfil} className={styles.circleImage} width={200} height={200} />
                                <div className={styles.divCampos} >

                                    <div className={styles.divCamposItem}>
                                        <label className={styles.atributo}>Perfil:</label>
                                        <label className={styles.valor}>{usuario.usuarioTipo}</label>
                                    </div>
                                    <div className={styles.divCamposItem}>
                                        <label className={styles.atributo}>Correo:</label>
                                        <label className={styles.valor}>{usuario.correo}</label>
                                    </div>

                                    <div className={styles.divCamposItem}>
                                        <label className={styles.atributo}>Fecha de activación:</label>
                                        <label className={styles.valor}>{usuario.fechaCreacion}</label>
                                    </div>

                                </div>
                            </div>


                            <div className={styles.divBotonera}>
                                <BotonPrincipal text="Modificar" onClick={() => navigate("edicion", { state: { usuario: usuario } })} width={'150px'} icono={BotonPrincipal.ICONO_MODIFICAR} />
                                <div style={{ width: '10px' }}></div>
                                <BotonPrincipal text="Eliminar" width={'150px'} icono={BotonPrincipal.ICONO_ELIMINAR} />
                            </div>

                        </div>
                    ))}


                </div>

            </div>



        </div >
    )
}

export default UsuariosPage;