// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* PasswordStrength.module.css */
.PasswordStrength_container__EIq\\+3 {
  display: flex;
  align-items: center;
  font-size: 0.4rem;
  font-weight: bold;
}

.PasswordStrength_indicator__Lzu4l {
  width: 100px; /* Ajusta el ancho según tus necesidades */
  height: 10px; /* Ajusta la altura según tus necesidades */
  border-radius: 10px;
  background-color: #ffd966; /* Color por defecto para 'weak' */
  margin-right: 10px;
}

.PasswordStrength_Segura__79kJV {
  background-color: #9acd32; /* Color para 'strong' */
}
`, "",{"version":3,"sources":["webpack://./src/components/password-strength/PasswordStrength.module.css"],"names":[],"mappings":"AAAA,gCAAgC;AAChC;EACE,aAAa;EACb,mBAAmB;EACnB,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,YAAY,EAAE,0CAA0C;EACxD,YAAY,EAAE,2CAA2C;EACzD,mBAAmB;EACnB,yBAAyB,EAAE,kCAAkC;EAC7D,kBAAkB;AACpB;;AAEA;EACE,yBAAyB,EAAE,wBAAwB;AACrD","sourcesContent":["/* PasswordStrength.module.css */\n.container {\n  display: flex;\n  align-items: center;\n  font-size: 0.4rem;\n  font-weight: bold;\n}\n\n.indicator {\n  width: 100px; /* Ajusta el ancho según tus necesidades */\n  height: 10px; /* Ajusta la altura según tus necesidades */\n  border-radius: 10px;\n  background-color: #ffd966; /* Color por defecto para 'weak' */\n  margin-right: 10px;\n}\n\n.Segura {\n  background-color: #9acd32; /* Color para 'strong' */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PasswordStrength_container__EIq+3`,
	"indicator": `PasswordStrength_indicator__Lzu4l`,
	"Segura": `PasswordStrength_Segura__79kJV`
};
export default ___CSS_LOADER_EXPORT___;
