

import styles from './EncuestasEdicionPage.module.css'
import stylesPanel from '../../../../../styles/Panel.module.css'
import React, { useState, useRef, useEffect } from 'react';
import { BrowserRouter as Router, useLocation, useNavigate } from 'react-router-dom';
import HeaderTituloComponent from '../../../../../components/header-titulo/HeaderTituloComponent.js';
import InputText from '../../../../../components/InputText/InputText.js';
import BotonPrincipal from '../../../../../components/boton-principal/BotonPrincipal.js';

import { useDispatch, useSelector } from 'react-redux';
import { cerrarSesion } from '../../../../../redux/userActions.js';

import imagenSpotNoFoto from '../../../../../assets/images/spot-no-image.jpg'

import BotonSecundario from '../../../../../components/boton-secundario/BotonSecundario.js';
import SelectComponent from '../../../../../components/select/SelectComponent.js';
import { srvGuardarUsuarioExistente } from '../../../../../services/usuarios/srvGuardarUsuarioExistente.js';
import { srvSubirFotoPerfil } from '../../../../../services/usuarios/srvSubirFotoPerfil.js';
import ConfirmationModalComponent from '../../../../../components/confirmation-modal/ConfirmationModalComponent.js';
import { srvObtenerFotoPerfil } from '../../../../../services/usuarios/srvObtenerFotoPerfil.js';
import { srvGuardarNuevoUsuario } from '../../../../../services/usuarios/srvGuardarNuevoUsuario.js';
import { srvBuscarUsuario } from '../../../../../services/usuarios/srvBuscarUsuario.js';
import { srvBuscarTodosLosSpots } from '../../../../../services/spots/srvBuscarTodosLosSpots.js';
import Checkbox from '../../../../../components/checkbox/Checkbox.js';
import { srvBuscarSpot } from '../../../../../services/spots/srvBuscarSpot.js';
import { srvObtenerFotoSpot } from '../../../../../services/spots/srvObtenerFotoSpot.js';
import { srvGuardarSpotExistente } from '../../../../../services/spots/srvGuardarSpotExistente.js';
import { srvGuardarNuevoSpot } from '../../../../../services/spots/srvGuardarNuevoSpot.js';
import { srvSubirFotoSpot } from '../../../../../services/spots/srvSubirFotoSpot.js';
import { srvBuscarEncuesta } from '../../../../../services/encuestas/srvBuscarEncuesta.js';
import MiniBoton from '../../../../../components/mini-boton/MiniBoton.js';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { srvBuscarTodosLosEjes } from '../../../../../services/encuestas/srvBuscarTodosLosEjes.js';
import SeleccionPreguntaPerfilModal from './seleccion-pregunta-perfil/SeleccionPreguntaPerfilModal.js';
import PreguntaEdicionModal from './pregunta-edicion/PreguntaEdicionModal.js';
import { srvGuardarNuevaEncuesta } from '../../../../../services/spots/srvGuardarNuevaEncuesta.js';
import { srvGuardarEncuestaExistente } from '../../../../../services/spots/srvGuardarEncuestaExistente.js';


function EncuestasEdicionPage(props) {
    const dispatch = useDispatch();
    // dispatch(cerrarSesion());
    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const [errorDescripcion, setErrorDescripcion] = useState('');
    const [errorSpot, setErrorSpot] = useState('');
    const [errorEje, setErrorEje] = useState('');

    const [file, setFile] = useState(null);
    const [spots, setSpots] = useState([]);
    const [imageUrl, setImageUrl] = useState(imagenSpotNoFoto);
    const fileInputRef = useRef(null);


    const [imagenDesdeServidor, setImagenDesdeServidor] = useState(null);

    const [isModalOpen, setModalOpen] = useState(false);
    const [isModalSeleccionPerfillOpen, setIsModalSeleccionPerfillOpen] = useState(false);
    const [isModalPreguntaEdicionOpen, setIsModalPreguntaEdicionOpen] = useState(false);
    const [preguntaSeleccionada, setPreguntaSeleccionada] = useState(null);
    const [isReadOnly, setIsReadOnly] = useState(false)

    const [ejes, setEjes] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null)



    const location = useLocation();

    const encuesta = location.state ? location.state.encuesta : null;

    React.useEffect(() => {
        // console.log("location.state", location.state)
        if (location.state && location.state.selectedItem) {
            setSelectedItem(location.state.selectedItem);
        }
    }, [location.state]);


    const [droppablePreguntasId, setDroppablePreguntasId] = useState('hello');
    const [droppablePreguntasPerfilId, setDroppablePreguntasPerfilId] = useState('hola');


    const preguntaTipoList = [
        { value: 1, texto: "Seleccion cuantitativa" },
        { value: 2, texto: "Alternativas múltiples" },
    ]

    const userData = useSelector(state => state.authInfo);


    const [formData, setFormData] = useState({
        idEncuestaHash: -1,
        titulo: '',
        descripcion: '',
        idEncuestaEje: -1,
        preguntasPerfil: [],
        preguntas: []
    })


    React.useEffect(() => {
        // console.log("encuesta", encuesta)
        if (encuesta)
            buscarEncuesta(encuesta.idEncuestaHash)
        buscarEjes()
    }, [encuesta])


    // React.useEffect(() => {
    //     console.log("location.state.preguntaPerfil", location.state)
    //     if (location.state) {
    //         console.log("Nuevo item seleccionado ", location.state)
    //     }
    // }, [location.state]);



    const buscarEncuesta = (idEncuestaHash) => {
        const fetchData = async () => {
            try {
                const encuestaFromServer = await srvBuscarEncuesta(userData, idEncuestaHash);
                // console.log(encuestaFromServer)
                // console.log("authorization", userInfo.headers.getAuthorization)
                if (encuestaFromServer) {
                    // console.log("holi")

                    setFormData(prevFormData => ({
                        ...prevFormData,

                        idEncuestaHash: encuestaFromServer.data.datos.idEncuestaHash,
                        titulo: encuestaFromServer.data.datos.titulo,
                        descripcion: encuestaFromServer.data.datos.descripcion,
                        idEncuestaEje: encuestaFromServer.data.datos.idEncuestaEje,
                        preguntasPerfil: encuestaFromServer.data.datos.preguntasPerfil,
                        preguntas: encuestaFromServer.data.datos.preguntas

                    }));
                }
                // navigate("/panel");
                else
                    setError("Error al buscar los usuarios")
            } catch (error) {
                console.log("error.message", "+" + error + "+")
                if (error == 'Error: Error del servidor: 401') {
                    console.log("ahora si 401")
                    dispatch(cerrarSesion());
                } else {
                    setError('Error al procesar la solicitud');
                }


            }
        };
        fetchData();
    }



    const buscarEjes = () => {
        const fetchData = async () => {
            try {
                const ejesFromServer = await srvBuscarTodosLosEjes(userData);
                // console.log(ejesFromServer)
                // console.log("authorization", userInfo.headers.getAuthorization)
                if (ejesFromServer) {
                    // console.log("holi")

                    setEjes(ejesFromServer.data.datos)
                }
                // navigate("/panel");
                else
                    setError("Error al buscar los usuarios")
            } catch (error) {
                console.log("error.message", "+" + error + "+")
                if (error == 'Error: Error del servidor: 401') {
                    console.log("ahora si 401")
                    dispatch(cerrarSesion());
                } else {
                    setError('Error al procesar la solicitud');
                }


            }
        };
        fetchData();
    }





    const handleInputChange = (e) => {
        const { name, value } = e.target;
        // console.log(value)
        setFormData({ ...formData, [name]: value });
    };







    const guardar = () => {
        // console.log(formData)
        let sw = 1
        if (formData.spot == "") {
            sw = 0
            setErrorSpot("Falta el nombre del spot")
        }
        if (formData.descripcion == "") {
            sw = 0
            setErrorDescripcion("Falta la descripción")
        }
        if (sw == 1) {
            setModalOpen(true)
        }
    }

    const enviarCambiosAlServidor = () => {
        const fetchData = async () => {
            try {
                console.log("Guardando formData", formData)
                let spotFromServer
                if (formData.idEncuestaHash != -1)
                    spotFromServer = await srvGuardarEncuestaExistente(userData, formData);
                else
                    spotFromServer = await srvGuardarNuevaEncuesta(userData, formData);
                // console.log(spotFromServer)
                // console.log("authorization", userInfo.headers.getAuthorization)
                if (spotFromServer) {
                    // console.log("imageUrl", imageUrl)
                    // console.log("usuariosFromServer.data", spotFromServer)


                    navigate("/principal/encuestas");

                }

                else
                    setError("Error al guardar cambios")
            } catch (error) {
                console.log("error.message", "+" + error + "+")
                if (error == 'Error: Error del servidor: 401') {
                    console.log("ahora si 401")
                    dispatch(cerrarSesion());
                } else {
                    setError('Error al procesar la solicitud');
                }
            }
        };
        fetchData();
    }


    const handleGuardar = () => {
        // Lógica para eliminar usuario

        enviarCambiosAlServidor()
        setModalOpen(false); // Cerrar el modal
    };

    const editarPreguntaPerfil = (preguntaPerfil) => {
        console.log(preguntaPerfil)
    };

    const handleDragEnd = (result) => {
        if (!result.destination) return;

        const sourceDroppableId = result.source.droppableId;
        const destinationDroppableId = result.destination.droppableId;

        if (sourceDroppableId === destinationDroppableId) {
            const items = Array.from(formData[sourceDroppableId]);
            const [reorderedItem] = items.splice(result.source.index, 1);
            items.splice(result.destination.index, 0, reorderedItem);
            setFormData({ ...formData, [sourceDroppableId]: items });
        }
    };

    useEffect(() => {
        // change the id later here based on some conditions
        // in my case when my data is loaded
        if (formData.preguntasPerfil.length) {
            setDroppablePreguntasPerfilId('preguntasPerfil');
        }
    }, [formData.preguntasPerfil.length]);

    useEffect(() => {
        // change the id later here based on some conditions
        // in my case when my data is loaded
        if (formData.preguntas.length) {
            setDroppablePreguntasId('preguntas');
        }
    }, [formData.preguntas.length]);



    const handleSelectChange = (event) => {

        setFormData(prevData => ({
            ...prevData,
            idEncuestaEje: event.target.value
        }))
    }


    const handleSelectPreguntaPerfilChange = (newQuestion) => {
        // console.log("handleSelectPreguntaPerfilChange", newQuestion)
        setFormData(prevData => ({
            ...prevData,
            preguntasPerfil: [...prevData.preguntasPerfil, newQuestion]
        }))
        setIsModalSeleccionPerfillOpen(false)
    }

    const abrirModalSeleccionPreguntaPerfil = () => {
        setIsModalSeleccionPerfillOpen(true)
    }

    const eliminarPreguntaPerfil = (preguntaPerfilSeleccionada) => {
        setFormData(prevData => ({
            ...prevData,
            preguntasPerfil: prevData.preguntasPerfil.filter(pregunta => pregunta.idPreguntaPerfil !== preguntaPerfilSeleccionada.idPreguntaPerfil)
        }))
    }

    const eliminarPregunta = (preguntaSeleccionada) => {
        setFormData(prevData => ({
            ...prevData,
            preguntas: prevData.preguntas.filter(pregunta => pregunta.idPregunta !== preguntaSeleccionada.idPregunta)
        }))
    }
    const onChangePregunta = (preguntaEditada) => {
        // console.log("preguntaEditada", preguntaEditada)

        setFormData(prevFormData => {
            const preguntaIndex = prevFormData.preguntas.findIndex(
                pregunta => pregunta.idPregunta === preguntaEditada.idPregunta
            );

            if (preguntaIndex !== -1) {
                // Reemplazar la pregunta existente
                const nuevasPreguntas = [...prevFormData.preguntas];
                nuevasPreguntas[preguntaIndex] = preguntaEditada;
                return { ...prevFormData, preguntas: nuevasPreguntas };
            } else {
                // Agregar la nueva pregunta
                return {
                    ...prevFormData,
                    preguntas: [...prevFormData.preguntas, preguntaEditada]
                };
            }
        });

        setIsModalPreguntaEdicionOpen(false)

    }

    const modificarPregunta = (pregunta) => {
        setPreguntaSeleccionada(pregunta)
        setIsReadOnly(false)
        setIsModalPreguntaEdicionOpen(true)
    }

    const verPregunta = (pregunta) => {
        console.log("pregunta", pregunta)
        setPreguntaSeleccionada(pregunta)
        setIsReadOnly(true)
        setIsModalPreguntaEdicionOpen(true)
    }

    const verPreguntaPerfil = (preguntaPerfil) => {

        let preguntaPerfilAVer = { ...preguntaPerfil, pregunta: preguntaPerfil.preguntaPerfil, idPregunta: preguntaPerfil.idPreguntaPerfil }
        console.log("pregunta", preguntaPerfilAVer)
        setPreguntaSeleccionada(preguntaPerfilAVer)
        setIsReadOnly(true)
        setIsModalPreguntaEdicionOpen(true)
        // setPreguntaSeleccionada(pregunta)
        // setIsReadOnly(true)
        // setIsModalPreguntaEdicionOpen(true)
    }
    console.log("formData", formData)

    return (
        <div className={stylesPanel.containerPrincipal}>
            <HeaderTituloComponent pagina={HeaderTituloComponent.PAGINA_ENCUESTAS_EDICION}></HeaderTituloComponent>
            <div className={stylesPanel.containerPanelSinBorde}>
                <div className={styles.divFormulario}>
                    <InputText
                        id="titulo"
                        name="titulo"
                        miMarginTop={20}
                        miwidth={'100%'}
                        value={formData.titulo}
                        required
                        type={"text"}
                        onChangeInput={handleInputChange}
                        placeholder={"Titulo..."}
                        mensajeError={errorSpot}
                    />
                    <InputText
                        id="descripcion"
                        name="descripcion"
                        value={formData.descripcion}
                        required
                        miwidth={'100%'}
                        type={"text"}
                        onChangeInput={handleInputChange}
                        placeholder={"Descripción..."}
                        mensajeError={errorDescripcion}
                    />


                    <SelectComponent placeholder={'Eje...'}
                        mensajeError={errorEje} options={ejes}
                        value={formData.idEncuestaEje}
                        onChange={handleSelectChange} >

                    </SelectComponent>



                    <div >
                        <DragDropContext onDragEnd={handleDragEnd}>
                            <Droppable droppableId={droppablePreguntasPerfilId} >

                                {(provided) => (
                                    <>
                                        <h3>1-Preguntas de perfil</h3>
                                        <p>Estas preguntas irán al comienzo de la encuesta y servirán para poder segmentar al usuario que está ingresando la encuesta</p>
                                        <div {...provided.droppableProps} ref={provided.innerRef} className={styles.contenedorSeccionPreguntas}>
                                            {formData.preguntasPerfil.map((pregunta, index) => (
                                                <Draggable key={pregunta.idPreguntaPerfil} draggableId={pregunta.idPreguntaPerfil.toString()} index={index}>
                                                    {(provided) => (
                                                        <div className={styles.contenedorPregunta} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="9" viewBox="0 0 24 9">
                                                                <path id="Icon_material-drag-handle" data-name="Icon material-drag-handle" d="M30,13.5H6v3H30Zm-24,9H30v-3H6Z" transform="translate(-6 -13.5)" fill="#269d85" />
                                                            </svg>
                                                            <div className={styles.descripcionPregunta}>
                                                                <h3>{pregunta.preguntaPerfil}</h3>
                                                                <div className={styles.descripcionPreguntaDetalle}>
                                                                    <label>Alternativas múltiples</label>
                                                                    <label>{pregunta.cantAlternativas} alternativas</label>
                                                                </div>
                                                            </div>
                                                            <div className={styles.miniBotonera}>
                                                                {/* <MiniBoton icono={MiniBoton.ICONO_VER} onClick={() => verPreguntaPerfil(pregunta)}></MiniBoton> */}
                                                                {/* <MiniBoton icono={MiniBoton.ICONO_BORRAR} onClick={() => eliminarPreguntaPerfil(pregunta)}></MiniBoton> */}
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                            <div className={styles.botoneraFinalSeccion}>
                                                <BotonPrincipal text="Agregar pregunta perfil" icono={BotonPrincipal.ICONO_PREGUNTA_PERFIL} onClick={abrirModalSeleccionPreguntaPerfil} />
                                            </div>
                                        </div>

                                    </>

                                )}
                            </Droppable>



                            <Droppable droppableId={droppablePreguntasId}>
                                {(provided) => (
                                    <>
                                        <h3>1-Preguntas de encuesta</h3>
                                        <p>Estas preguntas corresponden a la encuesta como tal</p>
                                        <div {...provided.droppableProps} ref={provided.innerRef} className={styles.contenedorSeccionPreguntas}>
                                            {formData.preguntas.map((pregunta, index) => (
                                                <Draggable key={pregunta.idPregunta} draggableId={pregunta.idPregunta.toString()} index={index}>
                                                    {(provided) => (
                                                        <div className={styles.contenedorPregunta} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="9" viewBox="0 0 24 9">
                                                                <path id="Icon_material-drag-handle" data-name="Icon material-drag-handle" d="M30,13.5H6v3H30Zm-24,9H30v-3H6Z" transform="translate(-6 -13.5)" fill="#269d85" />
                                                            </svg>
                                                            <div className={styles.descripcionPregunta}>
                                                                <h3>{pregunta.pregunta}</h3>
                                                                <div className={styles.descripcionPreguntaDetalle}>
                                                                    <label>{preguntaTipoList.find(x => x.value == pregunta.idPreguntaTipo).texto}</label>
                                                                    <label>{pregunta.cantAlternativas} alternativas</label>
                                                                </div>
                                                            </div>
                                                            <div className={styles.miniBotonera}>
                                                                <MiniBoton icono={MiniBoton.ICONO_VER} onClick={() => verPregunta(pregunta)}></MiniBoton>
                                                                <MiniBoton icono={MiniBoton.ICONO_EDITAR} onClick={() => modificarPregunta(pregunta)}></MiniBoton>
                                                                <MiniBoton icono={MiniBoton.ICONO_BORRAR} onClick={() => eliminarPregunta(pregunta)}></MiniBoton>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                            <div className={styles.botoneraFinalSeccion}>
                                                <BotonPrincipal text="Agregar pregunta encuesta" icono={BotonPrincipal.ICONO_PREGUNTA_PERFIL} onClick={() => modificarPregunta(null)} />
                                            </div>
                                        </div>

                                    </>

                                )}
                            </Droppable>
                        </DragDropContext>

                    </div>
                </div>
                <div className={styles.divBotonera}>
                    <BotonPrincipal text="Guardar" icono={BotonPrincipal.ICONO_GUARDAR} onClick={() => guardar()} />
                    <BotonSecundario text="Cancelar" onClick={() => navigate(-1)}></BotonSecundario>
                </div>
            </div>
            <ConfirmationModalComponent
                isOpen={isModalOpen}
                onClose={() => setModalOpen(false)}
                onConfirm={enviarCambiosAlServidor}
                title="Confirmar guardado">
                ¿Estás seguro de que deseas guardar esta encuesta?
            </ConfirmationModalComponent>



            <SeleccionPreguntaPerfilModal
                isOpen={isModalSeleccionPerfillOpen}
                onClose={() => setIsModalSeleccionPerfillOpen(false)}
                listPreguntasPerfilSeleccionadas={formData.preguntasPerfil.map(x => x.idPreguntaPerfil)}
                onSelected={handleSelectPreguntaPerfilChange}
            >

            </SeleccionPreguntaPerfilModal>

            <PreguntaEdicionModal
                isOpen={isModalPreguntaEdicionOpen}
                onClose={() => setIsModalPreguntaEdicionOpen(false)}
                preguntaOriginal={preguntaSeleccionada}
                isReadOnly={isReadOnly}
                onChange={onChangePregunta}
            />


        </div>
    )
}

export default EncuestasEdicionPage;