// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Sidebar.module.css */
.Sidebar_container__Knl8S {
  display: flex;
  flex-direction: row;
}

.Sidebar_header__9qY6f {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #333;
  color: white;
  padding: 1rem;
  z-index: 1000;
}

.Sidebar_menuButton__g91s7 {
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
}

.Sidebar_sidebar__go0QL {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  background: #444;
  color: white;
  padding: 1rem;
  transform: translateX(-100%);
  transition: transform 0.3s ease;
  z-index: 999;
}

.Sidebar_sidebar__go0QL.Sidebar_open__WSss0 {
  transform: translateX(0);
}

.Sidebar_main__vm83G {
  margin-left: 250px;
  padding: 2rem;
  transition: margin-left 0.3s ease;
}

@media (max-width: 768px) {
  .Sidebar_sidebar__go0QL.Sidebar_closed__hP2Fk {
    transform: translateX(-100%);
  }

  .Sidebar_main__vm83G {
    margin-left: 0;
    padding: 2rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/sidebar/Sidebar.module.css"],"names":[],"mappings":"AAAA,uBAAuB;AACvB;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,gBAAgB;EAChB,YAAY;EACZ,aAAa;EACb,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,YAAY;EACZ,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,YAAY;EACZ,YAAY;EACZ,gBAAgB;EAChB,YAAY;EACZ,aAAa;EACb,4BAA4B;EAC5B,+BAA+B;EAC/B,YAAY;AACd;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,iCAAiC;AACnC;;AAEA;EACE;IACE,4BAA4B;EAC9B;;EAEA;IACE,cAAc;IACd,aAAa;EACf;AACF","sourcesContent":["/* Sidebar.module.css */\n.container {\n  display: flex;\n  flex-direction: row;\n}\n\n.header {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  background: #333;\n  color: white;\n  padding: 1rem;\n  z-index: 1000;\n}\n\n.menuButton {\n  background: none;\n  border: none;\n  color: white;\n  font-size: 1.5rem;\n  cursor: pointer;\n}\n\n.sidebar {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 250px;\n  height: 100%;\n  background: #444;\n  color: white;\n  padding: 1rem;\n  transform: translateX(-100%);\n  transition: transform 0.3s ease;\n  z-index: 999;\n}\n\n.sidebar.open {\n  transform: translateX(0);\n}\n\n.main {\n  margin-left: 250px;\n  padding: 2rem;\n  transition: margin-left 0.3s ease;\n}\n\n@media (max-width: 768px) {\n  .sidebar.closed {\n    transform: translateX(-100%);\n  }\n\n  .main {\n    margin-left: 0;\n    padding: 2rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Sidebar_container__Knl8S`,
	"header": `Sidebar_header__9qY6f`,
	"menuButton": `Sidebar_menuButton__g91s7`,
	"sidebar": `Sidebar_sidebar__go0QL`,
	"open": `Sidebar_open__WSss0`,
	"main": `Sidebar_main__vm83G`,
	"closed": `Sidebar_closed__hP2Fk`
};
export default ___CSS_LOADER_EXPORT___;
