
import InputText from '../InputText/InputText';
import EtiquetaError from '../etiqueta-error/EtiquetaError';
import styles from './Incrementador.module.css'
import React, { useState, useEffect } from 'react';


function Incrementador({ onClick, minimo, maximo = 100, valor, error, onChangeInput }) {

    let miWidth = '20px'
    let miHeight = '20px'

    const [cantidadSeleccionada, setCantidadSeleccionada] = React.useState(valor)
    const [isModificadoDespuesDeError, setIsModificadoDespuesDeError] = React.useState(false)
    const [errorCantidad, setErrorCantidad] = React.useState('')


    React.useEffect(() => {
        onChangeInput(cantidadSeleccionada)
    }, [cantidadSeleccionada])



    const handleInputChange = (e) => {

        const { name, value } = e.target;
        if (value <= maximo && value >= minimo) {
            setCantidadSeleccionada(value)
            setErrorCantidad("")
        } else {
            setErrorCantidad(`La cantidad debe estar entre ${minimo} y ${maximo}`)
            setCantidadSeleccionada(valor)
        }

        // console.log(value)
        // setFormData({ ...formData, [name]: value });


    }

    const aumentarCantidad = () => {
        if (cantidadSeleccionada < maximo) {
            setCantidadSeleccionada(cantidadSeleccionada + 1)
            setIsModificadoDespuesDeError(true)
        }
    }

    const disminuirCantidad = () => {
        if (cantidadSeleccionada > minimo) {
            setCantidadSeleccionada(cantidadSeleccionada - 1)
            setIsModificadoDespuesDeError(true)
        }
    }

    return (
        <div className={styles.aContainerPadre} >

            <div className={styles.aContainer} >
                <a onClick={() => disminuirCantidad()}>
                    <div className={styles.botonPrincipalContainer}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="3" viewBox="0 0 24 3">
                            <path id="Icon_feather-minus" data-name="Icon feather-minus" d="M7.5,18h21" transform="translate(-6 -16.5)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                        </svg>
                    </div>
                </a>


                <div style={{ marginTop: 20 }}>
                    <InputText
                        id="cantidad"
                        name="cantidad"
                        miwidth={'80px'}
                        value={cantidadSeleccionada}
                        required
                        type={"numeric"}
                        onChangeInput={handleInputChange}
                        mensajeError={""}
                        placeholder={""}
                    ></InputText>
                </div>


                <a onClick={() => aumentarCantidad()}>
                    <div className={styles.botonPrincipalContainer}>

                        <svg xmlns="http://www.w3.org/2000/svg" width="23.991" height="23.991" viewBox="0 0 33.991 33.991">
                            <g id="Icon_feather-plus" data-name="Icon feather-plus" transform="translate(-6 -6)">
                                <path id="Path_11" data-name="Path 11" d="M18,7.5V38.491" transform="translate(4.995)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                                <path id="Path_12" data-name="Path 12" d="M7.5,18H38.491" transform="translate(0 4.995)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                            </g>
                        </svg>



                    </div>

                </a>
            </div>

            <EtiquetaError mensajeError={!isModificadoDespuesDeError ? errorCantidad : ""}></EtiquetaError>


        </div>
    )
}

Incrementador.ICONO_MENU = "MENU"
Incrementador.ICONO_NOTIFICATION = "ICONO_NOTIFICATION"


export default Incrementador;