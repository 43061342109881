
import stylesLogin from './../../../styles/LoginPage.module.css'

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import imgLogo from '../../../assets/images/logo.png'
import BotonPrincipal from '../../../components/boton-principal/BotonPrincipal.js';
import { useLocation } from 'react-router-dom';

function ValidacionExitosaPage() {

    // Dentro del componente de la ruta "/validar"
    const location = useLocation();

    const dispatch = useDispatch();


    const navigate = useNavigate();



    return (
        <div className='App-fondo'>
            <div className={`Card ${stylesLogin.CardLogin}`} >
                <div className={stylesLogin.filaTitulo}>
                    <h1 className='tituloVerde'>
                        Validación de correo
                    </h1>
                    <img className={stylesLogin.logo} src={imgLogo}></img>
                </div>

                <div className={stylesLogin.filaSubtitulo}>
                    <label className='subtituloNegro'>
                        ¡Cuenta creada exitosamente!
                    </label>
                </div>







                <div style={{ width: '100%', marginTop: '30px', marginBottom: '30px' }}>
                    <BotonPrincipal text={"Ir a Login"} onClick={() => { navigate('/login') }}></BotonPrincipal>
                </div>



            </div>
        </div >
    )
}

export default ValidacionExitosaPage;