

import styles from './CardUsuarioComponent.module.css'
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
import BANNER_UPPER from '../../../../assets/images/logo.png'
import imagenPerfil from '../../../../assets/icons/perfil.jpg'
import BotonPrincipal from '../../../../components/boton-principal/BotonPrincipal';
import { useDispatch, useSelector } from 'react-redux';

function CardUsuarioComponent({ titulo }) {
    const dispatch = useDispatch();
    const userData = useSelector(state => state.authInfo);

    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const [ruta, setRuta] = useState([
        { etiqueta: 'Users', path: '/ruta-a-a' },
        { etiqueta: 'Social Profile', path: '/ruta-a-b' }])


    return (
        <div className={styles.container}>
            <div className={styles.bannerContainer}>
                <div className={styles.profilePictureContainer}>
                    <img src={imagenPerfil} alt="Perfil" className={styles.profilePicture} />
                </div>
            </div>

            <label className={styles.nombreUsuario}>{userData.usuario.nombre} {userData.usuario.apellido}</label>
            <label className={styles.perfilUsuario}>{userData.usuario.tipoUsuario}</label>
            <div style={{ marginBottom: '20px', marginTop: '10px' }}><BotonPrincipal text="Ver Notificaciones"  ></BotonPrincipal></div>

        </div>
    )
}

const Separador = () => (
    <svg
        className={styles.separador}
        xmlns="http://www.w3.org/2000/svg"
        width="6"
        height="11"
        viewBox="0 0 12.621 22.243"
    >
        <path
            d="M13.5,27l9-9-9-9"
            transform="translate(-11.379 -6.879)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
        />
    </svg>
);


export default CardUsuarioComponent;