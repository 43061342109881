
export const ICONOS_BOTON = {
    RESULTADOS: 'ICONO_RESULTADOS',
    EDITAR: 'ICONO_EDITAR',
    ENCUESTAENABLED: 'ENCUESTAENABLED',
    ENCUESTADISABLED: 'ENCUESTADISABLED',
    EXPORTAR: 'EXPORTAR',
};


export const TEXTO_REQUISITO_PASSWORD_SEGURA = "La contraseña debe ser de largo 8 y debe incluir caracteres y números"
