import logo from './logo.svg';
import './App.css';
import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Form, redirect, Routes, useNavigate, useHistory, Navigate } from 'react-router-dom';
import LoginPage from './pages/auth/LoginPage';
import RegistrarPage from './pages/registro/1-registrar/RegistrarPage';
import ValidarPage from './pages/registro/2-validar/ValidarPage';
import ValidacionExitosaPage from './pages/registro/3-validacion-exitosa/ValidacionExitosaPage';
import SolicitarCorreoPage from './pages/recuperar-password/1-solicitar-correo/SolicitarCorreoPage';
import ValidarCorreoRecuperacion from './pages/recuperar-password/2-validar/ValidarCorreoRecuperacionPage';
import NuevoPasswordPage from './pages/recuperar-password/3-nuevo-password/NuevoPasswordPage';
import RecuperacionExitosaPage from './pages/recuperar-password/4-recuperacion-exitosa/RecuperacionExitosaPage';
import PrincipalPage from './pages/principal/PrincipalPage';
import EncuestasPage from './pages/principal/panel/encuestas/EncuestasPage';
import PerfilPage from './pages/principal/perfil/PerfilPage';

function App() {
  const userData = useSelector((state) => state.authInfo);
  console.log("userData", userData)


  return (

    <div className='App-fondo' >
      <Routes>
        <Route path="/" element={!userData.estaAutenticado ? <Navigate to="/login" /> : <Navigate to="/principal" />} />
        <Route path="/login" element={userData.estaAutenticado ? <Navigate to="/principal" /> : <LoginPage />} />
        <Route path="/registro/registro" element={userData.estaAutenticado ? <Navigate to="/principal" /> : <RegistrarPage />} />
        <Route path="/registro/validar" element={userData.estaAutenticado ? <Navigate to="/principal" /> : <ValidarPage />} />
        <Route path="/registro/validacion-exitosa" element={userData.estaAutenticado ? <Navigate to="/principal" /> : <ValidacionExitosaPage />} />

        <Route path="/recuperar-password/1-solicitar-correo" element={userData.estaAutenticado ? <Navigate to="/principal" /> : <SolicitarCorreoPage />} />
        <Route path="/recuperar-password/2-validar" element={userData.estaAutenticado ? <Navigate to="/principal" /> : <ValidarCorreoRecuperacion />} />
        <Route path="/recuperar-password/3-nuevo-password" element={userData.estaAutenticado ? <Navigate to="/principal" /> : <NuevoPasswordPage />} />
        <Route path="/recuperar-password/4-recuperacion-exitosa" element={userData.estaAutenticado ? <Navigate to="/principal" /> : <RecuperacionExitosaPage />} />

        {/* <Route path="/mi-perfil" element={!userData.estaAutenticado ? <Navigate to="/login" /> : <PerfilPage />} /> */}

        {/* <Route path="/principal" element={!userData.estaAutenticado ? <Navigate to="/login" /> : <PrincipalPage />} /> */}
        <Route path="/principal/*" element={!userData.estaAutenticado ? <Navigate to="/login" /> : <PrincipalPage />} />







        {/* <Route path="/login" element={userData.estaAutenticado ? <Navigate to="/panel" /> : <LoginPage />} />
          <Route path="/registrar" element={userData.estaAutenticado ? <Navigate to="/panel" /> : <RegistrarPage />} />
          <Route path="/registrar/crear-cuenta" element={userData.estaAutenticado ? <Navigate to="/panel" /> : <RegistrarPage />} />
 */}

      </Routes>
    </div>

  );
}

export default App;
