

import styles from './PerfilEdicionPage.module.css'
import stylesPanel from '../../../../styles/Panel.module.css'
import React, { useState, useRef, useEffect } from 'react';
import { BrowserRouter as Router, useLocation, useNavigate } from 'react-router-dom';
import HeaderTituloComponent from '../../../../components/header-titulo/HeaderTituloComponent.js';
import InputText from '../../../../components/InputText/InputText.js';
import BotonPrincipal from '../../../../components/boton-principal/BotonPrincipal.js';

import { useDispatch, useSelector } from 'react-redux';
import { cerrarSesion } from '../../../../redux/userActions.js';

import imagenPerfil from '../../../../assets/icons/perfil.jpg'
import BotonSecundario from '../../../../components/boton-secundario/BotonSecundario.js';
import SelectComponent from '../../../../components/select/SelectComponent.js';
import { srvGuardarUsuarioExistente } from '../../../../services/usuarios/srvGuardarUsuarioExistente.js';
import { srvSubirFotoPerfil } from '../../../../services/usuarios/srvSubirFotoPerfil.js';
import ConfirmationModalComponent from '../../../../components/confirmation-modal/ConfirmationModalComponent.js';
import { srvObtenerFotoPerfil } from '../../../../services/usuarios/srvObtenerFotoPerfil.js';
import { srvGuardarNuevoUsuario } from '../../../../services/usuarios/srvGuardarNuevoUsuario.js';
import { srvBuscarUsuario } from '../../../../services/usuarios/srvBuscarUsuario.js';
import { srvBuscarTodosLosSpots } from '../../../../services/spots/srvBuscarTodosLosSpots.js';
import Checkbox from '../../../../components/checkbox/Checkbox.js';

function PerfilEdicionPage(props) {
    const dispatch = useDispatch();
    // dispatch(cerrarSesion());
    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const [errorApellido, setErrorApellido] = useState('');
    const [errorNombre, setErrorNombre] = useState('');
    const [errorCorreo, setErrorCorreo] = useState('');
    const [errorPerfil, setErrorPerfil] = useState('');
    const [file, setFile] = useState(null);
    const [spots, setSpots] = useState([]);
    const [imageUrl, setImageUrl] = useState(imagenPerfil);
    const fileInputRef = useRef(null);


    const [imagenDesdeServidor, setImagenDesdeServidor] = useState(null);

    const [isModalOpen, setModalOpen] = useState(false);


    const userData = useSelector(state => state.authInfo);

    console.log("userData", userData)
    const usuario = userData.usuario


    const [formData, setFormData] = useState({
        idUsuarioHash: -1,
        nombre: 'Felop',
        apellido: 'adsd',
        correo: 'dasds',
        idUsuarioTipo: 1,
        imagenUrl: '',
        imagenBlob: null,
        spots: []
    })

    React.useEffect(() => {
        buscarTodosLosSpots()
    }, [])




    React.useEffect(() => {
        buscarUsuario(usuario.idUsuarioHash)
    }, [usuario])




    const buscarTodosLosSpots = () => {
        const fetchData = async () => {
            try {
                const spotsFromServer = await srvBuscarTodosLosSpots(userData);

                console.log("spotsFromServer", spotsFromServer)
                setSpots(spotsFromServer.data)
            } catch (error) {
                console.log("error.message", "+" + error + "+")
                if (error == 'Error: Error del servidor: 401') {
                    console.log("ahora si 401")
                    dispatch(cerrarSesion());
                } else {
                    setError('Error al procesar la solicitud');
                }
            }
        };
        fetchData();
    }



    const buscarUsuario = (idUsuarioHash) => {
        const fetchData = async () => {
            try {
                const usuarioFromServer = await srvBuscarUsuario(userData, idUsuarioHash);
                console.log(usuarioFromServer)
                // console.log("authorization", userInfo.headers.getAuthorization)
                if (usuarioFromServer) {
                    console.log("holi")

                    setFormData(prevFormData => ({
                        ...prevFormData,
                        idUsuarioHash: usuarioFromServer.data.idUsuarioHash,
                        nombre: usuarioFromServer.data.nombre,
                        apellido: usuarioFromServer.data.apellido,
                        correo: usuarioFromServer.data.correo,
                        idUsuarioTipo: usuarioFromServer.data.idUsuarioTipo,
                        imagenUrl: usuarioFromServer.data.imagenUrl || imagenPerfil,
                        spots: usuarioFromServer.data.spots
                    }));
                }
                // navigate("/panel");
                else
                    setError("Error al buscar los usuarios")
            } catch (error) {
                console.log("error.message", "+" + error + "+")
                if (error == 'Error: Error del servidor: 401') {
                    console.log("ahora si 401")
                    dispatch(cerrarSesion());
                } else {
                    setError('Error al procesar la solicitud');
                }


            }
        };
        fetchData();
    }

    useEffect(() => {
        if (formData.imagenUrl && formData.imagenUrl != '') {

            console.log('formData.imagenUrl nuevo:', formData.imagenUrl);
            cargarImagenFromServer();
        }
    }, [formData.imagenUrl]);


    const cargarImagenFromServer = async () => {
        const defaultImage = '../../../../../assets/icons/perfil.jpg'; // Ruta a la imagen por defecto en tu carpeta assets
        console.log("Cargando imagen:", formData.imagenUrl);
        if (formData.imagenUrl && formData.imagenUrl !== defaultImage && formData.imagenUrl !== "") {
            try {
                console.log("hola 1")
                const imageObjectURL = await srvObtenerFotoPerfil(userData, formData.imagenUrl);
                setFormData(prevFormData => ({ ...prevFormData, imagenBlob: imageObjectURL }));
            } catch (error) {
                console.log("hola 2")
                setFormData(prevFormData => ({ ...prevFormData, imagenBlob: defaultImage }));
            }
        } else {
            console.log("hola 3")
            setFormData(prevFormData => ({ ...prevFormData, imagenBlob: defaultImage }));
        }
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        // console.log(value)
        setFormData({ ...formData, [name]: value });
    };



    const handleSelectChange = (event) => {
        // setSelectedValue(event.target.value);
        // console.log("event.target.value", event.target.value)

        setFormData({ ...formData, idUsuarioTipo: event.target.value });
    };


    const onFileChange = event => {
        setFile(event.target.files[0]);
        if (event.target.files[0]) {
            // Crear una URL para la imagen seleccionada y actualizar la imagen que se muestra
            // setImageUrl(URL.createObjectURL(event.target.files[0]));
            setFormData({ ...formData, imagenBlob: URL.createObjectURL(event.target.files[0]) })
        }
    };



    const guardar = () => {
        console.log(formData)
        let sw = 1
        if (formData.nombre == "") {
            sw = 0
            setErrorNombre("Falta el nombre")
        }
        if (formData.apellido == "") {
            sw = 0
            setErrorApellido("Falta el apellido")
        }
        if (formData.correo == "") {
            sw = 0
            setErrorCorreo("Falta el correo")
        }
        if (formData.idUsuarioTipo == "") {
            sw = 0
            setErrorPerfil("Falta el perfil de usuario")
        }

        if (sw == 1) {
            setModalOpen(true)
        }
    }

    const enviarCambiosAlServidor = () => {
        const fetchData = async () => {
            try {
                console.log("Guardando formData", formData)
                let usuariosFromServer
                if (formData.idUsuarioHash != -1)
                    usuariosFromServer = await srvGuardarUsuarioExistente(userData, formData);
                else
                    usuariosFromServer = await srvGuardarNuevoUsuario(userData, formData);
                console.log(usuariosFromServer)
                // console.log("authorization", userInfo.headers.getAuthorization)
                if (usuariosFromServer) {
                    console.log("imageUrl", imageUrl)
                    console.log("usuariosFromServer.data", usuariosFromServer)

                    await srvSubirFotoPerfil(
                        userData,
                        file,
                        usuariosFromServer.data.datos.idUsuarioHash,
                    );
                    navigate("/principal/usuarios");

                }
                // navigate("/panel");

                else
                    setError("Error al guardar cambios")
            } catch (error) {
                console.log("error.message", "+" + error + "+")
                if (error == 'Error: Error del servidor: 401') {
                    console.log("ahora si 401")
                    dispatch(cerrarSesion());
                } else {
                    setError('Error al procesar la solicitud');
                }
            }
        };
        fetchData();
    }

    const triggerFileInput = () => {
        fileInputRef.current.click();
    };

    const handleGuardar = () => {
        // Lógica para eliminar usuario

        enviarCambiosAlServidor()
        setModalOpen(false); // Cerrar el modal
    };

    const handleSpotSeleccion = (idSpotHash, checked) => {
        setFormData((prevFormData) => {
            const updatedSpots = checked
                ? [...prevFormData.spots, idSpotHash]
                : prevFormData.spots.filter((spot) => spot !== idSpotHash);

            return {
                ...prevFormData,
                spots: updatedSpots
            };
        });
    };

    console.log("formData", formData)

    return (
        <div className={stylesPanel.containerPrincipal}>
            <HeaderTituloComponent pagina={HeaderTituloComponent.PAGINA_PERFIL_EDICION}></HeaderTituloComponent>

            <div className={stylesPanel.containerPanelSinBorde}>
                <div className={styles.containerUsuario} style={{ marginBottom: '20px' }}>
                    <img src={formData.imagenBlob ? formData.imagenBlob : imagenPerfil} onClick={triggerFileInput} className={styles.circleImage} width={400} height={400} />
                    <BotonSecundario text={"Cambiar imagen"} onClick={triggerFileInput} ></BotonSecundario>
                    <input
                        type="file"
                        onChange={onFileChange}
                        style={{ display: 'none' }}
                        ref={fileInputRef}
                    />


                    <div className={styles.divFormulario}>


                        <InputText
                            id="nombre"
                            name="nombre"
                            miMarginTop={20}
                            value={formData.nombre}
                            required
                            type={"text"}
                            onChangeInput={handleInputChange}
                            placeholder={"Nombre..."}
                            mensajeError={errorNombre}></InputText>
                        <InputText
                            id="apellido"
                            name="apellido"
                            value={formData.apellido}
                            required
                            type={"text"}
                            onChangeInput={handleInputChange}
                            placeholder={"Apellido..."}
                            mensajeError={errorApellido}></InputText>






                        {formData.idUsuarioTipo != 1 && (
                            <div className={stylesPanel.contenedorFormulario}>
                                {spots.map((spot, index) => (
                                    <Checkbox isChecked={formData.spots.includes(spot.idSpotHash)} label={spot.spot} onChange={(checked) => handleSpotSeleccion(spot.idSpotHash, checked)}></Checkbox>
                                ))}
                            </div>

                        )}




                    </div>



                    <div className={styles.divBotonera}>
                        <BotonPrincipal text="Guardar" icono={BotonPrincipal.ICONO_GUARDAR}
                            onClick={() => guardar()}
                        />

                        <BotonSecundario text="Cancelar" onClick={() => navigate(-1)}></BotonSecundario>
                    </div>

                </div>

            </div>

            <ConfirmationModalComponent
                isOpen={isModalOpen}
                onClose={() => setModalOpen(false)}
                onConfirm={handleGuardar}
                title="Confirmar guardado">
                ¿Estás seguro de que deseas guardar este usuario?
            </ConfirmationModalComponent>

        </div >
    )
}

export default PerfilEdicionPage;