
import styles from './BotonPrincipal.module.css'
import React, { useState, useEffect } from 'react';

function BotonPrincipal(props) {

    const iconos = [{
        id: BotonPrincipal.ICONO_CREAR,
        icono: <svg xmlns="http://www.w3.org/2000/svg" style={{ marginRight: '10px' }} width="25" viewBox="0 0 40.5 35.993">
            <path id="Icon_awesome-edit" data-name="Icon awesome-edit" d="M28.308,5.85l6.342,6.342a.687.687,0,0,1,0,.97L19.294,28.519l-6.525.724a1.368,1.368,0,0,1-1.512-1.512l.724-6.525L27.337,5.85A.687.687,0,0,1,28.308,5.85ZM39.7,4.24,36.267.809a2.75,2.75,0,0,0-3.881,0L29.9,3.3a.687.687,0,0,0,0,.97l6.342,6.342a.687.687,0,0,0,.97,0L39.7,8.121a2.75,2.75,0,0,0,0-3.881ZM27,24.342V31.5H4.5V9H20.658a.865.865,0,0,0,.6-.246l2.813-2.812a.844.844,0,0,0-.6-1.441H3.375A3.376,3.376,0,0,0,0,7.875v24.75A3.376,3.376,0,0,0,3.375,36h24.75A3.376,3.376,0,0,0,31.5,32.625V21.53a.845.845,0,0,0-1.441-.6l-2.812,2.813A.865.865,0,0,0,27,24.342Z" transform="translate(0 -0.007)" fill="#fff" />
        </svg>
    }, {
        id: BotonPrincipal.ICONO_COMPRAR,
        icono: <svg xmlns="http://www.w3.org/2000/svg" style={{ marginRight: '10px' }} width="25" viewBox="0 0 43.307 36">
            <path id="Icon_awesome-store" data-name="Icon awesome-store" d="M42.328,8.339,37.765,1.055A2.251,2.251,0,0,0,35.859,0H7.453A2.251,2.251,0,0,0,5.548,1.055L.984,8.339C-1.371,12.1.717,17.332,5.119,17.93a7.307,7.307,0,0,0,.963.063,6.943,6.943,0,0,0,5.189-2.327,6.948,6.948,0,0,0,10.378,0,6.948,6.948,0,0,0,10.378,0,6.966,6.966,0,0,0,5.189,2.327,7.228,7.228,0,0,0,.963-.063C42.6,17.339,44.691,12.108,42.328,8.339ZM37.23,20.25a8.921,8.921,0,0,1-2.074-.267V27h-27V19.983a9.315,9.315,0,0,1-2.074.267,9.487,9.487,0,0,1-1.266-.084,8.842,8.842,0,0,1-1.153-.253V33.75A2.248,2.248,0,0,0,5.913,36h31.5a2.248,2.248,0,0,0,2.25-2.25V19.913a7.179,7.179,0,0,1-1.153.253A9.778,9.778,0,0,1,37.23,20.25Z" transform="translate(-0.004)" fill="#fff" />
        </svg>


    }, {
        id: BotonPrincipal.ICONO_SIMULAR,
        icono: <svg xmlns="http://www.w3.org/2000/svg" style={{ marginRight: '10px' }} width="20" viewBox="0 0 30.355 37.6">
            <path id="Icon_feather-play" data-name="Icon feather-play" d="M7.5,4.5,32.855,20.8,7.5,37.1Z" transform="translate(-5 -2)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="5" />
        </svg>


    }, {
        id: BotonPrincipal.ICONO_ANALIZAR,
        icono: <svg xmlns="http://www.w3.org/2000/svg" style={{ marginRight: '10px' }} width="25" viewBox="0 0 46.496 34.872">
            <path id="Icon_awesome-chart-bar" data-name="Icon awesome-chart-bar" d="M30.222,27.748H33.71a1.25,1.25,0,0,0,1.162-1.162V14.38a1.25,1.25,0,0,0-1.162-1.162H30.222A1.25,1.25,0,0,0,29.06,14.38V26.586a1.25,1.25,0,0,0,1.162,1.162Zm8.718,0h3.487a1.25,1.25,0,0,0,1.162-1.162V5.662A1.25,1.25,0,0,0,42.428,4.5H38.94a1.25,1.25,0,0,0-1.162,1.162V26.586a1.25,1.25,0,0,0,1.162,1.162Zm-26.154,0h3.487a1.25,1.25,0,0,0,1.162-1.162V20.192a1.25,1.25,0,0,0-1.162-1.162H12.786a1.25,1.25,0,0,0-1.162,1.162v6.393a1.25,1.25,0,0,0,1.162,1.162Zm8.718,0h3.487a1.25,1.25,0,0,0,1.162-1.162V8.568a1.25,1.25,0,0,0-1.162-1.162H21.5a1.25,1.25,0,0,0-1.162,1.162V26.586A1.25,1.25,0,0,0,21.5,27.748ZM45.043,33.56H5.812V5.953A1.453,1.453,0,0,0,4.359,4.5H1.453A1.453,1.453,0,0,0,0,5.953V36.466a2.906,2.906,0,0,0,2.906,2.906H45.043A1.453,1.453,0,0,0,46.5,37.919V35.013A1.453,1.453,0,0,0,45.043,33.56Z" transform="translate(0 -4.5)" fill="#fff" />
        </svg>
    }, {
        id: BotonPrincipal.ICONO_MODIFICAR,
        icono: <svg xmlns="http://www.w3.org/2000/svg" style={{ marginRight: '10px' }} width="25" viewBox="0 0 40.5 35.993">
            <path id="Icon_awesome-edit" data-name="Icon awesome-edit" d="M28.308,5.85l6.342,6.342a.687.687,0,0,1,0,.97L19.294,28.519l-6.525.724a1.368,1.368,0,0,1-1.512-1.512l.724-6.525L27.337,5.85A.687.687,0,0,1,28.308,5.85ZM39.7,4.24,36.267.809a2.75,2.75,0,0,0-3.881,0L29.9,3.3a.687.687,0,0,0,0,.97l6.342,6.342a.687.687,0,0,0,.97,0L39.7,8.121a2.75,2.75,0,0,0,0-3.881ZM27,24.342V31.5H4.5V9H20.658a.865.865,0,0,0,.6-.246l2.813-2.812a.844.844,0,0,0-.6-1.441H3.375A3.376,3.376,0,0,0,0,7.875v24.75A3.376,3.376,0,0,0,3.375,36h24.75A3.376,3.376,0,0,0,31.5,32.625V21.53a.845.845,0,0,0-1.441-.6l-2.812,2.813A.865.865,0,0,0,27,24.342Z" transform="translate(0 -0.007)" fill="#fff" />
        </svg>

    }, {
        id: BotonPrincipal.ICONO_ACTIVAR,
        icono: <svg xmlns="http://www.w3.org/2000/svg" style={{ marginRight: '10px' }} width="20" viewBox="0 0 39.375 45">
            <path id="Icon_awesome-tablet-alt" data-name="Icon awesome-tablet-alt" d="M35.156,0H4.219A4.22,4.22,0,0,0,0,4.219V40.781A4.22,4.22,0,0,0,4.219,45H35.156a4.22,4.22,0,0,0,4.219-4.219V4.219A4.22,4.22,0,0,0,35.156,0ZM19.688,42.188A2.813,2.813,0,1,1,22.5,39.375,2.809,2.809,0,0,1,19.688,42.188ZM35.156,32.7A1.058,1.058,0,0,1,34.1,33.75H5.273A1.058,1.058,0,0,1,4.219,32.7V5.273A1.058,1.058,0,0,1,5.273,4.219H34.1a1.058,1.058,0,0,1,1.055,1.055Z" fill="#fff" />
        </svg>

    }, {
        id: BotonPrincipal.ICONO_ARCHIVAR,
        icono: <svg xmlns="http://www.w3.org/2000/svg" style={{ marginRight: '10px' }} width="25" viewBox="0 0 37 31">
            <g id="Icon_feather-archive" data-name="Icon feather-archive" transform="translate(0.5 -2.5)">
                <path id="Path_5" data-name="Path 5" d="M31.5,12V31.5H4.5V12" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" />
                <path id="Path_6" data-name="Path 6" d="M1.5,4.5h33V12H1.5Z" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" />
                <path id="Path_7" data-name="Path 7" d="M15,18h6" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" />
            </g>
        </svg>

    }, {
        id: BotonPrincipal.ICONO_ELIMINAR,
        icono: <svg xmlns="http://www.w3.org/2000/svg" style={{ marginRight: '10px' }} width="20" viewBox="0 0 31.5 36">
            <path id="Icon_awesome-trash-alt" data-name="Icon awesome-trash-alt" d="M2.25,32.625A3.375,3.375,0,0,0,5.625,36h20.25a3.375,3.375,0,0,0,3.375-3.375V9h-27Zm19.125-18a1.125,1.125,0,0,1,2.25,0v15.75a1.125,1.125,0,0,1-2.25,0Zm-6.75,0a1.125,1.125,0,0,1,2.25,0v15.75a1.125,1.125,0,0,1-2.25,0Zm-6.75,0a1.125,1.125,0,0,1,2.25,0v15.75a1.125,1.125,0,0,1-2.25,0ZM30.375,2.25H21.938L21.277.935A1.688,1.688,0,0,0,19.765,0H11.728a1.668,1.668,0,0,0-1.5.935L9.563,2.25H1.125A1.125,1.125,0,0,0,0,3.375v2.25A1.125,1.125,0,0,0,1.125,6.75h29.25A1.125,1.125,0,0,0,31.5,5.625V3.375A1.125,1.125,0,0,0,30.375,2.25Z" fill="#fff" />
        </svg>

    }, {
        id: BotonPrincipal.ICONO_GUARDAR,
        icono: <svg xmlns="http://www.w3.org/2000/svg" style={{ marginRight: '10px' }} width="20" viewBox="0 0 31.5 31.5">
            <path id="Icon_awesome-save" data-name="Icon awesome-save" d="M30.511,9.136l-5.9-5.9a3.375,3.375,0,0,0-2.386-.989H3.375A3.375,3.375,0,0,0,0,5.625v24.75A3.375,3.375,0,0,0,3.375,33.75h24.75A3.375,3.375,0,0,0,31.5,30.375V11.523a3.375,3.375,0,0,0-.989-2.386ZM15.75,29.25a4.5,4.5,0,1,1,4.5-4.5A4.5,4.5,0,0,1,15.75,29.25ZM22.5,7.838v7.068a.844.844,0,0,1-.844.844H5.344a.844.844,0,0,1-.844-.844V7.594a.844.844,0,0,1,.844-.844H21.412a.843.843,0,0,1,.6.247l.245.245a.843.843,0,0,1,.247.6Z" transform="translate(0 -2.25)" fill="#fff" />
        </svg>



    }, {
        id: BotonPrincipal.ICONO_AGREGAR_TOTEMS,
        icono: <svg id="Component_16_1" data-name="Component 16 – 1" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: '10px' }} width="30" viewBox="0 0 66.999 76.809">
            <g id="Component_9_5" data-name="Component 9 – 5">
                <path id="Icon_awesome-tablet-alt" data-name="Icon awesome-tablet-alt" d="M54.074,0H6.489A6.5,6.5,0,0,0,0,6.516V62.984A6.5,6.5,0,0,0,6.489,69.5H54.074a6.5,6.5,0,0,0,6.489-6.516V6.516A6.5,6.5,0,0,0,54.074,0ZM30.281,65.156a4.344,4.344,0,1,1,4.326-4.344A4.33,4.33,0,0,1,30.281,65.156ZM54.074,50.5a1.63,1.63,0,0,1-1.622,1.629H8.111A1.63,1.63,0,0,1,6.489,50.5V8.145A1.63,1.63,0,0,1,8.111,6.516h44.34a1.63,1.63,0,0,1,1.622,1.629Z" fill="#fff" />
                <path id="Icon_material-location-on" data-name="Icon material-location-on" d="M21.013,3A13.5,13.5,0,0,0,7.5,16.513c0,10.135,13.513,25.1,13.513,25.1s13.513-14.961,13.513-25.1A13.5,13.5,0,0,0,21.013,3Zm0,18.339a4.826,4.826,0,1,1,4.826-4.826A4.828,4.828,0,0,1,21.013,21.339Z" transform="translate(9.268 6.5)" fill="#fff" />
            </g>
            <path id="Icon_awesome-plus-circle" data-name="Icon awesome-plus-circle" d="M16.246.563A15.683,15.683,0,1,0,31.929,16.246,15.68,15.68,0,0,0,16.246.563Zm9.106,17.454a.761.761,0,0,1-.759.759H18.775v5.818a.761.761,0,0,1-.759.759H14.475a.761.761,0,0,1-.759-.759V18.775H7.9a.761.761,0,0,1-.759-.759V14.475a.761.761,0,0,1,.759-.759h5.818V7.9a.761.761,0,0,1,.759-.759h3.541a.761.761,0,0,1,.759.759v5.818h5.818a.761.761,0,0,1,.759.759Z" transform="translate(34.57 44.38)" fill="#fff" stroke="#1e9b31" stroke-width="1" />
        </svg>

    }, {
        id: BotonPrincipal.ICONO_DISPOSITIVO,
        icono: <svg xmlns="http://www.w3.org/2000/svg" width="28" style={{ marginRight: '10px' }} viewBox="0 0 48.125 55">
            <path id="Icon_awesome-tablet-alt" data-name="Icon awesome-tablet-alt" d="M42.969,0H5.156A5.158,5.158,0,0,0,0,5.156V49.844A5.158,5.158,0,0,0,5.156,55H42.969a5.158,5.158,0,0,0,5.156-5.156V5.156A5.158,5.158,0,0,0,42.969,0ZM24.063,51.563A3.438,3.438,0,1,1,27.5,48.125,3.434,3.434,0,0,1,24.063,51.563Zm18.906-11.6A1.293,1.293,0,0,1,41.68,41.25H6.445a1.293,1.293,0,0,1-1.289-1.289V6.445A1.293,1.293,0,0,1,6.445,5.156H41.68a1.293,1.293,0,0,1,1.289,1.289Z" fill="#fff" />
        </svg>


    }, {
        id: BotonPrincipal.ICONO_TIENDA,
        icono: <svg xmlns="http://www.w3.org/2000/svg" width="27" style={{ marginRight: '10px' }} viewBox="0 0 74.793 62.173">
            <path id="Icon_awesome-store" data-name="Icon awesome-store" d="M73.1,14.4,65.218,1.821A3.887,3.887,0,0,0,61.927,0H12.869A3.887,3.887,0,0,0,9.578,1.821L1.7,14.4c-4.068,6.5-.461,15.531,7.14,16.563a12.62,12.62,0,0,0,1.664.109,11.99,11.99,0,0,0,8.962-4.019,12,12,0,0,0,17.923,0,12,12,0,0,0,17.923,0,12.03,12.03,0,0,0,8.962,4.019,12.483,12.483,0,0,0,1.664-.109C73.56,29.945,77.179,20.911,73.1,14.4ZM64.3,34.972a15.407,15.407,0,0,1-3.582-.461V46.63H14.083V34.511a16.087,16.087,0,0,1-3.582.461,16.385,16.385,0,0,1-2.186-.146,15.271,15.271,0,0,1-1.991-.437v23.9a3.882,3.882,0,0,0,3.886,3.886h54.4A3.882,3.882,0,0,0,68.5,58.287v-23.9a12.4,12.4,0,0,1-1.991.437A16.887,16.887,0,0,1,64.3,34.972Z" transform="translate(-0.004)" fill="#fff" />
        </svg>
    }
        , {
        id: BotonPrincipal.ICONO_PREGUNTA_PERFIL,
        icono: <svg xmlns="http://www.w3.org/2000/svg" width="27" style={{ marginRight: '10px' }} viewBox="0 0 60.136 45.102">
            <path id="Icon_metro-profile" data-name="Icon metro-profile" d="M60.339,14.155H56.134V7.845l-8.681-.133.045,6.443H21.412l.178-6.443-8.5.133v6.443l-4.3-.133a4.3,4.3,0,0,0-4.3,4.3V48.519a4.3,4.3,0,0,0,4.3,4.3H60.339a4.3,4.3,0,0,0,4.3-4.3V18.451A4.3,4.3,0,0,0,60.339,14.155ZM49.6,9.86h4.3v8.591H49.6ZM21.68,24.49c2.594,0,4.7,2.764,4.7,6.175s-2.1,6.175-4.7,6.175-4.7-2.764-4.7-6.175,2.1-6.175,4.7-6.175ZM15.237,9.86h4.3v8.591h-4.3ZM12.374,44.112s.509-4.085,1.667-4.856,4.489-1.284,4.489-1.284,2.161,2.309,3.08,2.309,3.078-2.309,3.078-2.309,3.333.511,4.491,1.284c1.36.906,1.7,4.856,1.7,4.856h-18.5Zm43.669-2.036H36.714V39.928H56.044Zm0-4.3H36.714V35.632H56.044Zm0-4.3H36.714V31.337H56.044Zm0-4.3H36.714V27.042H56.044Z" transform="translate(-4.499 -7.712)" fill="#fff" />
        </svg>
    }
        , {
        id: BotonPrincipal.ICONO_VOLVER,
        icono: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" style={{ marginRight: '10px' }} viewBox="0 0 24 24">
            <path id="Icon_material-arrow_back" data-name="Icon material-arrow_back" d="M30,16.5H11.745L20.13,8.115,18,6,6,18,18,30l2.115-2.115L11.745,19.5H30Z" transform="translate(-6 -6)" fill="#fff" />
        </svg>
    }
        , {
        id: BotonPrincipal.ICONO_PLAY,
        icono: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" style={{ marginRight: '10px' }} viewBox="0 0 34.875 34.875">
            <path id="Icon_awesome-play-circle" data-name="Icon awesome-play-circle" d="M18,.563A17.438,17.438,0,1,0,35.438,18,17.434,17.434,0,0,0,18,.563Zm8.135,19.125-12.375,7.1a1.691,1.691,0,0,1-2.51-1.477V10.688a1.692,1.692,0,0,1,2.51-1.477l12.375,7.523A1.693,1.693,0,0,1,26.135,19.688Z" transform="translate(-0.563 -0.563)" fill="#fff" />
        </svg>


    }
        , {
        id: BotonPrincipal.ICONO_LLAVE,
        icono: <svg xmlns="http://www.w3.org/2000/svg" width="25.242" style={{ marginRight: '10px' }} viewBox="0 0 94.242 92.099">
            <path id="Icon_feather-key" data-name="Icon feather-key" d="M83.031,3l-8.424,8.424M42.551,43.48a23.167,23.167,0,1,1-32.765,0,23.166,23.166,0,0,1,32.765,0Zm0,0L59.864,26.167m0,0L72.5,38.8,87.243,24.061,74.606,11.424M59.864,26.167,74.606,11.424" transform="translate(1.999 4.071)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="10" />
        </svg>


    }, {
        id: BotonPrincipal.ICONO_ENCUESTA,
        icono: <svg xmlns="http://www.w3.org/2000/svg" height="26px" style={{ marginRight: '10px' }} viewBox="0 0 29 38">
            <path id="Icon_awesome-clipboard-check" data-name="Icon awesome-clipboard-check" d="M23.625,4.5H18a4.5,4.5,0,0,0-9,0H3.375A3.376,3.376,0,0,0,0,7.875v24.75A3.376,3.376,0,0,0,3.375,36h20.25A3.376,3.376,0,0,0,27,32.625V7.875A3.376,3.376,0,0,0,23.625,4.5ZM13.5,2.813A1.688,1.688,0,1,1,11.813,4.5,1.683,1.683,0,0,1,13.5,2.813Zm8.522,16.3-10.055,9.97a.845.845,0,0,1-1.2-.007L4.964,23.217a.845.845,0,0,1,.007-1.2l2-1.983a.845.845,0,0,1,1.2.007L11.4,23.309l7.453-7.4a.845.845,0,0,1,1.2.007l1.983,2A.845.845,0,0,1,22.022,19.111Z" transform="translate(1 1)" fill="none" stroke="#fff" stroke-width="3" />
        </svg>


    }




    ]

    return (
        <a onClick={props.onClick} className={styles.botonPrincipalA} style={{ width: props.width, marginRight: props.marginRight, marginTop: props.marginTop }} >
            <div className={styles.botonPrincipalContainer} style={props.swLabelDebeEstarCentrado ? { justifyContent: 'center' } : {}}>
                {props.icono && iconos.find(x => x.id == props.icono).icono}
                <label>{props.text}</label>
            </div>

        </a>
    )
}

BotonPrincipal.ICONO_CREAR = "ICONO_CREAR"
BotonPrincipal.ICONO_COMPRAR = "ICONO_COMPRAR"

BotonPrincipal.ICONO_SIMULAR = "ICONO_SIMULAR"
BotonPrincipal.ICONO_ANALIZAR = "ICONO_ANALIZAR"
BotonPrincipal.ICONO_MODIFICAR = "ICONO_MODIFICAR"
BotonPrincipal.ICONO_ACTIVAR = "ICONO_ACTIVAR"
BotonPrincipal.ICONO_ARCHIVAR = "ICONO_ARCHIVAR"
BotonPrincipal.ICONO_ELIMINAR = "ICONO_ELIMINAR"
BotonPrincipal.ICONO_GUARDAR = "ICONO_GUARDAR"
BotonPrincipal.ICONO_DISPOSITIVO = "ICONO_DISPOSITIVO"
BotonPrincipal.ICONO_TIENDA = "ICONO_TIENDA"
BotonPrincipal.ICONO_PLAY = "ICONO_PLAY"
BotonPrincipal.ICONO_LLAVE = "ICONO_LLAVE"

BotonPrincipal.ICONO_AGREGAR_TOTEMS = "ICONO_AGREGAR_TOTEMS"
BotonPrincipal.ICONO_PREGUNTA_PERFIL = "ICONO_PREGUNTA_PERFIL"

BotonPrincipal.ICONO_VOLVER = "ICONO_VOLVER"

BotonPrincipal.ICONO_ENCUESTA = "ICONO_ENCUESTA"

export default BotonPrincipal;