// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InformesPage_containerMenu__UUOIo {
  box-sizing: border-box;
  display: grid;
  gap: 20px;
  padding: 2rem;
  transition: margin-left 0.3s ease;
  background-color: white;

  border-radius: 15px;
  width: calc(100% - 30px);
  margin-top: 10px;
}

/* Mobile view: one column */
@media (max-width: 600px) {
  .InformesPage_containerMenu__UUOIo {
    grid-template-columns: 1fr;
  }
}

/* Tablet view: two columns */
@media (min-width: 600px) and (max-width: 1200px) {
  .InformesPage_containerMenu__UUOIo {
    grid-template-columns: 1fr 1fr;
  }
}

/* Desktop view: three columns */
@media (min-width: 1200px) {
  .InformesPage_containerMenu__UUOIo {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/principal/panel/informes/InformesPage.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,aAAa;EACb,SAAS;EACT,aAAa;EACb,iCAAiC;EACjC,uBAAuB;;EAEvB,mBAAmB;EACnB,wBAAwB;EACxB,gBAAgB;AAClB;;AAEA,4BAA4B;AAC5B;EACE;IACE,0BAA0B;EAC5B;AACF;;AAEA,6BAA6B;AAC7B;EACE;IACE,8BAA8B;EAChC;AACF;;AAEA,gCAAgC;AAChC;EACE;IACE,kCAAkC;EACpC;AACF","sourcesContent":[".containerMenu {\n  box-sizing: border-box;\n  display: grid;\n  gap: 20px;\n  padding: 2rem;\n  transition: margin-left 0.3s ease;\n  background-color: white;\n\n  border-radius: 15px;\n  width: calc(100% - 30px);\n  margin-top: 10px;\n}\n\n/* Mobile view: one column */\n@media (max-width: 600px) {\n  .containerMenu {\n    grid-template-columns: 1fr;\n  }\n}\n\n/* Tablet view: two columns */\n@media (min-width: 600px) and (max-width: 1200px) {\n  .containerMenu {\n    grid-template-columns: 1fr 1fr;\n  }\n}\n\n/* Desktop view: three columns */\n@media (min-width: 1200px) {\n  .containerMenu {\n    grid-template-columns: 1fr 1fr 1fr;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerMenu": `InformesPage_containerMenu__UUOIo`
};
export default ___CSS_LOADER_EXPORT___;
