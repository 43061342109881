// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a {
  cursor: pointer;
}

.MiniBoton_aContainer__N299n {
  display: flex;
  align-items: center;
  height: 38px;
  width: 38px;
  border-radius: 8px;
  border: 1px;
}

.MiniBoton_botonPrincipalContainer__I5SIj {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: var(--primary-color-seleccionado);
  border-radius: 8px;
  border: 1px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.MiniBoton_botonPrincipalContainer__I5SIj:hover {
  background-color: var(--primary-color);
}

.MiniBoton_botonPrincipalContainer__I5SIj:active {
  background-color: #357262;
}

.MiniBoton_botonPrincipalContainer__I5SIj:disabled {
  background-color: gray;
}

.MiniBoton_icono__AljwQ {
  fill: var(--primary-color);
}

.MiniBoton_botonPrincipalContainer__I5SIj:hover .MiniBoton_icono__AljwQ {
  fill: #ffffff;
}
`, "",{"version":3,"sources":["webpack://./src/components/mini-boton/MiniBoton.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,WAAW;EACX,mDAAmD;EACnD,kBAAkB;EAClB,WAAW;EACX,wCAAwC;AAC1C;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,aAAa;AACf","sourcesContent":["a {\n  cursor: pointer;\n}\n\n.aContainer {\n  display: flex;\n  align-items: center;\n  height: 38px;\n  width: 38px;\n  border-radius: 8px;\n  border: 1px;\n}\n\n.botonPrincipalContainer {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n  width: 100%;\n  background-color: var(--primary-color-seleccionado);\n  border-radius: 8px;\n  border: 1px;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\n}\n\n.botonPrincipalContainer:hover {\n  background-color: var(--primary-color);\n}\n\n.botonPrincipalContainer:active {\n  background-color: #357262;\n}\n\n.botonPrincipalContainer:disabled {\n  background-color: gray;\n}\n\n.icono {\n  fill: var(--primary-color);\n}\n\n.botonPrincipalContainer:hover .icono {\n  fill: #ffffff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"aContainer": `MiniBoton_aContainer__N299n`,
	"botonPrincipalContainer": `MiniBoton_botonPrincipalContainer__I5SIj`,
	"icono": `MiniBoton_icono__AljwQ`
};
export default ___CSS_LOADER_EXPORT___;
