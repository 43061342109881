

import styles from './ClientePage.module.css'
import stylesPanel from '../../../../styles/Panel.module.css'
import React, { useState, useRef, useEffect } from 'react';
import { BrowserRouter as Router, useLocation, useNavigate } from 'react-router-dom';
import HeaderTituloComponent from '../../../../components/header-titulo/HeaderTituloComponent.js';
import InputText from '../../../../components/InputText/InputText.js';
import BotonPrincipal from '../../../../components/boton-principal/BotonPrincipal.js';

import { useDispatch, useSelector } from 'react-redux';
import { cerrarSesion } from '../../../../redux/userActions.js';

import imagenPerfil from '../../../../assets/icons/perfil.jpg'
import BotonSecundario from '../../../../components/boton-secundario/BotonSecundario.js';
import SelectComponent from '../../../../components/select/SelectComponent.js';
import { srvGuardarUsuarioExistente } from '../../../../services/usuarios/srvGuardarUsuarioExistente.js';
import { srvSubirFotoPerfil } from '../../../../services/usuarios/srvSubirFotoPerfil.js';
import ConfirmationModalComponent from '../../../../components/confirmation-modal/ConfirmationModalComponent.js';
import { srvObtenerFotoPerfil } from '../../../../services/usuarios/srvObtenerFotoPerfil.js';
import { srvGuardarNuevoUsuario } from '../../../../services/usuarios/srvGuardarNuevoUsuario.js';
import { srvBuscarUsuario } from '../../../../services/usuarios/srvBuscarUsuario.js';
import { srvBuscarTodosLosSpots } from '../../../../services/spots/srvBuscarTodosLosSpots.js';
import Checkbox from '../../../../components/checkbox/Checkbox.js';
import { srvBuscaCliente } from '../../../../services/cliente/srvBuscaCliente.js';
import { srvModificarCliente } from '../../../../services/cliente/srvModificarCliente.js';

function ClientePage(props) {
    const dispatch = useDispatch();
    // dispatch(cerrarSesion());
    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const [errorApellido, setErrorApellido] = useState('');
    const [errorNombre, setErrorNombre] = useState('');
    const [errorCliente, setErrorCliente] = useState('');
    const [errorRut, setErrorRut] = useState('');
    const [file, setFile] = useState(null);
    const [spots, setSpots] = useState([]);
    const [imageUrl, setImageUrl] = useState(imagenPerfil);
    const fileInputRef = useRef(null);


    const [imagenDesdeServidor, setImagenDesdeServidor] = useState(null);

    const [isModalOpen, setModalOpen] = useState(false);


    const userData = useSelector(state => state.authInfo);

    console.log("userData", userData)
    const usuario = userData.usuario


    const [formData, setFormData] = useState({
        idClienteHash: '',
        cliente: '',
        rut: ''
    })




    React.useEffect(() => {
        buscarUsuario()
    }, [userData])



    const buscarUsuario = () => {
        const fetchData = async () => {
            try {
                console.log(userData)
                const usuarioFromServer = await srvBuscaCliente(userData, userData.usuario.idClienteHash);
                console.log(usuarioFromServer)
                // console.log("authorization", userInfo.headers.getAuthorization)
                if (usuarioFromServer) {
                    console.log("holi")

                    setFormData(prevFormData => ({
                        ...prevFormData,
                        cliente: usuarioFromServer.data.datos.cliente,
                        rut: usuarioFromServer.data.datos.rut,
                        idClienteHash: usuarioFromServer.data.datos.idClienteHash
                    }));
                }
                // navigate("/panel");
                else
                    setError("Error al buscar los usuarios")
            } catch (error) {
                console.log("error.message", "+" + error + "+")
                if (error == 'Error: Error del servidor: 401') {
                    console.log("ahora si 401")
                    dispatch(cerrarSesion());
                } else {
                    setError('Error al procesar la solicitud');
                }


            }
        };
        fetchData();
    }



    const handleInputChange = (e) => {
        const { name, value } = e.target;
        // console.log(value)
        setFormData({ ...formData, [name]: value });
    };





    const onFileChange = event => {
        setFile(event.target.files[0]);
        if (event.target.files[0]) {
            // Crear una URL para la imagen seleccionada y actualizar la imagen que se muestra
            // setImageUrl(URL.createObjectURL(event.target.files[0]));
            setFormData({ ...formData, imagenBlob: URL.createObjectURL(event.target.files[0]) })
        }
    };



    const guardar = () => {
        setErrorCliente("")
        setErrorRut("")
        console.log(formData)
        console.log(verificarRUT(formData.rut))
        var sw = 1
        if (formData.rut == "") {
            sw = 0
            setErrorRut("Falta el rut")
        }
        if (sw == 1 && !verificarRUT(formData.rut)) {
            sw = 0
            setErrorRut("Rut incorrecto")
        }
        if (sw == 1 && formData.cliente == "") {
            sw = 0
            setErrorCliente("Falta el nombre de la institución")
        }

        if (sw == 1) {
            setModalOpen(true)
        }
    }

    const enviarCambiosAlServidor = () => {
        const fetchData = async () => {
            try {
                console.log("Guardando formData", formData)
                let respuesta
                respuesta = await srvModificarCliente(userData, userData.usuario.idClienteHash, formData.rut, formData.cliente);
                console.log(respuesta)
                // console.log("authorization", userInfo.headers.getAuthorization)
                if (respuesta) {
                    console.log("imageUrl", imageUrl)
                    console.log("usuariosFromServer.data", respuesta)

                    await srvSubirFotoPerfil(
                        userData,
                        file,
                        respuesta.data.datos.idUsuarioHash,
                    );
                    navigate("/principal/usuarios");

                }
                // navigate("/panel");

                else
                    setError("Error al guardar cambios")
            } catch (error) {
                console.log("error.message", "+" + error + "+")
                if (error == 'Error: Error del servidor: 401') {
                    console.log("ahora si 401")
                    dispatch(cerrarSesion());
                } else {
                    setError('Error al procesar la solicitud');
                }
            }
        };
        fetchData();
    }

    const triggerFileInput = () => {
        fileInputRef.current.click();
    };

    const handleGuardar = () => {
        // Lógica para eliminar usuario

        enviarCambiosAlServidor()
        setModalOpen(false); // Cerrar el modal
    };

    const handleSpotSeleccion = (idSpotHash, checked) => {
        setFormData((prevFormData) => {
            const updatedSpots = checked
                ? [...prevFormData.spots, idSpotHash]
                : prevFormData.spots.filter((spot) => spot !== idSpotHash);

            return {
                ...prevFormData,
                spots: updatedSpots
            };
        });
    };

    function verificarRUT(rut) {
        // Eliminar puntos y guión
        rut = rut.replace(/\./g, '').replace(/-/g, '');

        // Separar número del dígito verificador
        let rutNum = rut.slice(0, -1);
        let dv = rut.slice(-1).toUpperCase();

        // Calcular el dígito verificador esperado
        let suma = 0;
        let multiplicador = 2;

        for (let i = rutNum.length - 1; i >= 0; i--) {
            suma += parseInt(rutNum.charAt(i)) * multiplicador;
            multiplicador = multiplicador < 7 ? multiplicador + 1 : 2;
        }

        let dvEsperado = 11 - (suma % 11);
        if (dvEsperado === 11) {
            dvEsperado = '0';
        } else if (dvEsperado === 10) {
            dvEsperado = 'K';
        } else {
            dvEsperado = dvEsperado.toString();
        }

        const esValido = dv === dvEsperado;

        if (esValido) {
            // Formatear el RUT
            let rutFormateado = rutNum.replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '-' + dv;
            setFormData({ ...formData, rut: rutFormateado });
        }

        // Verificar si el dígito verificador es correcto
        return dv === dvEsperado;
    }

    // Ejemplo de uso
    // console.log(verificarRUT('12.345.678-5'));  // Cambia esto por el RUT que deseas verificar

    // Ejemplo de uso 


    console.log("formData", formData)

    return (
        <div className={stylesPanel.containerPrincipal}>
            <HeaderTituloComponent pagina={HeaderTituloComponent.PAGINA_CLIENTE}></HeaderTituloComponent>

            <div className={stylesPanel.containerPanelSinBorde}>


                <div className={styles.containerUsuario} style={{ marginBottom: '20px' }}>



                    <div className={styles.divFormulario}>


                        <InputText
                            id="cliente"
                            name="cliente"
                            miMarginTop={20}
                            value={formData.cliente}
                            required
                            type={"text"}
                            onChangeInput={handleInputChange}
                            placeholder={"Cliente..."}
                            mensajeError={errorCliente}></InputText>
                        <InputText
                            id="rut"
                            name="rut"
                            value={formData.rut}
                            required
                            type={"text"}
                            onChangeInput={handleInputChange}
                            placeholder={"Rut..."}
                            mensajeError={errorRut}></InputText>






                    </div>



                    <div className={styles.divBotonera}>
                        <BotonPrincipal text="Guardar" icono={BotonPrincipal.ICONO_GUARDAR}
                            onClick={() => guardar()}
                        />

                        <BotonSecundario text="Cancelar" onClick={() => navigate('/principal')}></BotonSecundario>
                    </div>

                </div>

            </div>

            <ConfirmationModalComponent
                isOpen={isModalOpen}
                onClose={() => setModalOpen(false)}
                onConfirm={handleGuardar}
                title="Confirmar guardado">
                ¿Estás seguro de que deseas guardar el cliente?
            </ConfirmationModalComponent>

        </div >
    )
}

export default ClientePage;