// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PaginaIntroduccion_fondoComponente__QSBBC {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: white;
  width: 100%;
  height: 100%;
}

.PaginaIntroduccion_logo__d8sYI {
  height: 30px;
  width: auto;
  justify-self: flex-start;
  justify-items: flex-start;
  justify-content: flex-start;
  margin-top: 10px;
  margin-left: 10px;
  align-self: flex-start;
}

.PaginaIntroduccion_midiv__XMKrl {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: white;
  width: 100%;
  height: 100%;
}

.PaginaIntroduccion_bienvenido__XKFYb {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
}
.PaginaIntroduccion_mensaje__jLStq {
  font-size: 1rem;
  margin-bottom: 20px;
}
.PaginaIntroduccion_icono__PZ50L {
  height: 60px;
  width: auto;
}
`, "",{"version":3,"sources":["webpack://./src/pages/principal/panel/encuestas/simulador/pagina-introduccion/PaginaIntroduccion.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,uBAAuB;EACvB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,wBAAwB;EACxB,yBAAyB;EACzB,2BAA2B;EAC3B,gBAAgB;EAChB,iBAAiB;EACjB,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,uBAAuB;EACvB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,mBAAmB;AACrB;AACA;EACE,YAAY;EACZ,WAAW;AACb","sourcesContent":[".fondoComponente {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  border-radius: 5px;\n  background-color: white;\n  width: 100%;\n  height: 100%;\n}\n\n.logo {\n  height: 30px;\n  width: auto;\n  justify-self: flex-start;\n  justify-items: flex-start;\n  justify-content: flex-start;\n  margin-top: 10px;\n  margin-left: 10px;\n  align-self: flex-start;\n}\n\n.midiv {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  border-radius: 5px;\n  background-color: white;\n  width: 100%;\n  height: 100%;\n}\n\n.bienvenido {\n  font-size: 2rem;\n  font-weight: bold;\n  margin-bottom: 20px;\n}\n.mensaje {\n  font-size: 1rem;\n  margin-bottom: 20px;\n}\n.icono {\n  height: 60px;\n  width: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fondoComponente": `PaginaIntroduccion_fondoComponente__QSBBC`,
	"logo": `PaginaIntroduccion_logo__d8sYI`,
	"midiv": `PaginaIntroduccion_midiv__XMKrl`,
	"bienvenido": `PaginaIntroduccion_bienvenido__XKFYb`,
	"mensaje": `PaginaIntroduccion_mensaje__jLStq`,
	"icono": `PaginaIntroduccion_icono__PZ50L`
};
export default ___CSS_LOADER_EXPORT___;
