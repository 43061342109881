

import styles from './BotonCardComponent.module.css'
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

function BotonCardComponent({ opcion, onClick }) {
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const [ruta, setRuta] = useState([
        { etiqueta: 'Users', path: '/ruta-a-a' },
        { etiqueta: 'Social Profile', path: '/ruta-a-b' }])
    const opciones = [
        {
            id: BotonCardComponent.MENU_ENCUESTAS, titulo: "Encuestas", svg:
                <svg xmlns="http://www.w3.org/2000/svg" height="100%" viewBox="0 0 29 38">
                    <path id="Icon_awesome-clipboard-check" data-name="Icon awesome-clipboard-check" d="M23.625,4.5H18a4.5,4.5,0,0,0-9,0H3.375A3.376,3.376,0,0,0,0,7.875v24.75A3.376,3.376,0,0,0,3.375,36h20.25A3.376,3.376,0,0,0,27,32.625V7.875A3.376,3.376,0,0,0,23.625,4.5ZM13.5,2.813A1.688,1.688,0,1,1,11.813,4.5,1.683,1.683,0,0,1,13.5,2.813Zm8.522,16.3-10.055,9.97a.845.845,0,0,1-1.2-.007L4.964,23.217a.845.845,0,0,1,.007-1.2l2-1.983a.845.845,0,0,1,1.2.007L11.4,23.309l7.453-7.4a.845.845,0,0,1,1.2.007l1.983,2A.845.845,0,0,1,22.022,19.111Z" transform="translate(1 1)" fill="none" stroke="#000" stroke-width="2" />
                </svg>
            , ruta: 'encuestas/'
        },
        {
            id: BotonCardComponent.MENU_SPOTS, titulo: "Spots", svg:
                <svg xmlns="http://www.w3.org/2000/svg" height="100%" viewBox="0 0 40.087 64.139">
                    <path id="Icon_metro-location" data-name="Icon metro-location" d="M28.4,1.928A20.043,20.043,0,0,0,8.355,21.971c0,20.043,20.043,44.1,20.043,44.1s20.043-24.052,20.043-44.1A20.043,20.043,0,0,0,28.4,1.928Zm0,32.32A12.277,12.277,0,1,1,40.675,21.971,12.277,12.277,0,0,1,28.4,34.248ZM20.631,21.971A7.767,7.767,0,1,1,28.4,29.738,7.767,7.767,0,0,1,20.631,21.971Z" transform="translate(-8.355 -1.928)" />
                </svg>

            , ruta: 'spots/'
        },
        {
            id: BotonCardComponent.MENU_USUARIOS, titulo: "Usuarios", svg:
                <svg xmlns="http://www.w3.org/2000/svg" height="100%" viewBox="0 0 36 30.13">
                    <g id="Icon_feather-users" data-name="Icon feather-users" transform="translate(0 -2.87)">
                        <path id="Path_17" data-name="Path 17" d="M25.5,31.5v-3a6,6,0,0,0-6-6H7.5a6,6,0,0,0-6,6v3" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                        <path id="Path_18" data-name="Path 18" d="M19.5,10.5a6,6,0,1,1-6-6,6,6,0,0,1,6,6Z" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                        <path id="Path_19" data-name="Path 19" d="M34.5,31.5v-3A6,6,0,0,0,30,22.7" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                        <path id="Path_20" data-name="Path 20" d="M24,4.7A6,6,0,0,1,24,16.32" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                    </g>
                </svg>
            , ruta: 'usuarios/'
        },
        {
            id: BotonCardComponent.MENU_LICENCIAS, titulo: "Licencias", svg:
                <svg xmlns="http://www.w3.org/2000/svg" height="100%" viewBox="0 0 32.999 32.12">
                    <path id="Icon_feather-key" data-name="Icon feather-key" d="M31.5,3l-3,3M17.085,17.415a8.25,8.25,0,1,1-11.668,0,8.25,8.25,0,0,1,11.668,0Zm0,0L23.25,11.25m0,0,4.5,4.5L33,10.5,28.5,6m-5.25,5.25L28.5,6" transform="translate(-1.501 -0.879)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                </svg>

            , ruta: 'licencias/'
        },
        {
            id: BotonCardComponent.MENU_CLIENTE, titulo: "Cliente", svg:
                <svg xmlns="http://www.w3.org/2000/svg" height="100%" viewBox="0 0 50.016 41.68">
                    <path id="Icon_map-museum" data-name="Icon map-museum" d="M9.337,18.188h4.08l.627,18.756H8.794l.543-18.756ZM5.93,37.986h39.6V40.07h3.126V43.2h2.084V45.28H.72V43.2H2.8V40.07H5.93Zm41.68-25.2L25.73,3.6,3.846,12.782V14.02H47.61V12.782Zm-39.6,2.28H43.442v2.084H8.014Zm29.943,3.126h4.078l.632,18.756H37.41l.547-18.756Zm-9.536,0H32.5l.625,18.756H27.871Zm-9.538,0h4.079l.621,18.756H18.332l.55-18.756Z" transform="translate(-0.72 -3.6)" />
                </svg>


            , ruta: 'cliente/'
        },
        {
            id: BotonCardComponent.MENU_INFORMES, titulo: "Informes", svg:
                <svg xmlns="http://www.w3.org/2000/svg" height="90%" viewBox="0 0 36 27">
                    <path id="Icon_awesome-chart-bar" data-name="Icon awesome-chart-bar" d="M23.4,22.5h2.7a.968.968,0,0,0,.9-.9V12.15a.968.968,0,0,0-.9-.9H23.4a.968.968,0,0,0-.9.9V21.6a.968.968,0,0,0,.9.9Zm6.75,0h2.7a.968.968,0,0,0,.9-.9V5.4a.968.968,0,0,0-.9-.9h-2.7a.968.968,0,0,0-.9.9V21.6a.968.968,0,0,0,.9.9ZM9.9,22.5h2.7a.968.968,0,0,0,.9-.9V16.65a.968.968,0,0,0-.9-.9H9.9a.968.968,0,0,0-.9.9V21.6a.968.968,0,0,0,.9.9Zm6.75,0h2.7a.968.968,0,0,0,.9-.9V7.65a.968.968,0,0,0-.9-.9h-2.7a.968.968,0,0,0-.9.9V21.6A.968.968,0,0,0,16.65,22.5ZM34.875,27H4.5V5.625A1.125,1.125,0,0,0,3.375,4.5H1.125A1.125,1.125,0,0,0,0,5.625V29.25A2.25,2.25,0,0,0,2.25,31.5H34.875A1.125,1.125,0,0,0,36,30.375v-2.25A1.125,1.125,0,0,0,34.875,27Z" transform="translate(0 -4.5)" fill="#000" />
                </svg>


            , ruta: 'informes/'
        },
        {
            id: BotonCardComponent.MENU_INFORMES_PERFIL, titulo: "Informes de perfil", svg:
                <svg xmlns="http://www.w3.org/2000/svg" height="90%" viewBox="0 0 354.672 215.122">
                    <path id="Icon_awesome-chart-bar" data-name="Icon awesome-chart-bar" d="M130.507,104.89h15.058a5.4,5.4,0,0,0,5.019-5.019v-52.7a5.4,5.4,0,0,0-5.019-5.019H130.507a5.4,5.4,0,0,0-5.019,5.019v52.7a5.4,5.4,0,0,0,5.019,5.019Zm37.646,0h15.058a5.4,5.4,0,0,0,5.019-5.019V9.519A5.4,5.4,0,0,0,183.211,4.5H168.153a5.4,5.4,0,0,0-5.019,5.019V99.87a5.4,5.4,0,0,0,5.019,5.019Zm-112.938,0H70.273a5.4,5.4,0,0,0,5.019-5.019V72.263a5.4,5.4,0,0,0-5.019-5.019H55.214a5.4,5.4,0,0,0-5.019,5.019V99.87a5.4,5.4,0,0,0,5.019,5.019Zm37.646,0h15.058a5.4,5.4,0,0,0,5.019-5.019v-77.8a5.4,5.4,0,0,0-5.019-5.019H92.86a5.4,5.4,0,0,0-5.019,5.019v77.8A5.4,5.4,0,0,0,92.86,104.89Zm101.645,25.1H25.1V10.774A6.273,6.273,0,0,0,18.823,4.5H6.274A6.273,6.273,0,0,0,0,10.774V142.536a12.55,12.55,0,0,0,12.549,12.549H194.5a6.273,6.273,0,0,0,6.274-6.274V136.261A6.273,6.273,0,0,0,194.5,129.987Z" transform="translate(0 -4.5)" />
                    <path id="Icon_open-people" data-name="Icon open-people" d="M108.767,0C98.682,0,89.98,5.64,84.641,14.18A47.294,47.294,0,0,1,98.879,48.5,46.83,46.83,0,0,1,97.1,61.556a27.065,27.065,0,0,0,11.668,3.062c16.414,0,29.664-14.5,29.664-32.228S125.181.161,108.767.161ZM49.44,16.114c-16.414,0-29.664,14.5-29.664,32.228S33.026,80.571,49.44,80.571,79.1,66.068,79.1,48.342,65.854,16.114,49.44,16.114Zm93.935,50.921a49.292,49.292,0,0,1-33.421,13.536c5.339,6.123,8.7,13.536,8.7,21.593V112.8h39.552V86.049c0-8.379-6.131-15.631-14.832-19.176ZM14.832,83.149C6.131,86.694,0,93.945,0,102.325v26.749H98.879V102.325c0-8.379-6.131-15.631-14.832-19.176A49.324,49.324,0,0,1,49.44,96.685,50.041,50.041,0,0,1,14.832,83.149Z" transform="translate(196.465 86.047)" />
                </svg>


            , ruta: 'informes/'
        },
    ]

    return (
        <a className={styles.container} onClick={() => { onClick(opciones.find(x => x.id == opcion).ruta) }}>


            {/* <div className={styles.bannerContainer} style={{ background: `url('${image}')` }}>
 
             </div> */}
            <div className={styles.divSuperior}>
                {/* <img src={opciones.find(x => x.id == opcion).imagen}></img> */}
                {opciones.find(x => x.id == opcion).svg}
            </div>
            <div className={styles.divInferior}>
                <label >{opciones.find(x => x.id == opcion).titulo}</label>
            </div>

        </a>

    )
}


BotonCardComponent.MENU_ENCUESTAS = "MENU_ENCUESTAS"
BotonCardComponent.MENU_SPOTS = "MENU_SPOTS"
BotonCardComponent.MENU_USUARIOS = "MENU_USUARIOS"
BotonCardComponent.MENU_LICENCIAS = "MENU_LICENCIAS"
BotonCardComponent.MENU_INFORMES = "MENU_INFORMES"

BotonCardComponent.MENU_INFORMES_PERFIL = "MENU_INFORMES_PERFIL"
BotonCardComponent.MENU_INFORMES_ENCUESTAS = "MENU_INFORMES_ENCUESTAS"

BotonCardComponent.MENU_CLIENTE = "MENU_CLIENTE"



export default BotonCardComponent;