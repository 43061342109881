import React, { useState, useEffect } from 'react';
import styles from './Sidebar.module.css'; // Asegúrate de crear este archivo

const Sidebar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsDesktop(window.innerWidth > 768);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (isDesktop) {
            setIsOpen(true);
        }
    }, [isDesktop]);

    return (
        <div className={styles.container}>
            <header className={styles.header}>
                <button className={styles.menuButton} onClick={toggleSidebar}>
                    ☰
                </button>
            </header>
            <aside className={`${styles.sidebar} ${isOpen ? styles.open : styles.closed}`}>
                <nav>
                    <ul>
                        <li>Inicio</li>
                        <li>Acerca de</li>
                        <li>Servicios</li>
                        <li>Contacto</li>
                    </ul>
                </nav>
            </aside>
            <main className={styles.main}>
                {/* Contenido principal */}
            </main>
        </div>
    );
};

export default Sidebar;