

import styles from './SpotsPage.module.css'
import stylesPanel from '../../../../styles/Panel.module.css'
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
import HeaderTituloComponent from '../../../../components/header-titulo/HeaderTituloComponent.js';
import InputText from '../../../../components/InputText/InputText.js';
import BotonPrincipal from '../../../../components/boton-principal/BotonPrincipal.js';
import { srvBuscarTodasLasEncuestas } from '../../../../services/encuestas/srvBuscarTodasLasEncuestas.js';
import { useDispatch, useSelector } from 'react-redux';
import { cerrarSesion } from '../../../../redux/userActions.js';

import imagenSpotNoFoto from '../../../../assets/images/spot-no-image.jpg'
import { srvBuscarTodosLosUsuarios } from '../../../../services/usuarios/srvBuscarTodosLosUsuarios.js';
import { srvObtenerFotoPerfil } from '../../../../services/usuarios/srvObtenerFotoPerfil.js';
import axios from 'axios';
import { srvBuscarTodosLosSpots } from '../../../../services/spots/srvBuscarTodosLosSpots.js';
import ProgressBar from '../../../../components/progress-bar/ProgressBar.js';
import { srvBuscarCantidadTotemsSinActivar } from '../../../../services/totems/srvBuscarCantidadTotemsSinActivar.js';
import CuadroAyuda from '../../../../components/cuadro-ayuda/CuadroAyuda.js';
import SimuladorPage from '../encuestas/simulador/SimuladorModal.js';
import ConfirmationModalComponent from '../../../../components/confirmation-modal/ConfirmationModalComponent.js';
import { srvDesactivarEncuesta } from '../../../../services/encuestas/srvDesactivarEncuesta.js';


function SpotsPage(props) {
    const dispatch = useDispatch();
    // dispatch(cerrarSesion());
    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const [errorBuscar, setErrorBuscar] = useState('');
    const [textoBuscado, setTextoBuscado] = useState('');
    const [spotsSinFiltrar, setSpotsSinFiltrar] = useState([]);
    const [spotsFiltrados, setSpotsFiltrados] = useState([]);
    const [isModalSimuladorOpen, setIsModalSimuladorOpen] = useState(false);
    const [cantidadTotemsSinActivar, setCantidadTotemsSinActivar] = useState([])

    const [spotSelected, setSpotSelected] = useState(null);


    const [isOpenModal, setIsOpenModal] = useState(false);

    const ACCION_VACIA = { accion: "", titulo: "", mensaje: "" }
    const ACCION_DESACTIVAR = { accion: "ACCION_DESACTIVAR", titulo: "Desactivar encuesta", mensaje: "¿seguro que deseas desactivar la encuesta?. Esto se aplicará a todos los totems" }

    const [accionAConfirmar, setAccionAConfirmar] = useState(ACCION_VACIA);

    const userData = useSelector(state => state.authInfo);

    React.useEffect(() => {
        getSpotsFromServer()
        buscarCantidadTotemsSinActivar()
    }, [])


    useEffect(() => {
        console.log("Nuevo texto a buscar ", textoBuscado)
        console.log("usuariosSinFiltrar ", spotsSinFiltrar)
        setSpotsFiltrados(spotsSinFiltrar.filter(usuario => {
            const textoBuscadoEnMinusculas = textoBuscado.toLowerCase();
            return usuario.spot.toLowerCase().includes(textoBuscadoEnMinusculas) || usuario.descripcion.toLowerCase().includes(textoBuscadoEnMinusculas) || usuario.creadoPor.toLowerCase().includes(textoBuscadoEnMinusculas)
        }
        ))

    }, [textoBuscado, spotsSinFiltrar]);



    const getSpotsFromServer = () => {
        // console.log("asds")
        const fetchData = async () => {
            try {
                const spotsFromServer = await srvBuscarTodosLosSpots(userData);
                console.log(spotsFromServer)
                // console.log("authorization", userInfo.headers.getAuthorization)
                if (spotsFromServer) {
                    console.log(spotsFromServer.data)

                    setSpotsSinFiltrar(spotsFromServer.data);
                    // Cargar las imágenes después de actualizar el estado de los usuarios
                    cargarImagenes(spotsFromServer.data);
                    // props.handleLogged(userInfo.data)
                }
                // navigate("/panel");
                else
                    setError("Error al buscar los usuarios")
            } catch (error) {
                console.log("error.message", "+" + error + "+")
                if (error == 'Error: Error del servidor: 401') {
                    console.log("ahora si 401")
                    dispatch(cerrarSesion());
                } else {
                    setError('Error al procesar la solicitud');
                }


            }
        };
        fetchData();
    }



    const defaultImage = '../../../../assets/icons/perfil.jpg'; // Ruta a la imagen por defecto en tu carpeta assets

    const cargarImagenes = async (usuarios) => {
        const usuariosConImagenes = await Promise.all(usuarios.map(async usuario => {
            if (usuario.imagenUrl && usuario.imagenUrl !== defaultImage && usuario.imagenUrl !== "") {
                try {
                    const imageObjectURL = await srvObtenerFotoPerfil(userData, usuario.imagenUrl)
                    return { ...usuario, imagenBlob: imageObjectURL };
                } catch (error) {
                    console.error(`Error al cargar la imagen para el usuario ${usuario.id}:`, error);
                    return { ...usuario, imagenBlob: usuario };
                }
            } else {
                return { ...usuario, imagenBlob: imagenSpotNoFoto };
            }
        }));

        setSpotsSinFiltrar(usuariosConImagenes);
    };

    const handleInputChange = (e) => {

        const { name, value } = e.target;
        console.log("Texto buscado", value)
        setTextoBuscado(value)

    };
    const crearSpot = () => {
        navigate("edicion", { state: { usuario: null } })
    }


    const buscarCantidadTotemsSinActivar = () => {
        // console.log("asds")
        const fetchData = async () => {
            try {
                const spotsFromServer = await srvBuscarCantidadTotemsSinActivar(userData);
                console.log(spotsFromServer)
                // console.log("authorization", userInfo.headers.getAuthorization)
                if (spotsFromServer) {
                    console.log(spotsFromServer.data.datos.cantidadTotemsSinActivar)

                    setCantidadTotemsSinActivar(spotsFromServer.data.datos.cantidadTotemsSinActivar)
                    // Cargar las imágenes después de actualizar el estado de los usuarios

                    // props.handleLogged(userInfo.data)
                }
                // navigate("/panel");
                else
                    setError("Error al buscar los usuarios")
            } catch (error) {
                console.log("error.message", "+" + error + "+")
                if (error == 'Error: Error del servidor: 401') {
                    console.log("ahora si 401")
                    dispatch(cerrarSesion());
                } else {
                    setError('Error al procesar la solicitud');
                }


            }
        };
        fetchData();
    }


    const simular = (idEncuestaHash) => {
        console.log("idEncuestaHash", idEncuestaHash)


        setSpotSelected({ idEncuestaHash: idEncuestaHash })
        setIsModalSimuladorOpen(true)

    }

    const desactivarEncuesta = (idSpotHash) => {
        console.log(idSpotHash)

        const fetchData = async () => {
            try {
                const spotsFromServer = await srvDesactivarEncuesta(userData, idSpotHash);

                if (spotsFromServer) {
                    getSpotsFromServer()
                }
                else
                    setError("Error al buscar los usuarios")
            } catch (error) {
                console.log("error.message", "+" + error + "+")
                if (error == 'Error: Error del servidor: 401') {
                    console.log("ahora si 401")
                    dispatch(cerrarSesion());
                } else {
                    setError('Error al procesar la solicitud');
                }
            }
        };
        fetchData();


    }



    const accionConfirmada = () => {
        setIsOpenModal(false)
        console.log(accionAConfirmar)
        switch (accionAConfirmar.accion) {
            case ACCION_DESACTIVAR.accion:
                desactivarEncuesta(spotSelected.idSpotHash)
                break
            default:
                break
        }
    }


    const pedirConfirmacion = (spot, accion) => {
        setSpotSelected(spot)
        setAccionAConfirmar(accion)
        setIsOpenModal(true)
    }



    const asignarEncuesta = (spot) => {
        setSpotSelected(spot)
    }



    return (
        <div className={stylesPanel.containerPrincipal}>
            <HeaderTituloComponent pagina={HeaderTituloComponent.PAGINA_SPOTS_PRINCIPAL}></HeaderTituloComponent>

            <div className={stylesPanel.containerPanel}>
                <div className={styles.containerFilaBusqueda}>
                    <InputText
                        id="buscar"
                        name="buscar"
                        value={textoBuscado}
                        type={"search"}
                        onChangeInput={handleInputChange}
                        placeholder={"Busca spots..."}
                        mensajeError={errorBuscar}></InputText>
                    {/* <BarraBuscar handleNuevaBusqueda={handleNuevaBusqueda} toolTip={"Busca encuestas"} habilitarBusqueda={true}></BarraBuscar> */}

                    <div style={{ marginLeft: '20px' }} ><BotonPrincipal text="Crear" icono={BotonPrincipal.ICONO_CREAR} onClick={() => crearSpot()} /></div>


                </div>


                {/* 
                {cantidadTotemsSinActivar > 0 && (
                    <label className={styles.resumenLicenciasSinUsar} >Tienes {cantidadTotemsSinActivar} Totems sin asignar</label>
                )} */}

                {cantidadTotemsSinActivar > 0 && (
                    <CuadroAyuda titulo={`Tienes ${cantidadTotemsSinActivar} Totems sin asignar`} texto="Puedes asignar estos totems disponibles a cualquier spot. Luego de esto puedes activar un dispositivo"></CuadroAyuda>
                )}



                <div className={styles.containerGrillaUsuarios}  >
                    {spotsFiltrados.map((spot, index) => (
                        <div key={index} className={styles.containerUsuario} style={{ marginBottom: '20px' }}>
                            <label className={styles.titulo}>{spot.nombre} {spot.apellido}</label>
                            <div className={styles.divTodoEnUnaFila}>

                                <div className={styles.divCampos} >
                                    <img src={spot.imagenBlob ? spot.imagenBlob : imagenSpotNoFoto} className={styles.circleImage} />
                                    <div className={styles.divCamposItem}>
                                        <label className={styles.atributo}>Spot:</label>
                                        <label className={styles.valor}>{spot.spot}</label>
                                    </div>
                                    <div className={styles.divCamposItem}>
                                        <label className={styles.atributo}>Descripcion:</label>
                                        <label className={styles.valor}>{spot.descripcion}</label>
                                    </div>

                                    <div className={styles.divCamposItem}>
                                        <label className={styles.atributo}>Fecha de creación:</label>
                                        <label className={styles.valor}>{spot.fechaCreacion}</label>
                                    </div>

                                    <div className={styles.divCamposItem}>
                                        <label className={styles.atributo}>Creado por:</label>
                                        <label className={styles.valor}>{spot.creadoPor}</label>
                                    </div>

                                    <BotonPrincipal text="Modificar" onClick={() => navigate("edicion", { state: { spot: spot } })} marginTop={'10px'} width={'250px'} icono={BotonPrincipal.ICONO_MODIFICAR} />
                                    <div style={{ width: '10px' }}></div>
                                    <BotonPrincipal text="Eliminar" width={'250px'} marginTop={'10px'} icono={BotonPrincipal.ICONO_ELIMINAR} />

                                    <div style={{ width: '10px' }}></div>
                                </div>
                            </div>

                            <hr></hr>

                            <div className={styles.divTodoEnUnaFila}>

                                <div className={styles.divCampos} >
                                    {!spot.idEncuestaHash ? (
                                        <>
                                            <label className={styles.atributo}>Sin encuesta activa</label>
                                            <BotonPrincipal text="Asignar encuesta" onClick={() => navigate("seleccion-encuesta", { state: { spot: spot } })} marginTop={'10px'} width={'250px'} icono={BotonPrincipal.ICONO_ENCUESTA} />

                                        </>

                                    ) : (<><div className={styles.divCamposItem}>
                                        <label className={styles.atributo}>Encuesta:</label>
                                        <label className={styles.valor}>{spot.encuestaActiva}</label>
                                    </div>
                                        <div className={styles.divCamposItem}>
                                            <label className={styles.atributo}>Fecha activacion:</label>
                                            <label className={styles.valor}>{spot.fechaActivacionEncuesta}</label>
                                        </div>

                                        <BotonPrincipal text="Simular" onClick={() => simular(spot.idEncuestaHash)} marginTop={'10px'} width={'250px'} icono={BotonPrincipal.ICONO_SIMULAR} />
                                        <BotonPrincipal text="Desactivar encuesta" onClick={() => pedirConfirmacion(spot, ACCION_DESACTIVAR)} marginTop={'10px'} width={'250px'} icono={BotonPrincipal.ICONO_ELIMINAR} />
                                    </>)}



                                </div>
                            </div>

                            <hr></hr>
                            {spot.cantidadTotems > 0 && (
                                <div className={styles.divColumn} style={{ marginTop: 10, height: 40 }}>
                                    <label className={styles.atributo} >Tienes {spot.cantidadTotemsAsignados} de {spot.cantidadTotems} totems activados:</label>
                                    <ProgressBar progress={100 * spot.cantidadTotemsAsignados / spot.cantidadTotems} />
                                </div>
                            )}

                            {spot.cantidadTotems == 0 && (
                                <div className={styles.divColumn} style={{ marginTop: 10, height: 40 }}>
                                    <label className={styles.atributo} >No tienes totems asignados a este Spot</label>

                                </div>
                            )}


                            <div className={styles.divBotonera}>


                                <BotonPrincipal text="Gestión de totems" onClick={() => navigate("agregar-totems", { state: { spot: spot } })} width={'250px'} icono={BotonPrincipal.ICONO_MODIFICAR} />
                            </div>

                        </div>
                    ))}

                    {spotsFiltrados.length == 0 ? (
                        <div className={styles.divInformacion} ><label className={styles.labelSinEncuestas}>Sin spots creados</label></div>
                    ) : null}


                </div>

            </div>


            <SimuladorPage
                isOpen={isModalSimuladorOpen}
                onClose={() => setIsModalSimuladorOpen(false)}
                encuesta={spotSelected}

            />


            <ConfirmationModalComponent
                isOpen={isOpenModal}
                onClose={() => setIsOpenModal(false)}
                onConfirm={accionConfirmada}
                title={accionAConfirmar.titulo}>
                {accionAConfirmar.mensaje}
            </ConfirmationModalComponent>


        </div >
    )
}

export default SpotsPage;