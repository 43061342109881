
import styles from './CuadroAyuda.module.css'
import React, { useState, useEffect } from 'react';

function CuadroAyuda({ titulo, texto }) {


    return (
        <div className={styles.cuadro}>
            {titulo && titulo != "" && (
                <label className={styles.divTitulo} >{titulo}</label>
            )}

            <label className={styles.divAyuda} >{texto}</label>
        </div>


    )
}

export default CuadroAyuda;