import axios from 'axios';

// Función para realizar la solicitud GET a la API
export const srvObtenerFotoSpot = async (userData, imagenUrl) => {
    try {

        const response = await axios.get(imagenUrl, {
            headers: {
                Authorization: `Bearer ${userData.usuario.token}`,
            },
            responseType: 'blob', // Asegura que la respuesta sea tratada como un blob
        });

        const imageObjectURL = URL.createObjectURL(response.data);
        return imageObjectURL; // Retornar la imagen en un elemento img

    } catch (error) {
        console.error('Error al obtener los datos:', error);

        // Chequear si el error tiene 'response'
        if (error.response) {
            // El servidor respondió con un estado fuera del rango de 2xx
            console.error('Data:', error.response.data);
            console.error('Status:', error.response.status);
            console.error('Headers:', error.response.headers);
            throw new Error(`Error del servidor: ${error.response.status}`);
        } else if (error.request) {
            // La solicitud fue hecha pero no hubo respuesta
            console.error('Error de solicitud sin respuesta:', error.request);
            throw new Error('No se recibió respuesta del servidor');
        } else {
            // Algo ocurrió en la configuración de la solicitud que lanzó un Error
            console.error('Error:', error.message);
            throw new Error(error.message);
        }
    }
};
