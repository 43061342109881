// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Contenedor de la barra de progreso */
.ProgressBar_progressBar__8Gd1r {
  width: 100%; /* Ajusta el ancho según sea necesario */
  height: 20px; /* Ajusta la altura según sea necesario */
  background-color: #f0f0f0; /* Color de fondo de la barra vacía */
  border-radius: 6px; /* Bordes redondeados */
  border-color: var(--gris-separador);
  border-width: 2px;
  border-style: solid;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); /* Sombra para darle un efecto 3D */
  overflow: hidden; /* Oculta el desbordamiento para mantener los bordes redondeados */
}

/* Barra de progreso */
.ProgressBar_progress__sV\\+1R {
  height: 100%;
  background-color: #4caf50; /* Color de la barra de progreso */

  border-radius: 4px 0 0 4px; /* Bordes redondeados en el extremo izquierdo */
  transition: width 0.4s ease; /* Transición suave al cambiar el ancho */
}
`, "",{"version":3,"sources":["webpack://./src/components/progress-bar/ProgressBar.module.css"],"names":[],"mappings":"AAAA,uCAAuC;AACvC;EACE,WAAW,EAAE,wCAAwC;EACrD,YAAY,EAAE,yCAAyC;EACvD,yBAAyB,EAAE,qCAAqC;EAChE,kBAAkB,EAAE,uBAAuB;EAC3C,mCAAmC;EACnC,iBAAiB;EACjB,mBAAmB;EACnB,sCAAsC,EAAE,mCAAmC;EAC3E,gBAAgB,EAAE,kEAAkE;AACtF;;AAEA,sBAAsB;AACtB;EACE,YAAY;EACZ,yBAAyB,EAAE,kCAAkC;;EAE7D,0BAA0B,EAAE,+CAA+C;EAC3E,2BAA2B,EAAE,yCAAyC;AACxE","sourcesContent":["/* Contenedor de la barra de progreso */\n.progressBar {\n  width: 100%; /* Ajusta el ancho según sea necesario */\n  height: 20px; /* Ajusta la altura según sea necesario */\n  background-color: #f0f0f0; /* Color de fondo de la barra vacía */\n  border-radius: 6px; /* Bordes redondeados */\n  border-color: var(--gris-separador);\n  border-width: 2px;\n  border-style: solid;\n  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); /* Sombra para darle un efecto 3D */\n  overflow: hidden; /* Oculta el desbordamiento para mantener los bordes redondeados */\n}\n\n/* Barra de progreso */\n.progress {\n  height: 100%;\n  background-color: #4caf50; /* Color de la barra de progreso */\n\n  border-radius: 4px 0 0 4px; /* Bordes redondeados en el extremo izquierdo */\n  transition: width 0.4s ease; /* Transición suave al cambiar el ancho */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progressBar": `ProgressBar_progressBar__8Gd1r`,
	"progress": `ProgressBar_progress__sV+1R`
};
export default ___CSS_LOADER_EXPORT___;
