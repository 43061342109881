// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Checkbox.module.css */
.Checkbox_checkboxContainer__VdEC7 {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  height: auto;
  width: 100%;
}
.Checkbox_checkboxContainer__VdEC7 input {
  width: 20px;
  height: 20px;
}
.Checkbox_checkboxLabel__-NK9y {
  display: flex;
  flex-direction: row;
  margin-left: 5px;
  width: auto;
  color: black;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/components/checkbox/Checkbox.module.css"],"names":[],"mappings":"AAAA,wBAAwB;AACxB;EACE,aAAa;EACb,mBAAmB;EACnB,qBAAqB;EACrB,2BAA2B;EAC3B,YAAY;EACZ,WAAW;AACb;AACA;EACE,WAAW;EACX,YAAY;AACd;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB","sourcesContent":["/* Checkbox.module.css */\n.checkboxContainer {\n  display: flex;\n  align-items: center;\n  align-content: center;\n  justify-content: flex-start;\n  height: auto;\n  width: 100%;\n}\n.checkboxContainer input {\n  width: 20px;\n  height: 20px;\n}\n.checkboxLabel {\n  display: flex;\n  flex-direction: row;\n  margin-left: 5px;\n  width: auto;\n  color: black;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkboxContainer": `Checkbox_checkboxContainer__VdEC7`,
	"checkboxLabel": `Checkbox_checkboxLabel__-NK9y`
};
export default ___CSS_LOADER_EXPORT___;
