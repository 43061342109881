// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PaginaPrincipal_fondoComponente__eBTgx {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: white;
  width: 100%;
  height: 100%;
}

.PaginaPrincipal_logo__G5Tvv {
  height: 30px;
  width: auto;
  margin-bottom: 20px;
}
.PaginaPrincipal_bienvenido__uygzS {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
}
.PaginaPrincipal_mensaje__Zna\\+H {
  font-size: 1rem;
  margin-bottom: 20px;
}
.PaginaPrincipal_icono__P3waT {
  height: 60px;
  width: auto;
}
`, "",{"version":3,"sources":["webpack://./src/pages/principal/panel/encuestas/simulador/pagina-principal/PaginaPrincipal.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,uBAAuB;EACvB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,iBAAiB;EACjB,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,mBAAmB;AACrB;AACA;EACE,YAAY;EACZ,WAAW;AACb","sourcesContent":[".fondoComponente {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  border-radius: 5px;\n  background-color: white;\n  width: 100%;\n  height: 100%;\n}\n\n.logo {\n  height: 30px;\n  width: auto;\n  margin-bottom: 20px;\n}\n.bienvenido {\n  font-size: 2rem;\n  font-weight: bold;\n  margin-bottom: 20px;\n}\n.mensaje {\n  font-size: 1rem;\n  margin-bottom: 20px;\n}\n.icono {\n  height: 60px;\n  width: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fondoComponente": `PaginaPrincipal_fondoComponente__eBTgx`,
	"logo": `PaginaPrincipal_logo__G5Tvv`,
	"bienvenido": `PaginaPrincipal_bienvenido__uygzS`,
	"mensaje": `PaginaPrincipal_mensaje__Zna+H`,
	"icono": `PaginaPrincipal_icono__P3waT`
};
export default ___CSS_LOADER_EXPORT___;
