

import styles from './EncuestasArchivadasPage.module.css'
import stylesPanel from '../../../../../styles/Panel.module.css'
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
import HeaderTituloComponent from '../../../../../components/header-titulo/HeaderTituloComponent.js';
import InputText from '../../../../../components/InputText/InputText.js';
import BotonPrincipal from '../../../../../components/boton-principal/BotonPrincipal.js';
import { srvBuscarTodasLasEncuestas } from '../../../../../services/encuestas/srvBuscarTodasLasEncuestas.js';
import { useDispatch, useSelector } from 'react-redux';
import { cerrarSesion } from '../../../../../redux/userActions.js';
import { srvArchivarEncuesta } from '../../../../../services/encuestas/srvArchivarEncuesta.js';
import ConfirmationModalComponent from '../../../../../components/confirmation-modal/ConfirmationModalComponent.js';
import { srvEliminarEncuesta } from '../../../../../services/encuestas/srvEliminarEncuesta.js';
import SimuladorPage from './../simulador/SimuladorModal.js';

function EncuestasArchivadasPage({ isOpen, onClose }) {
    const dispatch = useDispatch();
    // dispatch(cerrarSesion());
    const navigate = useNavigate();
    const [error, setError] = useState(false);

    const [isModalSimuladorOpen, setIsModalSimuladorOpen] = useState(false);


    const ACCION_VACIA = { accion: "", titulo: "", mensaje: "" }
    const ACCION_ARCHIVAR = { accion: "archivar", titulo: "Archivar encuesta", mensaje: "¿seguro que deseas archivar la encuesta?. Esto la desactivará de cualquier Totem que la esté ejecutando" }
    const ACCION_DESARCHIVAR = { accion: "desarchivar", titulo: "Desarchivar encuesta", mensaje: "¿seguro que deseas desarchivar la encuesta?" }
    const ACCION_MODIFICAR = { accion: "modificar", titulo: "Modificar", mensaje: "¿seguro que deseas modificar la encuesta?" }
    const ACCION_ELIMINAR = { accion: "eliminar", titulo: "Eliminar", mensaje: "¿seguro que deseas eliminar la encuesta?" }
    const ACCION_ACTIVAR = { accion: "ACtivar", titulo: "Activar", mensaje: "Cuando la encuesta se active en un Spot, esta ya no se podrá modificar ni eliminar. Realice todos los cambios necesarios antes de continuar ¿estás seguro que deseas activar la encuesta?" }


    const [errorBuscar, setErrorBuscar] = useState('');
    const [textoBuscado, setTextoBuscado] = useState('');

    const [encuestas, setEncuestas] = useState([]);
    const [encuestaSelected, setEncuestaSelected] = useState(null);
    const [accionAConfirmar, setAccionAConfirmar] = useState(ACCION_VACIA);
    const [isOpenModal, setIsOpenModal] = useState(false);

    const userData = useSelector(state => state.authInfo);

    React.useEffect(() => {
        getEncuestasFromServer()
    }, [])


    // useEffect(() => {
    //     console.log("Nuevo texto a buscar ", textoBuscado)
    // }, [textoBuscado]);


    const getEncuestasFromServer = () => {
        // console.log("asds")
        const fetchData = async () => {
            try {
                const encuestasFromServer = await srvBuscarTodasLasEncuestas(userData, "1");
                console.log(encuestasFromServer)
                // console.log("authorization", userInfo.headers.getAuthorization)
                if (encuestasFromServer.data) {
                    // console.log("encuestasFromServer.data", encuestasFromServer)
                    // console.log("encuestasFromServer.data", encuestasFromServer.filter(encuesta => encuesta.creadoPor === "4"))
                    setEncuestas(encuestasFromServer.data)
                    // props.handleLogged(userInfo.data)
                }
                // navigate("/panel");
                else
                    setError("Error al buscar las encuestas")
            } catch (error) {
                console.log("error.message", "+" + error + "+")
                if (error == 'Error: Error del servidor: 401') {
                    console.log("ahora si 401")
                    dispatch(cerrarSesion());
                } else {
                    setError('Error al procesar la solicitud');
                }


            }
        };
        fetchData();
    }


    const handleInputChange = (e) => {

        const { name, value } = e.target;
        console.log("Texto buscado", value)
        setTextoBuscado(value)

    };

    const crearEncuestas = () => {

    }
    const comprarEncuestas = () => {

    }
    const verArchivadas = () => {

    }

    const archivar = () => {
        const fetchData = async () => {
            try {
                const encuestasFromServer = await srvArchivarEncuesta(userData, encuestaSelected.idEncuestaHash, encuestaSelected.swArchivada == 0 ? 1 : 0);
                console.log(encuestasFromServer)
                if (encuestasFromServer.data) {
                    getEncuestasFromServer()
                }
                else
                    setError("Error al buscar las encuestas")
            } catch (error) {
                console.log("error.message", "+" + error + "+")
                if (error == 'Error: Error del servidor: 401') {
                    console.log("ahora si 401")
                    dispatch(cerrarSesion());
                } else {
                    setError('Error al procesar la solicitud');
                }


            }
        };
        fetchData()
    }
    const analizar = () => {

    }
    const eliminar = () => {
        const fetchData = async () => {
            try {
                const encuestasFromServer = await srvEliminarEncuesta(userData, encuestaSelected.idEncuestaHash);
                console.log(encuestasFromServer)
                if (encuestasFromServer.data) {
                    getEncuestasFromServer()
                }
                else
                    setError("Error al buscar las encuestas")
            } catch (error) {
                console.log("error.message", "+" + error + "+")
                if (error == 'Error: Error del servidor: 401') {
                    console.log("ahora si 401")
                    dispatch(cerrarSesion());
                } else {
                    setError('Error al procesar la solicitud');
                }


            }
        };
        fetchData()
    }
    const simular = (encuesta) => {
        console.log("Simulando")
        setEncuestaSelected(encuesta)
        setIsModalSimuladorOpen(true)

    }

    const pedirConfirmacion = (encuesta, accion) => {
        setEncuestaSelected(encuesta)
        setAccionAConfirmar(accion)
        setIsOpenModal(true)
    }

    const accionConfirmada = () => {
        setIsOpenModal(false)
        console.log(accionAConfirmar)
        switch (accionAConfirmar.accion) {
            case ACCION_ARCHIVAR.accion:
                archivar()
                break
            case ACCION_DESARCHIVAR.accion:
                archivar()
                break
            case ACCION_MODIFICAR.accion:
                navigate("edicion", { state: { encuesta: encuestaSelected } })
                break
            case ACCION_ELIMINAR.accion:
                eliminar()
                break
            case ACCION_ACTIVAR.accion:
                navigate("activar", { state: { encuesta: encuestaSelected } })
                break
            default:
                break
        }
    }

    if (!isOpen) return null;
    return (
        <div className={styles.modalOverlay}>

            <div className={styles.modal}>


                <div className={styles.modalHeader}>
                    <button onClick={onClose} className={styles.closeButton}>X</button>
                </div>
                <div className={styles.modalContent}>
                    <div className={styles.containerFilaBusqueda}>
                        <InputText
                            id="buscar"
                            name="buscar"
                            value={textoBuscado}
                            type={"search"}
                            onChangeInput={handleInputChange}
                            placeholder={"Busca encuesta..."}
                            mensajeError={errorBuscar}></InputText>
                        {/* <BarraBuscar handleNuevaBusqueda={handleNuevaBusqueda} toolTip={"Busca encuestas"} habilitarBusqueda={true}></BarraBuscar> */}



                    </div>



                    <div className={styles.containerGrillaUsuarios}  >
                        {encuestas.map((encuesta, index) => (
                            <div key={index} className={styles.containerUsuario} style={{ marginBottom: '20px' }}>
                                <label className={styles.titulo}>{encuesta.titulo}</label>
                                <div className={styles.divTodoEnUnaFila}>

                                    <div className={styles.divCampos} >

                                        <div className={styles.divCamposItem}>
                                            <label className={styles.atributo}>Descripcion:</label>
                                            <label className={styles.valor}>{encuesta.descripcion}</label>
                                        </div>
                                        {encuesta.swModificable == 0 && (
                                            <div className={styles.divCamposItem}>
                                                <label className={styles.atributo}>Esta encuesta no es modificable</label>
                                            </div>
                                        )}


                                        {encuesta.swArchivada == 1 && (
                                            <div className={styles.divCamposItem}>
                                                <label className={styles.atributo}>Esta encuesta está archivada por lo que no puedes activarla pero si puedes ver sus resultados</label>
                                            </div>
                                        )}


                                        <div className={styles.divCamposItem}>
                                            <label className={styles.atributo}>Creado por:</label>
                                            <label className={styles.valor}>{encuesta.usuarioCreador}</label>
                                        </div>
                                        <div className={styles.divCamposItem}>
                                            <label className={styles.atributo}>Fecha creación:</label>
                                            <label className={styles.valor}>{encuesta.fechaCreacion}</label>
                                        </div>

                                        <div className={styles.divCamposItem}>
                                            <label className={styles.atributo}>Spots usando esta encuesta:</label>
                                            <label className={styles.valor}>{encuesta.spotsConEncuestaActiva}</label>
                                        </div>

                                        <div className={styles.divCamposItem}>
                                            <label className={styles.atributo}>Totems usando esta encuesta:</label>
                                            <label className={styles.valor}>{encuesta.totemsConEncuestaActiva}</label>
                                        </div>

                                        <div className={styles.divCamposItem}>
                                            <label className={styles.atributo}>Respuestas ingresadas a esta encuesta:</label>
                                            <label className={styles.valor}>{encuesta.cantidadRespuestas}</label>
                                        </div>
                                    </div>
                                </div>


                                <div className={styles.divBotonera}>
                                    {encuesta.swModificable == 1 && (
                                        <>
                                            <BotonPrincipal text="Modificar" onClick={() => pedirConfirmacion(encuesta, ACCION_MODIFICAR)} width={'150px'} icono={BotonPrincipal.ICONO_MODIFICAR} />

                                            <BotonPrincipal text="Eliminar" onClick={() => pedirConfirmacion(encuesta, ACCION_ELIMINAR)} width={'150px'} icono={BotonPrincipal.ICONO_ELIMINAR} />
                                        </>
                                    )}

                                    {encuesta.swArchivada == 1 && (
                                        <BotonPrincipal text="Desarchivar" onClick={() => pedirConfirmacion(encuesta, ACCION_DESARCHIVAR)} width={'150px'} icono={BotonPrincipal.ICONO_ARCHIVAR} />
                                    )}
                                    {encuesta.swArchivada == 0 && (
                                        <>
                                            <BotonPrincipal text="Archivar" onClick={() => pedirConfirmacion(encuesta, ACCION_ARCHIVAR)} width={'150px'} icono={BotonPrincipal.ICONO_ARCHIVAR} />
                                            <BotonPrincipal text="Activar" onClick={() => pedirConfirmacion(encuesta, ACCION_ACTIVAR)} width={'150px'} icono={BotonPrincipal.ICONO_ACTIVAR} />
                                        </>
                                    )}

                                    <BotonPrincipal text="Analizar" onClick={() => analizar()} icono={BotonPrincipal.ICONO_ANALIZAR} />
                                    <BotonPrincipal text="Simular" onClick={() => simular(encuesta)} icono={BotonPrincipal.ICONO_SIMULAR} />

                                </div>

                            </div>
                        ))}


                    </div>
                </div>


                <SimuladorPage
                    isOpen={isModalSimuladorOpen}
                    onClose={() => setIsModalSimuladorOpen(false)}
                    encuesta={encuestaSelected}

                />



                <ConfirmationModalComponent
                    isOpen={isOpenModal}
                    onClose={() => setIsOpenModal(false)}
                    onConfirm={accionConfirmada}
                    title={accionAConfirmar.titulo}>
                    {accionAConfirmar.mensaje}
                </ConfirmationModalComponent>



            </div >

        </div >
    )
}

export default EncuestasArchivadasPage;