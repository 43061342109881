// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PreguntaEdicionModal_modalOverlay__JaAfm {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.PreguntaEdicionModal_modalContent__gdTf3 {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 400px;
  max-height: 90vh; /* Max height to ensure it doesn't overflow the viewport */
  overflow-y: auto; /* Make the content scrollable */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.PreguntaEdicionModal_divFormulario__kVyI4 {
  width: 100%;
}

.PreguntaEdicionModal_contenedorTipoCuantitativa__KTzP2 {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
}

.PreguntaEdicionModal_imgCarita__WiOeU {
  display: flex;
  flex: 1 1;
  max-width: calc((100% / 5) - 10px); /* Ajusta el ancho de cada imagen */
  object-fit: contain; /* Mantiene la proporción de la imagen */
}

.PreguntaEdicionModal_divAlternativa__El\\+xK {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.PreguntaEdicionModal_divBotonera__aUWbx {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/principal/panel/encuestas/encuestas-edicion/pregunta-edicion/PreguntaEdicionModal.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,oCAAoC;EACpC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,kBAAkB;EAClB,YAAY;EACZ,gBAAgB,EAAE,0DAA0D;EAC5E,gBAAgB,EAAE,gCAAgC;EAClD,wCAAwC;AAC1C;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,mBAAmB;EACnB,8BAA8B;EAC9B,SAAS;EACT,WAAW;AACb;;AAEA;EACE,aAAa;EACb,SAAO;EACP,kCAAkC,EAAE,mCAAmC;EACvE,mBAAmB,EAAE,wCAAwC;AAC/D;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,gBAAgB;AAClB","sourcesContent":[".modalOverlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: rgba(0, 0, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 1000;\n}\n\n.modalContent {\n  background: white;\n  padding: 20px;\n  border-radius: 5px;\n  width: 400px;\n  max-height: 90vh; /* Max height to ensure it doesn't overflow the viewport */\n  overflow-y: auto; /* Make the content scrollable */\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n}\n\n.divFormulario {\n  width: 100%;\n}\n\n.contenedorTipoCuantitativa {\n  display: flex;\n  position: relative;\n  flex-direction: row;\n  justify-content: space-between;\n  gap: 10px;\n  width: 100%;\n}\n\n.imgCarita {\n  display: flex;\n  flex: 1;\n  max-width: calc((100% / 5) - 10px); /* Ajusta el ancho de cada imagen */\n  object-fit: contain; /* Mantiene la proporción de la imagen */\n}\n\n.divAlternativa {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  gap: 10px;\n}\n\n.divBotonera {\n  display: flex;\n  justify-content: flex-end;\n  margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalOverlay": `PreguntaEdicionModal_modalOverlay__JaAfm`,
	"modalContent": `PreguntaEdicionModal_modalContent__gdTf3`,
	"divFormulario": `PreguntaEdicionModal_divFormulario__kVyI4`,
	"contenedorTipoCuantitativa": `PreguntaEdicionModal_contenedorTipoCuantitativa__KTzP2`,
	"imgCarita": `PreguntaEdicionModal_imgCarita__WiOeU`,
	"divAlternativa": `PreguntaEdicionModal_divAlternativa__El+xK`,
	"divBotonera": `PreguntaEdicionModal_divBotonera__aUWbx`
};
export default ___CSS_LOADER_EXPORT___;
