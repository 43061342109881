
import stylesLogin from './../../../styles/LoginPage.module.css'

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import InputText from '../../../components/InputText/InputText.js';
import BotonPrincipal from '../../../components/boton-principal/BotonPrincipal.js';
import BotonSecundario from '../../../components/boton-secundario/BotonSecundario.js';

import { useLocation } from 'react-router-dom';
import { srvValidarCodigo } from '../../../services/registro/srvValidarCodigo.js';
import { srvEnviarCorreoParaRecibirCodigo } from '../../../services/recuperar-password/srvEnviarCorreoParaRecibirCodigo.js';


function SolicitarCorreoPage() {

    // Dentro del componente de la ruta "/validar"
    const location = useLocation();


    const dispatch = useDispatch();

    const [errorCorreo, setErrorCorreo] = React.useState("");

    const navigate = useNavigate();
    const [correo, setCorreo] = useState('');

    const solicitarCodigoVerificacion = () => {
        // console.log("asds")
        const fetchData = async () => {
            try {
                const userInfo = await srvEnviarCorreoParaRecibirCodigo(correo);
                console.log(userInfo.data)

                if (userInfo.data.estado == 1) {
                    navigate("/recuperar-password/2-validar", { state: { correo: correo } });
                }
                // navigate("/panel");
                else
                    setErrorCorreo("Error al buscar correo ¿estás segudo de que está correcto?")
            } catch (error) {
                // setError(error.message);
                setErrorCorreo("Error al buscar correo ¿estás segudo de que está correcto?")
            }
        };
        fetchData();
    }

    const handleInputChange = (e) => {

        const { name, value } = e.target;
        console.log(value)
        setCorreo(value);

    };


    const validarLogin = () => {
        let sw = 1
        setErrorCorreo("")
        if (correo == "") {
            sw = 0
            setErrorCorreo("Correo no puede estar vacio")
        }

        const regexCorreo = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        if (sw == 1 && !regexCorreo.test(correo)) {
            sw = 0;
            setErrorCorreo('Correo incorrecto');
        }

        if (sw == 1) {
            solicitarCodigoVerificacion()
        }
    }


    return (
        <div className='App-fondo'>
            <div className={`Card ${stylesLogin.CardLogin}`} >


                <div className={stylesLogin.filaSubtitulo2}>
                    <h1 className='tituloNegro'>
                        ¿Olvidaste tu password?
                    </h1>
                </div>

                <div className={stylesLogin.filaSubtitulo2}>
                    <label className='subtituloNegro2'>
                        Ingresa tu correo y enviaremos un código para resetear tu contraseña
                    </label>
                </div>




                <div style={{ marginTop: "30px", display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                    <InputText
                        id="correo"
                        name="correo"
                        value={correo}
                        type={"text"}
                        onChangeInput={handleInputChange}
                        width={'100%'}
                        required
                        placeholder={"Correo"}
                        mensajeError={errorCorreo}></InputText>


                </div>




                <div style={{ width: '100%', marginTop: '30px' }}>
                    <BotonPrincipal text={"Enviar correo"} onClick={() => validarLogin()}></BotonPrincipal>
                </div>

                {/* <Button variant="contained">Ingresar</Button> */}
                <hr></hr>

                <div className={stylesLogin.FilaNoEstasRegistrado}>
                    <BotonSecundario colorTexto='black' text={"¿Ya tienes una cuenta?"} onClick={() => navigate('/login')}></BotonSecundario>
                </div>



            </div>
        </div >
    )
}

export default SolicitarCorreoPage;