// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PaginaAlternativas_fondoComponente__n4SVe {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: white;
  width: 100%;
  height: 100%;
}

.PaginaAlternativas_logo__G7tJZ {
  height: 30px;
  width: auto;
  justify-self: flex-start;
  justify-items: flex-start;
  justify-content: flex-start;
  margin-top: 10px;
  margin-left: 10px;
  align-self: flex-start;
}

.PaginaAlternativas_midiv__7Yzii {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-radius: 5px;
  background-color: white;
  margin-top: 20px;
  height: 100%;
  width: 90%;
}

.PaginaAlternativas_indexPregunta__x3K\\+7 {
  font-size: 1rem;
  align-self: flex-start;
}

.PaginaAlternativas_pregunta__PBcqG {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
}
.PaginaAlternativas_mensaje__an7va {
  font-size: 1rem;
  margin-bottom: 20px;
}
.PaginaAlternativas_icono__LyCZs {
  height: 60px;
  width: auto;
}

.PaginaAlternativas_botonera__7jkIN {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 80%;
  gap: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/principal/panel/encuestas/simulador/pagina-alternativas/PaginaAlternativas.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,uBAAuB;EACvB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,wBAAwB;EACxB,yBAAyB;EACzB,2BAA2B;EAC3B,gBAAgB;EAChB,iBAAiB;EACjB,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,sBAAsB;EACtB,mBAAmB;EACnB,2BAA2B;EAC3B,kBAAkB;EAClB,uBAAuB;EACvB,gBAAgB;EAChB,YAAY;EACZ,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,mBAAmB;AACrB;AACA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAiB;EACjB,UAAU;EACV,SAAS;AACX","sourcesContent":[".fondoComponente {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  border-radius: 5px;\n  background-color: white;\n  width: 100%;\n  height: 100%;\n}\n\n.logo {\n  height: 30px;\n  width: auto;\n  justify-self: flex-start;\n  justify-items: flex-start;\n  justify-content: flex-start;\n  margin-top: 10px;\n  margin-left: 10px;\n  align-self: flex-start;\n}\n\n.midiv {\n  display: flex;\n  box-sizing: border-box;\n  flex-direction: column;\n  align-items: center;\n  justify-content: flex-start;\n  border-radius: 5px;\n  background-color: white;\n  margin-top: 20px;\n  height: 100%;\n  width: 90%;\n}\n\n.indexPregunta {\n  font-size: 1rem;\n  align-self: flex-start;\n}\n\n.pregunta {\n  font-size: 2rem;\n  font-weight: bold;\n  margin-bottom: 20px;\n}\n.mensaje {\n  font-size: 1rem;\n  margin-bottom: 20px;\n}\n.icono {\n  height: 60px;\n  width: auto;\n}\n\n.botonera {\n  display: flex;\n  flex-direction: column;\n  flex-wrap: nowrap;\n  width: 80%;\n  gap: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fondoComponente": `PaginaAlternativas_fondoComponente__n4SVe`,
	"logo": `PaginaAlternativas_logo__G7tJZ`,
	"midiv": `PaginaAlternativas_midiv__7Yzii`,
	"indexPregunta": `PaginaAlternativas_indexPregunta__x3K+7`,
	"pregunta": `PaginaAlternativas_pregunta__PBcqG`,
	"mensaje": `PaginaAlternativas_mensaje__an7va`,
	"icono": `PaginaAlternativas_icono__LyCZs`,
	"botonera": `PaginaAlternativas_botonera__7jkIN`
};
export default ___CSS_LOADER_EXPORT___;
