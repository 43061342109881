

import styles from './PaginaFin.module.css'
import React from 'react';
import BotonPrincipal from '../../../../../../components/boton-principal/BotonPrincipal.js';
import logo from '../../../../../../assets/images/logo.png'


function PaginaFin({ onSiguiente }) {




    return (
        <div className={styles.fondoComponente}>
            <img className={styles.logo} src={logo} />
            <div className={styles.midiv} >
                <label className={styles.bienvenido}>Gracias por responder</label>
                <label className={styles.mensaje}>Tus respuestas nos ayudarán a seguir mejorando</label>
                <a onClick={onSiguiente}>
                    <BotonPrincipal text="Finalizar"></BotonPrincipal>
                </a>
            </div>


        </div>

    )
}

export default PaginaFin;