// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a {
  cursor: pointer;
}

.BotonPrincipal_botonPrincipalContainer__pSDNH {
  display: flex;
  align-items: center;
  height: auto;
  min-height: 40px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: auto;
  justify-items: flex-start;
  padding-left: 20px;
  padding-right: 20px;
  background-color: var(--primary-color);
  border-radius: 8px;
  border: 1px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.BotonPrincipal_botonPrincipalContainer__pSDNH:hover {
  background-color: #459680;
}

.BotonPrincipal_botonPrincipalContainer__pSDNH:active {
  background-color: #357262;
}

.BotonPrincipal_botonPrincipalContainer__pSDNH:disabled {
  background-color: gray;
}

.BotonPrincipal_botonPrincipalContainer__pSDNH label {
  color: white;

  align-self: center;
  font-weight: bold;
  justify-content: center;
  text-align: left;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/boton-principal/BotonPrincipal.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;EACnB,WAAW;EACX,yBAAyB;EACzB,kBAAkB;EAClB,mBAAmB;EACnB,sCAAsC;EACtC,kBAAkB;EAClB,WAAW;EACX,wCAAwC;AAC1C;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,YAAY;;EAEZ,kBAAkB;EAClB,iBAAiB;EACjB,uBAAuB;EACvB,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":["a {\n  cursor: pointer;\n}\n\n.botonPrincipalContainer {\n  display: flex;\n  align-items: center;\n  height: auto;\n  min-height: 40px;\n  padding-top: 5px;\n  padding-bottom: 5px;\n  width: auto;\n  justify-items: flex-start;\n  padding-left: 20px;\n  padding-right: 20px;\n  background-color: var(--primary-color);\n  border-radius: 8px;\n  border: 1px;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\n}\n\n.botonPrincipalContainer:hover {\n  background-color: #459680;\n}\n\n.botonPrincipalContainer:active {\n  background-color: #357262;\n}\n\n.botonPrincipalContainer:disabled {\n  background-color: gray;\n}\n\n.botonPrincipalContainer label {\n  color: white;\n\n  align-self: center;\n  font-weight: bold;\n  justify-content: center;\n  text-align: left;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"botonPrincipalContainer": `BotonPrincipal_botonPrincipalContainer__pSDNH`
};
export default ___CSS_LOADER_EXPORT___;
