

import styles from './PaginaPrincipal.module.css'
import React, { useState, useRef, useEffect } from 'react';
import { BrowserRouter as Router, useLocation, useNavigate } from 'react-router-dom';
import BotonPrincipal from '../../../../../../components/boton-principal/BotonPrincipal.js';
import logo from '../../../../../../assets/images/logo.png'
import icono_touch from '../../../../../../assets/icons/icono_touch.png'

import { useDispatch, useSelector } from 'react-redux';



function PaginaPrincipal({ onSiguiente }) {
    const dispatch = useDispatch();
    // dispatch(cerrarSesion());
    const navigate = useNavigate();
    const [error, setError] = useState("")
    const [ejes, setEjes] = useState([])

    const userData = useSelector(state => state.authInfo);


    const [formData, setFormData] = useState({
        idEncuestaHash: -1,
        titulo: '',
        descripcion: '',
        idEje: -1,
        preguntasPerfil: [],
        preguntas: [],
        idEje: -1
    })




    console.log("formData", formData)
    return (
        <div className={styles.fondoComponente}>
            <img className={styles.logo} src={logo} />
            <label className={styles.bienvenido}>Bienvenido al sistema de encuestas</label>
            <label className={styles.mensaje}>Toca la pantalla para continuar</label>
            <a onClick={onSiguiente}>
                <img className={styles.icono} src={icono_touch} />
            </a>

        </div>

    )
}

export default PaginaPrincipal;