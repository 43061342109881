// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `p {
  width: 400px;
}
.LoginPage_container__iL\\+WE {
  display: flex;
  flex-direction: column;
  /* align-content: center;
  align-items: center; */
  justify-content: flex-start;
  height: 100vh;
  width: 100%;
  background-color: white;
}

.LoginPage_CardLogin__V\\+2cR {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
  border-radius: 12px;
}

.LoginPage_FilaNoEstasRegistrado__xD81v {
  display: flex;
  justify-content: flex-end;
  height: 60px;
}
.LoginPage_filaTitulo__ARmyc {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.LoginPage_filaSubtitulo__WjG9H {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.LoginPage_filaSubtitulo2__4JEAB {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.LoginPage_logo__L3MLW {
  width: 100px;
  height: 40px;
}

@media (min-width: 600px) {
  .LoginPage_CardLogin__V\\+2cR {
    width: 450px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/LoginPage.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;AACA;EACE,aAAa;EACb,sBAAsB;EACtB;wBACsB;EACtB,2BAA2B;EAC3B,aAAa;EACb,WAAW;EACX,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,YAAY;AACd;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,YAAY;AACd;;AAEA;EACE;IACE,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;EACjB;AACF","sourcesContent":["p {\n  width: 400px;\n}\n.container {\n  display: flex;\n  flex-direction: column;\n  /* align-content: center;\n  align-items: center; */\n  justify-content: flex-start;\n  height: 100vh;\n  width: 100%;\n  background-color: white;\n}\n\n.CardLogin {\n  display: flex;\n  flex-direction: column;\n  margin-left: 20px;\n  margin-right: 20px;\n  margin-top: 20px;\n  border-radius: 12px;\n}\n\n.FilaNoEstasRegistrado {\n  display: flex;\n  justify-content: flex-end;\n  height: 60px;\n}\n.filaTitulo {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.filaSubtitulo {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: center;\n}\n\n.filaSubtitulo2 {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  margin-top: 20px;\n}\n\n.logo {\n  width: 100px;\n  height: 40px;\n}\n\n@media (min-width: 600px) {\n  .CardLogin {\n    width: 450px;\n    margin-left: auto;\n    margin-right: auto;\n    margin-top: 0px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `LoginPage_container__iL+WE`,
	"CardLogin": `LoginPage_CardLogin__V+2cR`,
	"FilaNoEstasRegistrado": `LoginPage_FilaNoEstasRegistrado__xD81v`,
	"filaTitulo": `LoginPage_filaTitulo__ARmyc`,
	"filaSubtitulo": `LoginPage_filaSubtitulo__WjG9H`,
	"filaSubtitulo2": `LoginPage_filaSubtitulo2__4JEAB`,
	"logo": `LoginPage_logo__L3MLW`
};
export default ___CSS_LOADER_EXPORT___;
