

import styles from './HeaderTituloComponent.module.css'
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';


function HeaderTituloComponent({ pagina }) {
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const paginasArr = [
        {
            id: HeaderTituloComponent.PAGINA_ENCUESTAS_PRINCIPAL, titulo: 'Encuestas',
            path: [{ etiqueta: 'Encuestas', path: '/principal/encuestas' }]
        },
        {
            id: HeaderTituloComponent.PAGINA_ENCUESTAS_EDICION, titulo: 'Modificación',
            path: [{ etiqueta: 'Encuestas', path: '/principal/encuestas' }, { etiqueta: 'Edicion', path: '/principal/encuestas/edicion' }]
        },
        {
            id: HeaderTituloComponent.PAGINA_ENCUESTAS_EDICION_SELECCION_PREGUNTA_PERFIL, titulo: 'Selección pregunta perfil',
            path: [{ etiqueta: 'Encuestas', path: '/principal/encuestas' }, { etiqueta: 'Edicion', path: '/principal/encuestas/edicion' }, { etiqueta: 'Pregunta perfil', path: '/principal/encuestas/edicion' }]
        },
        {
            id: HeaderTituloComponent.PAGINA_ENCUESTAS_ACTIVAR, titulo: 'Activación de encuesta',
            path: [{ etiqueta: 'Encuestas', path: '/principal/encuestas' }, { etiqueta: 'Activación', path: '/principal/encuestas/activar' }]
        },
        {
            id: HeaderTituloComponent.PAGINA_USUARIOS_PRINCIPAL, titulo: 'Usuarios',
            path: [{ etiqueta: 'Usuarios', path: '/principal/usuarios' }]
        },
        {
            id: HeaderTituloComponent.PAGINA_USUARIOS_EDICION, titulo: 'Modificación',
            path: [{ etiqueta: 'Usuarios', path: '/principal/usuarios' }, { etiqueta: 'Edicion', path: '/principal/usuarios-edicion' }]
        },

        {
            id: HeaderTituloComponent.PAGINA_SPOTS_PRINCIPAL, titulo: 'Spots',
            path: [{ etiqueta: 'Spots', path: '/principal/spots' }]
        },
        {
            id: HeaderTituloComponent.PAGINA_SPOTS_EDICION, titulo: 'Modificación',
            path: [{ etiqueta: 'Spots', path: '/principal/spots' }, { etiqueta: 'Edicion', path: '/principal/spots-edicion' }]
        },

        {
            id: HeaderTituloComponent.PAGINA_LICENCIAS_PRINCIPAL, titulo: 'Licencias',
            path: [{ etiqueta: 'Licencias', path: '/principal/licencias' }]
        },
        {
            id: HeaderTituloComponent.PAGINA_LICENCIAS_EDICION, titulo: 'Modificación',
            path: [{ etiqueta: 'Spots', path: '/principal/spots' }, { etiqueta: 'Gestiòn totems', path: '/principal/spots/agregar-totems' }]
        },
        {
            id: HeaderTituloComponent.PAGINA_LICENCIAS_NUEVO_DISPOSITIVO, titulo: 'Activación dispositivo',
            path: [{ etiqueta: 'Spots', path: '/principal/spots' }, { etiqueta: 'Gestiòn totems', path: '/principal/spots/agregar-totems' }, { etiqueta: 'Activación dispositivo', path: '/principal/spots/agregar-totems/activar-nuevo-dispositivo' }]
        },

        {
            id: HeaderTituloComponent.PAGINA_PERFIL, titulo: 'Perfil',
            path: [{ etiqueta: 'Perfil', path: '/principal/mi-perfil' }]
        },

        {
            id: HeaderTituloComponent.PAGINA_CLIENTE, titulo: 'Perfil',
            path: [{ etiqueta: 'Cliente', path: '/principal/cliente' }]
        },
        {
            id: HeaderTituloComponent.PAGINA_PERFIL_EDICION, titulo: 'Edicion',
            path: [{ etiqueta: 'Perfil', path: '/principal/mi-perfil' }, { etiqueta: 'Edicion', path: '/principal/mi-perfil/edicion' }]
        },

        {
            id: HeaderTituloComponent.PAGINA_PANEL, titulo: 'Panel',
            path: []
        },

        {
            id: HeaderTituloComponent.PAGINA_PERFIL, titulo: 'Perfil',
            path: []
        },

        {
            id: HeaderTituloComponent.PAGINA_INFORMES, titulo: 'Informes',
            path: [{ etiqueta: 'Informes', path: '/principal/informes' }]
        },

        {
            id: HeaderTituloComponent.PAGINA_SPOTS_SELECCION_REPORTE, titulo: 'Seleccion reporte',
            path: [{ etiqueta: 'Spots', path: '/principal/spots' }, { etiqueta: 'Selección reportes', path: '/principal/spots/seleccion-reporte' }]
        }
    ]


    return (
        <div className={styles.containerTitulo}>

            <h1>{paginasArr.find(x => x.id == pagina).titulo}</h1>
            <div className={styles.ruta}>
                {paginasArr.find(x => x.id == pagina).path.length > 0 && (
                    <a href='/principal'>
                        <svg xmlns="http://www.w3.org/2000/svg" className={styles.iconoPath} viewBox="0 0 30 25.5">
                            <path className={styles.iconoSvg} id="Icon_material-home" data-name="Icon material-home" d="M15,30V21h6v9h7.5V18H33L18,4.5,3,18H7.5V30Z" transform="translate(-3 -4.5)" />
                        </svg>
                    </a>
                )}
                {paginasArr.find(x => x.id == pagina).path.map((item, index) => (
                    <div key={index} className={styles.itemPath}>
                        <Separador></Separador>
                        <a href={item.path}>
                            <label  >{item.etiqueta}</label>
                        </a>

                    </div>

                ))}



            </div>
        </div>
    )
}

const Separador = () => (
    <svg
        className={styles.separador}
        xmlns="http://www.w3.org/2000/svg"
        width="6"
        height="11"
        viewBox="0 0 12.621 22.243"
    >
        <path
            d="M13.5,27l9-9-9-9"
            transform="translate(-11.379 -6.879)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
        />
    </svg>
);




HeaderTituloComponent.PAGINA_ENCUESTAS_PRINCIPAL = "PAGINA_ENCUESTAS_PRINCIPAL"
HeaderTituloComponent.PAGINA_ENCUESTAS_EDICION = "PAGINA_ENCUESTAS_EDICION"
HeaderTituloComponent.PAGINA_ENCUESTAS_EDICION_SELECCION_PREGUNTA_PERFIL = "PAGINA_ENCUESTAS_EDICION_SELECCION_PREGUNTA_PERFIL"
HeaderTituloComponent.PAGINA_ENCUESTAS_ACTIVAR = "PAGINA_ENCUESTAS_ACTIVAR"


HeaderTituloComponent.PAGINA_USUARIOS_PRINCIPAL = "PAGINA_USUARIOS_PRINCIPAL"
HeaderTituloComponent.PAGINA_USUARIOS_EDICION = "PAGINA_USUARIOS_EDICION"

HeaderTituloComponent.PAGINA_SPOTS_PRINCIPAL = "PAGINA_SPOTS_PRINCIPAL"
HeaderTituloComponent.PAGINA_SPOTS_EDICION = "PAGINA_SPOTS_EDICION"

HeaderTituloComponent.PAGINA_LICENCIAS_PRINCIPAL = "PAGINA_LICENCIAS_PRINCIPAL"
HeaderTituloComponent.PAGINA_LICENCIAS_EDICION = "PAGINA_LICENCIAS_EDICION"
HeaderTituloComponent.PAGINA_LICENCIAS_NUEVO_DISPOSITIVO = "PAGINA_LICENCIAS_NUEVO_DISPOSITIVO"

HeaderTituloComponent.PAGINA_PERFIL = "PAGINA_PERFIL"
HeaderTituloComponent.PAGINA_PERFIL_EDICION = "PAGINA_PERFIL_EDICION"

HeaderTituloComponent.PAGINA_INFORMES = "PAGINA_INFORMES"


HeaderTituloComponent.PAGINA_PANEL = "PAGINA_PANEL"

HeaderTituloComponent.PAGINA_CLIENTE = "PAGINA_CLIENTE"

HeaderTituloComponent.PAGINA_SPOTS_SELECCION_REPORTE = "PAGINA_SPOTS_SELECCION_REPORTE"

export default HeaderTituloComponent;