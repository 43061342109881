// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SimuladorModal_modalOverlay__BQlw3 {
  position: fixed;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-start;

  z-index: 1000;
}

.SimuladorModal_modalContent__14yj6 {
  background: white;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 50px;
  border-radius: 15px;
  margin-top: 30px;
  width: 90vh;
  height: 36.25vw; /* 16:9 aspect ratio */
  max-height: 90vh;

  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.SimuladorModal_tabletContent__amv6o {
  background: gray;

  width: 100%;
  height: 100%; /* 16:9 aspect ratio */
  border-width: 1px;
  border-color: gray;
  border-style: solid;
  border-radius: 5px;
}

.SimuladorModal_divFlotante__LoL4T {
  position: fixed;
  bottom: 0px;
  right: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/principal/panel/encuestas/simulador/SimuladorModal.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,QAAQ;EACR,OAAO;EACP,QAAQ;EACR,SAAS;EACT,oCAAoC;EACpC,aAAa;EACb,uBAAuB;EACvB,uBAAuB;;EAEvB,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,iBAAiB;EACjB,oBAAoB;EACpB,mBAAmB;EACnB,mBAAmB;EACnB,gBAAgB;EAChB,WAAW;EACX,eAAe,EAAE,sBAAsB;EACvC,gBAAgB;;EAEhB,wCAAwC;AAC1C;;AAEA;EACE,gBAAgB;;EAEhB,WAAW;EACX,YAAY,EAAE,sBAAsB;EACpC,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,WAAW;EACX,UAAU;AACZ","sourcesContent":[".modalOverlay {\n  position: fixed;\n  top: 0px;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: rgba(0, 0, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: flex-start;\n\n  z-index: 1000;\n}\n\n.modalContent {\n  background: white;\n  padding-left: 20px;\n  padding-top: 20px;\n  padding-bottom: 20px;\n  padding-right: 50px;\n  border-radius: 15px;\n  margin-top: 30px;\n  width: 90vh;\n  height: 36.25vw; /* 16:9 aspect ratio */\n  max-height: 90vh;\n\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n}\n\n.tabletContent {\n  background: gray;\n\n  width: 100%;\n  height: 100%; /* 16:9 aspect ratio */\n  border-width: 1px;\n  border-color: gray;\n  border-style: solid;\n  border-radius: 5px;\n}\n\n.divFlotante {\n  position: fixed;\n  bottom: 0px;\n  right: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalOverlay": `SimuladorModal_modalOverlay__BQlw3`,
	"modalContent": `SimuladorModal_modalContent__14yj6`,
	"tabletContent": `SimuladorModal_tabletContent__amv6o`,
	"divFlotante": `SimuladorModal_divFlotante__LoL4T`
};
export default ___CSS_LOADER_EXPORT___;
