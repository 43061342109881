
import styles from './InputText.module.css'
import React, { useState, useEffect } from 'react';
import ShowPasswordIcon from '../../assets/icons/mostrar-password.svg'
import HidePasswordIcon from '../../assets/icons/ocultar-password.svg'
import EtiquetaError from '../etiqueta-error/EtiquetaError';

function InputText({
    miwidth,
    miMarginTop,
    id,
    name,
    value,
    required,
    type,
    placeholder,
    mensajeError,
    onChangeInput,
    isReadOnly = false }) {

    const [isFocused, setIsFocused] = useState(false);
    const [isModificadoDespuesDeError, setIsModificadoDespuesDeError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };


    React.useEffect(() => {
        setIsModificadoDespuesDeError(false)
    }, [mensajeError])

    const handleFocus = () => setIsFocused(true);
    const handleBlur = () => setIsFocused(false);
    const onChange = (e) => {
        // console.log("este onchange")
        onChangeInput(e);

        //const { name, value } = e.target;
        //console.log(value)

        setIsModificadoDespuesDeError(true)
    }
    return (
        <div className={styles.inputContainerGlobal}
            style={{
                width: miwidth || '100%', // Aplica 'miwidth' si existe, si no, aplica un valor default
                marginTop: miMarginTop || '0px' // Aplica 'miMarginTop' si existe, si no, aplica un valor default
            }}>
            <div className={isFocused ? `${styles.inputContainer} ${styles.focused}` : (mensajeError != "" && isModificadoDespuesDeError == false ? (`${styles.inputContainer} ${styles.inputContainerConError}`) : styles.inputContainer)}


            >
                <label>{placeholder}</label>
                <div style={{ boxSizing: 'border-box', width: '100%', display: 'flex', flexDirection: 'row', margin: '0px', padding: '0px', height: '35px', justifyContent: 'space-between' }}>

                    {type == 'search' && (
                        <svg xmlns="http://www.w3.org/2000/svg" width="30.621" height="30.621" viewBox="0 0 30.621 30.621">
                            <g id="Icon_feather-search" data-name="Icon feather-search" transform="translate(-3 -3)">
                                <path id="Path_8" data-name="Path 8" d="M28.5,16.5a12,12,0,1,1-12-12,12,12,0,0,1,12,12Z" fill="none" stroke="#384151" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                                <path id="Path_9" data-name="Path 9" d="M31.5,31.5l-6.525-6.525" fill="none" stroke="#384151" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                            </g>
                        </svg>

                    )}
                    <input
                        id={id}
                        name={name}
                        value={value}
                        onChange={onChange}
                        required={required}
                        type={(showPassword || type == 'search') ? 'text' : type}
                        placeholder={placeholder}
                        onFocus={handleFocus}
                        readOnly={isReadOnly}
                        onBlur={handleBlur} />
                    {type === 'password' && (
                        <div className={styles.divIconoPassword}>
                            <img
                                src={showPassword ? HidePasswordIcon : ShowPasswordIcon}
                                onClick={toggleShowPassword}
                                alt="Toggle password visibility"
                                style={{ cursor: 'pointer' }} // Hace que el icono sea clickable
                            />
                        </div>

                    )}


                </div>


            </div>
            {/* <label className={styles.mensajeError}>{!isModificadoDespuesDeError ? props.mensajeError : ""}</label> */}

            <EtiquetaError mensajeError={!isModificadoDespuesDeError ? mensajeError : ""}></EtiquetaError>


        </div >

    )
}

export default InputText;