

import styles from './InformesPage.module.css'
import stylesPanel from '../../../../styles/Panel.module.css'
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
import HeaderTituloComponent from '../../../../components/header-titulo/HeaderTituloComponent';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmationModalComponent from '../../../../components/confirmation-modal/ConfirmationModalComponent.js';
import SimuladorPage from './../encuestas/simulador/SimuladorModal.js';
import EncuestasArchivadasPage from './../encuestas/encuestas-archivadas/EncuestasArchivadasPage.js';
import BotonCardComponent from '../../../../components/boton-card/BotonCardComponent.js';

function InformesPage(props) {
    const dispatch = useDispatch();
    // dispatch(cerrarSesion());
    const navigate = useNavigate();
    const [error, setError] = useState(false);

    const [isModalSimuladorOpen, setIsModalSimuladorOpen] = useState(false);
    const [isEncuestaArchivadasOpen, setIsEncuestaArchivadasOpen] = useState(false);


    const ACCION_VACIA = { accion: "", titulo: "", mensaje: "" }
    const ACCION_ARCHIVAR = { accion: "archivar", titulo: "Archivar encuesta", mensaje: "¿seguro que deseas archivar la encuesta?. Esto la desactivará de cualquier Totem que la esté ejecutando" }
    const ACCION_DESARCHIVAR = { accion: "desarchivar", titulo: "Desarchivar encuesta", mensaje: "¿seguro que deseas desarchivar la encuesta?" }
    const ACCION_MODIFICAR = { accion: "modificar", titulo: "Modificar", mensaje: "¿seguro que deseas modificar la encuesta?" }
    const ACCION_ELIMINAR = { accion: "eliminar", titulo: "Eliminar", mensaje: "¿seguro que deseas eliminar la encuesta?" }
    const ACCION_ACTIVAR = { accion: "ACtivar", titulo: "Activar", mensaje: "Cuando la encuesta se active en un Spot, esta ya no se podrá modificar ni eliminar. Realice todos los cambios necesarios antes de continuar ¿estás seguro que deseas activar la encuesta?" }


    const [errorBuscar, setErrorBuscar] = useState('');
    const [textoBuscado, setTextoBuscado] = useState('');

    const [encuestas, setEncuestas] = useState([]);
    const [encuestaSelected, setEncuestaSelected] = useState(null);
    const [accionAConfirmar, setAccionAConfirmar] = useState(ACCION_VACIA);
    const [isOpenModal, setIsOpenModal] = useState(false);

    const userData = useSelector(state => state.authInfo);

    React.useEffect(() => {
    }, [])


    const abrirPage = (valor) => {
        // console.log("valor", valor)
        navigate(valor)
    }
    return (
        <div className={stylesPanel.containerPrincipal}>
            <HeaderTituloComponent pagina={HeaderTituloComponent.PAGINA_INFORMES}></HeaderTituloComponent>
            <div className={styles.containerMenu}>
                <BotonCardComponent className={styles.botonCard} onClick={(ruta) => abrirPage(ruta)} opcion={BotonCardComponent.MENU_INFORMES_PERFIL}  ></BotonCardComponent>
                <BotonCardComponent className={styles.botonCard} onClick={(ruta) => abrirPage(ruta)} opcion={BotonCardComponent.MENU_INFORMES_ENCUESTAS}  ></BotonCardComponent>

            </div>


            <SimuladorPage
                isOpen={isModalSimuladorOpen}
                onClose={() => setIsModalSimuladorOpen(false)}
                encuesta={encuestaSelected}

            />



        </div >
    )
}

export default InformesPage;