
import styles from './PerfilPage.module.css'
import stylesPanel from './../../../styles/Panel.module.css'
import React, { useState, useEffect } from 'react';

import { BrowserRouter as Router, Route, Switch, Form, redirect, Routes, useNavigate, useHistory, Navigate, Link } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import HeaderTituloComponent from '../../../components/header-titulo/HeaderTituloComponent.js';
import imagenPerfil from '../../../assets/icons/perfil.jpg'
import BotonPrincipal from '../../../components/boton-principal/BotonPrincipal';
import BotonSecundario from '../../../components/boton-secundario/BotonSecundario.js';
import { cerrarSesion } from '../../../redux/userActions.js';
import { srvObtenerFotoPerfil } from '../../../services/usuarios/srvObtenerFotoPerfil.js';
import { srvBuscarUsuario } from '../../../services/usuarios/srvBuscarUsuario.js';

function PerfilPage(props) {
    const dispatch = useDispatch();

    const [errorUsuario, setErrorUsuario] = React.useState("");
    const [errorPassword, setErrorPassword] = React.useState("");
    const navigate = useNavigate();
    const [error, setError] = useState(false);

    const [isOpen, setIsOpen] = useState(false);
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

    const [selected, setSelected] = useState('');
    const [imagenBlob, setImagenBlob] = useState('');

    const [formData, setFormData] = useState({
        idUsuarioHash: -1,
        nombre: 'Felop',
        apellido: 'adsd',
        correo: 'dasds',
        idUsuarioTipo: 1,
        imagenUrl: '',
        imagenBlob: null,
        spots: []
    })


    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsDesktop(window.innerWidth > 768);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (isDesktop) {
            setIsOpen(true);
        }
    }, [isDesktop]);

    useEffect(() => {
        if (formData.imagenUrl && formData.imagenUrl != '') {

            console.log('formData.imagenUrl nuevo:', formData.imagenUrl);
            cargarImagenFromServer();
        }
    }, [formData.imagenUrl]);



    const userData = useSelector(state => state.authInfo);

    useEffect(() => {
        if (userData.usuario)
            buscarUsuario(userData.usuario.idUsuarioHash)
    }, [userData]);

    console.log("userData", userData)





    const handleNavigation = (path) => {
        navigate(path);
        setSelected(path);
    };

    const salirDeLaSesion = () => {
        dispatch(cerrarSesion());
    }

    const modificarPerfil = () => {
        console.log("dic")
        navigate("edicion")
    }



    const cargarImagenFromServer = async () => {
        const defaultImage = '../../../../../assets/icons/perfil.jpg'; // Ruta a la imagen por defecto en tu carpeta assets
        console.log("Cargando imagen:", formData.imagenUrl);

        try {
            console.log("hola 1")
            const imageObjectURL = await srvObtenerFotoPerfil(userData, formData.imagenUrl);
            // setFormData(prevFormData => ({ ...prevFormData, imagenBlob: imageObjectURL }));
            setImagenBlob(imageObjectURL)
        } catch (error) {
            console.log("hola 2")
            setImagenBlob(defaultImage);

        }

    };

    const buscarUsuario = (idUsuarioHash) => {
        const fetchData = async () => {
            try {
                const usuarioFromServer = await srvBuscarUsuario(userData, idUsuarioHash);
                console.log(usuarioFromServer)
                // console.log("authorization", userInfo.headers.getAuthorization)
                if (usuarioFromServer) {
                    console.log("holi")

                    setFormData(prevFormData => ({
                        ...prevFormData,
                        idUsuarioHash: usuarioFromServer.data.idUsuarioHash,
                        nombre: usuarioFromServer.data.nombre,
                        apellido: usuarioFromServer.data.apellido,
                        correo: usuarioFromServer.data.correo,
                        idUsuarioTipo: usuarioFromServer.data.idUsuarioTipo,
                        imagenUrl: usuarioFromServer.data.imagenUrl || imagenPerfil,
                        spots: usuarioFromServer.data.spots
                    }));
                }
                // navigate("/panel");
                else
                    setError("Error al buscar los usuarios")
            } catch (error) {
                console.log("error.message", "+" + error + "+")
                if (error == 'Error: Error del servidor: 401') {
                    console.log("ahora si 401")
                    dispatch(cerrarSesion());
                } else {
                    setError('Error al procesar la solicitud');
                }


            }
        };
        fetchData();
    }


    return (

        <div className={stylesPanel.containerPrincipal}>
            <HeaderTituloComponent pagina={HeaderTituloComponent.PAGINA_PERFIL}></HeaderTituloComponent>
            <div className={stylesPanel.containerPanel}>
                <div className={styles.container}>

                    <img src={imagenBlob} alt="Perfil" className={styles.profilePicture} />

                    <label className={styles.nombreUsuario}>{userData.usuario.nombre} {userData.usuario.apellido}</label>
                    <label className={styles.perfilUsuario}>{userData.usuario.tipoUsuario}</label>
                    <div style={{ marginBottom: '20px', marginTop: '10px' }}><BotonSecundario text="Modificar" onClick={() => modificarPerfil()} ></BotonSecundario></div>
                    <div style={{ marginBottom: '20px', marginTop: '20px' }}><BotonPrincipal text="Salir de la sesión" onClick={() => salirDeLaSesion()} ></BotonPrincipal></div>

                </div>
            </div>

        </div>

    )
}

export default PerfilPage;