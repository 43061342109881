

import styles from './LicenciasPage.module.css'
import stylesPanel from '../../../../styles/Panel.module.css'
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
import HeaderTituloComponent from '../../../../components/header-titulo/HeaderTituloComponent.js';
import InputText from '../../../../components/InputText/InputText.js';
import BotonPrincipal from '../../../../components/boton-principal/BotonPrincipal.js';
import { srvBuscarTodasLasEncuestas } from '../../../../services/encuestas/srvBuscarTodasLasEncuestas.js';
import { useDispatch, useSelector } from 'react-redux';
import { cerrarSesion } from '../../../../redux/userActions.js';

import imagenSpotNoFoto from '../../../../assets/images/spot-no-image.jpg'
import { srvBuscarTodosLosUsuarios } from '../../../../services/usuarios/srvBuscarTodosLosUsuarios.js';
import { srvObtenerFotoPerfil } from '../../../../services/usuarios/srvObtenerFotoPerfil.js';
import axios from 'axios';
import { srvBuscarTodosLosSpots } from '../../../../services/spots/srvBuscarTodosLosSpots.js';
import ProgressBar from '../../../../components/progress-bar/ProgressBar.js';
import CuadroAyuda from '../../../../components/cuadro-ayuda/CuadroAyuda.js';
import { srvBuscarCantidadTotemsSinActivar } from '../../../../services/totems/srvBuscarCantidadTotemsSinActivar.js';
import { srvBuscarLicenciasHistoricas } from '../../../../services/licencias/srvBuscarLicenciasHistoricas.js';
import ConfirmationModalComponent from '../../../../components/confirmation-modal/ConfirmationModalComponent.js';
import { srvBuscarLicencia } from '../../../../services/licencias/srvBuscarLicencia.js';
import EtiquetaError from '../../../../components/etiqueta-error/EtiquetaError.js';


function LicenciasPage(props) {
    const dispatch = useDispatch();
    // dispatch(cerrarSesion());
    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const [errorBuscar, setErrorBuscar] = useState('');
    const [textoBuscado, setTextoBuscado] = useState('');
    const [spotsSinFiltrar, setSpotsSinFiltrar] = useState([]);
    const [spotsFiltrados, setSpotsFiltrados] = useState([]);
    const [cantidadTotemsSinActivar, setCantidadTotemsSinActivar] = useState([])
    const [licenciasHistoricas, setLicenciasHistoricas] = useState([])
    const [codigo, setCodigo] = useState('')
    const [tituloPopup, setTituloPopup] = useState('')
    const [isModalOpen, setModalOpen] = useState(false)

    const userData = useSelector(state => state.authInfo);

    React.useEffect(() => {
        getLicenciasHistoricasFromServer()
        buscarCantidadTotemsSinActivar()
    }, [])


    useEffect(() => {
        console.log("Nuevo texto a buscar ", textoBuscado)
        console.log("usuariosSinFiltrar ", spotsSinFiltrar)
        setSpotsFiltrados(spotsSinFiltrar.filter(usuario => {
            const textoBuscadoEnMinusculas = textoBuscado.toLowerCase();
            return usuario.spot.toLowerCase().includes(textoBuscadoEnMinusculas) || usuario.descripcion.toLowerCase().includes(textoBuscadoEnMinusculas) || usuario.creadoPor.toLowerCase().includes(textoBuscadoEnMinusculas)
        }
        ))

    }, [textoBuscado, spotsSinFiltrar]);



    const getLicenciasHistoricasFromServer = () => {
        // console.log("asds")
        const fetchData = async () => {
            try {
                const licenciasHistoricasFromServer = await srvBuscarLicenciasHistoricas(userData);
                console.log(licenciasHistoricasFromServer)
                // console.log("authorization", userInfo.headers.getAuthorization)
                if (licenciasHistoricasFromServer) {
                    console.log(licenciasHistoricasFromServer.data)
                    setLicenciasHistoricas(licenciasHistoricasFromServer.data.datos)
                }
                // navigate("/panel");
                else
                    setError("Error al buscar los usuarios")
            } catch (error) {
                console.log("error.message", "+" + error + "+")
                if (error == 'Error: Error del servidor: 401') {
                    console.log("ahora si 401")
                    dispatch(cerrarSesion());
                } else {
                    setError('Error al procesar la solicitud');
                }


            }
        };
        fetchData();
    }




    const buscarCantidadTotemsSinActivar = () => {
        // console.log("asds")
        const fetchData = async () => {
            try {
                const spotsFromServer = await srvBuscarCantidadTotemsSinActivar(userData);
                console.log(spotsFromServer)
                // console.log("authorization", userInfo.headers.getAuthorization)
                if (spotsFromServer) {
                    console.log(spotsFromServer.data.datos.cantidadTotemsSinActivar)

                    setCantidadTotemsSinActivar(spotsFromServer.data.datos.cantidadTotemsSinActivar)
                    // Cargar las imágenes después de actualizar el estado de los usuarios

                    // props.handleLogged(userInfo.data)
                }
                // navigate("/panel");
                else
                    setError("Error al buscar los usuarios")
            } catch (error) {
                console.log("error.message", "+" + error + "+")
                if (error == 'Error: Error del servidor: 401') {
                    console.log("ahora si 401")
                    dispatch(cerrarSesion());
                } else {
                    setError('Error al procesar la solicitud');
                }


            }
        };
        fetchData();
    }


    const handleInputChange = (e) => {

        const { name, value } = e.target;
        console.log("Texto buscado", value)
        setCodigo(value)

    };
    const crearSpot = () => {
        navigate("edicion", { state: { usuario: null } })
    }

    const buscarCodigoLicencia = () => {
        var sw = 1
        if (codigo == "") {
            sw = 0
            setError("Debes ingresar el código de licencia")
        }
        if (sw == 1) {
            const fetchData = async () => {
                try {
                    const spotsFromServer = await srvBuscarLicencia(userData, codigo);
                    console.log(spotsFromServer)
                    if (spotsFromServer) {
                        setModalOpen(true)
                        setTituloPopup(spotsFromServer.data.mensaje)
                        if (spotsFromServer.data.estado == 1)
                            setCodigo("")
                        getLicenciasHistoricasFromServer()
                    }
                    else
                        setError("Error al buscar los usuarios")
                } catch (error) {
                    console.log("error.message", "+" + error + "+")
                    if (error == 'Error: Error del servidor: 401') {
                        console.log("ahora si 401")
                        dispatch(cerrarSesion());
                    } else {
                        setError('Error al procesar la solicitud');
                    }


                }
            };
            fetchData();
        }

    }

    const handleActivarLicencia = () => {

    }

    return (
        <div className={stylesPanel.containerPrincipal}>
            <HeaderTituloComponent pagina={HeaderTituloComponent.PAGINA_LICENCIAS_PRINCIPAL}></HeaderTituloComponent>

            <div className={stylesPanel.containerPanel} style={{ display: 'flex', alignItems: 'flex-start' }}>


                <label className={styles.resumenLicenciasSinUsar} style={{ marginTop: '20px' }} >Cargar licencia</label>

                <label className={styles.descripcion} style={{ marginTop: '20px' }} >Ingresa el código de licencia</label>
                <div style={{ width: '200px' }}>
                    <InputText
                        id="codigo"
                        name="codigo"
                        value={codigo}
                        type={"text"}
                        miwidth={250}
                        onChangeInput={handleInputChange}
                        placeholder={"Código de licencia..."}
                        mensajeError={errorBuscar}></InputText>
                    <EtiquetaError mensajeError={error} />
                    <BotonPrincipal text="Cargar" onClick={() => buscarCodigoLicencia()} icono={BotonPrincipal.ICONO_LLAVE} width={180} />
                </div>


                {licenciasHistoricas.length > 0 && (
                    <>   <label className={styles.resumenLicenciasSinUsar} style={{ marginTop: '20px' }} >Listado de licencias cargadas</label>
                        <div className={styles.containerGrillaUsuarios}  >
                            {licenciasHistoricas.map((licencia, index) => (
                                <div key={index} className={styles.info}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="64.242" viewBox="0 0 94.242 92.099">
                                        <path id="Icon_feather-key" data-name="Icon feather-key" d="M83.031,3l-8.424,8.424M42.551,43.48a23.167,23.167,0,1,1-32.765,0,23.166,23.166,0,0,1,32.765,0Zm0,0L59.864,26.167m0,0L72.5,38.8,87.243,24.061,74.606,11.424M59.864,26.167,74.606,11.424" transform="translate(1.999 4.071)" fill="none" stroke="#384151" stroke-linecap="round" stroke-linejoin="round" stroke-width="10" />
                                    </svg>
                                    <div className={styles.divInfoLicencia} >
                                        <label className={styles.resumenLicenciasSinUsar}>Fecha de carga: {licencia.fechaCarga}</label>
                                        <label className={styles.resumenLicenciasSinUsar}>Totems cargados: {licencia.cantidadTotems}</label>

                                    </div>
                                </div>
                            ))}


                        </div></>
                )}
                {licenciasHistoricas.length == 0 && (<div className={styles.divInformacion} ><label className={styles.labelSinEncuestas}>No existen licencias cargadas</label></div>)}

            </div>

            <ConfirmationModalComponent
                isOpen={isModalOpen}
                onClose={() => setModalOpen(false)}
                onConfirm={handleActivarLicencia}
                title={tituloPopup}
                swModalInformativo={true}
                titleConfirmar="Cerrar">

            </ConfirmationModalComponent>



        </div >
    )
}

export default LicenciasPage;