// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  font-size: 1rem; /* Esto hace que 1rem sea igual a 16px */
}
body {
  box-sizing: content-box;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem; /* Igual a 16px */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-size: 2rem; /* Igual a 32px */
}

p {
  font-size: 0.875rem; /* Igual a 14px */
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,eAAe,EAAE,wCAAwC;AAC3D;AACA;EACE,uBAAuB;EACvB,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;EAClC,eAAe,EAAE,iBAAiB;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;EACE,eAAe,EAAE,iBAAiB;AACpC;;AAEA;EACE,mBAAmB,EAAE,iBAAiB;AACxC","sourcesContent":["html {\n  font-size: 1rem; /* Esto hace que 1rem sea igual a 16px */\n}\nbody {\n  box-sizing: content-box;\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\",\n    \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\",\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  font-size: 1rem; /* Igual a 16px */\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}\n\nh1 {\n  font-size: 2rem; /* Igual a 32px */\n}\n\np {\n  font-size: 0.875rem; /* Igual a 14px */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
