// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CuadroAyuda_cuadro__Ff72k {
  display: flex;
  flex-direction: column;
  background-color: var(--amarillo-ayuda);
  width: 100%;
  color: var(--text-color);
  padding: 5px;
  border-radius: 5px;
  border-width: 1px;
  border-color: var(--gris);
  border-style: solid;
  text-align: center;
  font-size: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.CuadroAyuda_divTitulo__cTCjA {
  font-weight: bold;
  font-size: 1.2rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/cuadro-ayuda/CuadroAyuda.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uCAAuC;EACvC,WAAW;EACX,wBAAwB;EACxB,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;EACjB,yBAAyB;EACzB,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;EACf,wCAAwC;AAC1C;AACA;EACE,iBAAiB;EACjB,iBAAiB;AACnB","sourcesContent":[".cuadro {\n  display: flex;\n  flex-direction: column;\n  background-color: var(--amarillo-ayuda);\n  width: 100%;\n  color: var(--text-color);\n  padding: 5px;\n  border-radius: 5px;\n  border-width: 1px;\n  border-color: var(--gris);\n  border-style: solid;\n  text-align: center;\n  font-size: 1rem;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\n}\n.divTitulo {\n  font-weight: bold;\n  font-size: 1.2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cuadro": `CuadroAyuda_cuadro__Ff72k`,
	"divTitulo": `CuadroAyuda_divTitulo__cTCjA`
};
export default ___CSS_LOADER_EXPORT___;
