// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BotonSecundario_botonSecundarioContainer__d4ljk {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.BotonSecundario_botonSecundarioContainer__d4ljk label {
  color: var(--primary-color);
  width: 100%;
  align-self: center;
  cursor: pointer;
  font-weight: bold;
}

.BotonSecundario_botonSecundarioContainer__d4ljk label:hover {
  color: var(--primary-color-hover);
}
`, "",{"version":3,"sources":["webpack://./src/components/boton-secundario/BotonSecundario.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,2BAA2B;EAC3B,WAAW;EACX,kBAAkB;EAClB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,iCAAiC;AACnC","sourcesContent":[".botonSecundarioContainer {\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n}\n\n.botonSecundarioContainer label {\n  color: var(--primary-color);\n  width: 100%;\n  align-self: center;\n  cursor: pointer;\n  font-weight: bold;\n}\n\n.botonSecundarioContainer label:hover {\n  color: var(--primary-color-hover);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"botonSecundarioContainer": `BotonSecundario_botonSecundarioContainer__d4ljk`
};
export default ___CSS_LOADER_EXPORT___;
