import axios from 'axios';

// Función para realizar la solicitud GET a la API
export const srvEnviarCorreoParaRecibirCodigo = async (correo) => {
    try {

        const response = await axios.post(`${process.env.REACT_APP_SERVER_BACKEND}/v1/auth/recuperacion/1-enviar-correo-recuperacion`, { correo: correo });

        if (response.status === 200) {
            console.log("response.data", response)
            return response;
        } else {
            throw new Error(`Error en la solicitud. Código de estado: ${response.status}`);
        }

    } catch (error) {
        console.error('Error al obtener los datos:', error);
        throw new Error(error);
    }
};
