
import stylesPanel from './../../../styles/Panel.module.css'
import styles from './HeaderComponent.module.css'
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
import MiniBoton from '../../../components/mini-boton/MiniBoton';
import imagenPerfil from '../../../assets/icons/perfil.jpg'
import logoImagen from '../../../assets/images/logo.png'
import { srvBuscarUsuario } from '../../../services/usuarios/srvBuscarUsuario';
import { srvObtenerFotoPerfil } from '../../../services/usuarios/srvObtenerFotoPerfil';
import { useDispatch, useSelector } from 'react-redux';
import { cerrarSesion } from '../../../redux/userActions.js';


function HeaderComponent({ onClickMenu }) {
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const [error, setError] = useState(false);


    const [formData, setFormData] = useState({
        idUsuarioHash: -1,
        nombre: 'Felop',
        apellido: 'adsd',
        correo: 'dasds',
        idUsuarioTipo: 1,
        imagenUrl: '',
        imagenBlob: null,
        spots: []
    })

    const userData = useSelector(state => state.authInfo);
    const usuario = userData.usuario

    React.useEffect(() => {
        buscarUsuario(usuario.idUsuarioHash)
    }, [usuario])


    const buscarUsuario = (idUsuarioHash) => {
        const fetchData = async () => {
            try {
                const usuarioFromServer = await srvBuscarUsuario(userData, idUsuarioHash);
                console.log(usuarioFromServer)
                // console.log("authorization", userInfo.headers.getAuthorization)
                if (usuarioFromServer) {
                    console.log("holi")

                    setFormData(prevFormData => ({
                        ...prevFormData,
                        idUsuarioHash: usuarioFromServer.data.idUsuarioHash,
                        nombre: usuarioFromServer.data.nombre,
                        apellido: usuarioFromServer.data.apellido,
                        correo: usuarioFromServer.data.correo,
                        idUsuarioTipo: usuarioFromServer.data.idUsuarioTipo,
                        imagenUrl: usuarioFromServer.data.imagenUrl || imagenPerfil,
                        spots: usuarioFromServer.data.spots
                    }));
                }
                // navigate("/panel");
                else
                    setError("Error al buscar los usuarios")
            } catch (error) {
                console.log("error.message", "+" + error + "+")
                if (error == 'Error: Error del servidor: 401') {
                    console.log("ahora si 401")
                    dispatch(cerrarSesion());
                } else {
                    setError('Error al procesar la solicitud');
                }


            }
        };
        fetchData();
    }

    useEffect(() => {
        if (formData.imagenUrl && formData.imagenUrl != '') {

            console.log('formData.imagenUrl nuevo:', formData.imagenUrl);
            cargarImagenFromServer();
        }
    }, [formData.imagenUrl]);





    const cargarImagenFromServer = async () => {
        const defaultImage = '../../../../../assets/icons/perfil.jpg'; // Ruta a la imagen por defecto en tu carpeta assets
        console.log("Cargando imagen:", formData.imagenUrl);
        if (formData.imagenUrl && formData.imagenUrl !== defaultImage && formData.imagenUrl !== "") {
            try {
                console.log("hola 1")
                const imageObjectURL = await srvObtenerFotoPerfil(userData, formData.imagenUrl);
                setFormData(prevFormData => ({ ...prevFormData, imagenBlob: imageObjectURL }));
            } catch (error) {
                console.log("hola 2")
                setFormData(prevFormData => ({ ...prevFormData, imagenBlob: defaultImage }));
            }
        } else {
            console.log("hola 3")
            setFormData(prevFormData => ({ ...prevFormData, imagenBlob: defaultImage }));
        }
    };

    return (
        <div className={styles.container}>
            <a onClick={() => navigate('')}>
                <div className={styles.areaIzquierda} >
                    <img src={logoImagen}></img>
                    <MiniBoton onClick={onClickMenu} icono={MiniBoton.ICONO_MENU}></MiniBoton>
                </div>
            </a>


            <div className={styles.areaDerecha}>
                {/* <MiniBoton icono={MiniBoton.ICONO_NOTIFICATION}></MiniBoton> */}
                <a onClick={() => navigate('mi-perfil')}>
                    <div className={styles.perfilContainer}>
                        <img src={formData.imagenBlob ? formData.imagenBlob : imagenPerfil} className={styles.circleImage} width={30} height={30} />
                        <label className={styles.nombre}>{formData.nombre} {formData.apellido}</label>

                        <svg className={styles.iconoConfiguracion} id="Icon_feather-settings" data-name="Icon feather-settings" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
                            <path className={styles.iconoConfiguracion} id="Path_3" data-name="Path 3" d="M22.5,18A4.5,4.5,0,1,1,18,13.5,4.5,4.5,0,0,1,22.5,18Z" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                            <path className={styles.iconoConfiguracion} id="Path_4" data-name="Path 4" d="M29.1,22.5a2.475,2.475,0,0,0,.495,2.73l.09.09a3,3,0,1,1-4.245,4.245l-.09-.09a2.5,2.5,0,0,0-4.23,1.77V31.5a3,3,0,1,1-6,0v-.135A2.475,2.475,0,0,0,13.5,29.1a2.475,2.475,0,0,0-2.73.495l-.09.09A3,3,0,1,1,6.435,25.44l.09-.09a2.5,2.5,0,0,0-1.77-4.23H4.5a3,3,0,1,1,0-6h.135A2.475,2.475,0,0,0,6.9,13.5a2.475,2.475,0,0,0-.5-2.73l-.09-.09A3,3,0,1,1,10.56,6.435l.09.09a2.475,2.475,0,0,0,2.73.495h.12A2.475,2.475,0,0,0,15,4.755V4.5a3,3,0,0,1,6,0v.135a2.5,2.5,0,0,0,4.23,1.77l.09-.09a3,3,0,1,1,4.245,4.245l-.09.09a2.475,2.475,0,0,0-.5,2.73v.12A2.475,2.475,0,0,0,31.245,15H31.5a3,3,0,0,1,0,6h-.135A2.475,2.475,0,0,0,29.1,22.5Z" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                        </svg>
                    </div>
                </a>
            </div>
        </div>
    )
}

export default HeaderComponent;