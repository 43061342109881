
import styles from './MiniBoton.module.css'
import React, { useState, useEffect } from 'react';

function MiniBoton({ onClick, icono }) {

    let miWidth = '20px'
    let miHeight = '20px'

    return (
        <a onClick={onClick} className={styles.aContainer} >
            <div className={styles.botonPrincipalContainer}>

                {icono == MiniBoton.ICONO_MENU && (
                    <svg width={miWidth} height={miHeight} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="none">
                        <path className={styles.icono} fill-rule="evenodd" d="M19 4a1 1 0 01-1 1H2a1 1 0 010-2h16a1 1 0 011 1zm0 6a1 1 0 01-1 1H2a1 1 0 110-2h16a1 1 0 011 1zm-1 7a1 1 0 100-2H2a1 1 0 100 2h16z" />
                    </svg>
                )}
                {icono == MiniBoton.ICONO_NOTIFICATION && (
                    <svg width={miWidth} height={miHeight} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.75 29.25">
                        <path className={styles.icono} id="Icon_ionic-md-notifications-outline" data-name="Icon ionic-md-notifications-outline" d="M18.07,8.471a10.165,10.165,0,0,1,1.681.315,7.166,7.166,0,0,1,5.462,7.021v8.973l.655.658.548.55H9.584l.548-.55.655-.658V15.806a7.166,7.166,0,0,1,5.462-7.021,12.447,12.447,0,0,1,1.681-.315m.07-5.1a2.15,2.15,0,0,0-2.184,2.194V6.592a9.479,9.479,0,0,0-7.279,9.214V23.85L5.625,26.775v1.462h24.75V26.775L27.463,23.85V15.806a9.479,9.479,0,0,0-7.279-9.214V5.569A2.15,2.15,0,0,0,18,3.375ZM20.912,29.7H15.088a2.912,2.912,0,1,0,5.823,0Z" transform="translate(-5.625 -3.375)" />
                    </svg>
                )}
                {icono == MiniBoton.ICONO_VER && (


                    <svg xmlns="http://www.w3.org/2000/svg" width={miWidth} height={miHeight} viewBox="0 0 40.5 27">
                        <path className={styles.icono} id="Icon_awesome-eye" data-name="Icon awesome-eye" d="M40.255,16.973A22.552,22.552,0,0,0,20.25,4.5,22.555,22.555,0,0,0,.245,16.974a2.275,2.275,0,0,0,0,2.052A22.552,22.552,0,0,0,20.25,31.5,22.555,22.555,0,0,0,40.255,19.026,2.275,2.275,0,0,0,40.255,16.973ZM20.25,28.125A10.125,10.125,0,1,1,30.375,18,10.125,10.125,0,0,1,20.25,28.125Zm0-16.875a6.7,6.7,0,0,0-1.78.266,3.364,3.364,0,0,1-4.7,4.7,6.735,6.735,0,1,0,6.484-4.97Z" transform="translate(0 -4.5)" fill="#269d85" />
                    </svg>


                )}
                {icono == MiniBoton.ICONO_EDITAR && (
                    <svg xmlns="http://www.w3.org/2000/svg" width={miWidth} height={miHeight} viewBox="0 0 30.849 30.849">
                        <path className={styles.icono} id="Icon_metro-pencil" data-name="Icon metro-pencil" d="M28.6,1.928A4.82,4.82,0,0,1,32.455,9.64l-1.928,1.928L23.779,4.82l1.928-1.928A4.8,4.8,0,0,1,28.6,1.928ZM4.5,24.1,2.571,32.777l8.676-1.928L29.081,13.014,22.333,6.266ZM24.128,12.881l-13.5,13.5L8.97,24.716l13.5-13.5,1.662,1.662Z" transform="translate(-2.571 -1.928)" fill="#269d85" />
                    </svg>

                )}
                {icono == MiniBoton.ICONO_BORRAR && (
                    <svg xmlns="http://www.w3.org/2000/svg" width={miWidth} height={miHeight} viewBox="0 0 31.5 36">
                        <path className={styles.icono} id="Icon_awesome-trash-alt" data-name="Icon awesome-trash-alt" d="M2.25,32.625A3.375,3.375,0,0,0,5.625,36h20.25a3.375,3.375,0,0,0,3.375-3.375V9h-27Zm19.125-18a1.125,1.125,0,0,1,2.25,0v15.75a1.125,1.125,0,0,1-2.25,0Zm-6.75,0a1.125,1.125,0,0,1,2.25,0v15.75a1.125,1.125,0,0,1-2.25,0Zm-6.75,0a1.125,1.125,0,0,1,2.25,0v15.75a1.125,1.125,0,0,1-2.25,0ZM30.375,2.25H21.938L21.277.935A1.688,1.688,0,0,0,19.765,0H11.728a1.668,1.668,0,0,0-1.5.935L9.563,2.25H1.125A1.125,1.125,0,0,0,0,3.375v2.25A1.125,1.125,0,0,0,1.125,6.75h29.25A1.125,1.125,0,0,0,31.5,5.625V3.375A1.125,1.125,0,0,0,30.375,2.25Z" transform="translate(0 0)" fill="#269d85" />
                    </svg>

                )}

                {icono == MiniBoton.ICONO_VOLVER && (
                    <svg xmlns="http://www.w3.org/2000/svg" width={miWidth} height={miHeight} viewBox="0 0 24 24">
                        <path id="Icon_material-arrow_back" data-name="Icon material-arrow_back" d="M30,16.5H11.745L20.13,8.115,18,6,6,18,18,30l2.115-2.115L11.745,19.5H30Z" transform="translate(-6 -6)" fill="#fff" />
                    </svg>

                )}


            </div>

        </a>
    )
}

MiniBoton.ICONO_MENU = "MENU"
MiniBoton.ICONO_NOTIFICATION = "ICONO_NOTIFICATION"
MiniBoton.ICONO_VER = "ICONO_VER"
MiniBoton.ICONO_EDITAR = "ICONO_EDITAR"
MiniBoton.ICONO_BORRAR = "ICONO_BORRAR"
MiniBoton.ICONO_VOLVER = "ICONO_VOLVER"


export default MiniBoton;